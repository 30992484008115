import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LinkService } from 'src/api/administration/services';
import { LinkViewModel } from 'src/app/view-models/link/link-view-model';
import { LinkAssemblerService } from '../assemblers/link-assembler.service';

@Injectable({
  providedIn: 'root'
})
export class LinkRepositoryService {
  public totalAmount: number = 0;

  constructor(private linkAPI: LinkService, 
    private linkAssembler: LinkAssemblerService) { }

    public GetLink(id: string): Observable<LinkViewModel> {
      return this.linkAPI.getLinkById({
        id: id
      }).pipe(
        map(response => {
          return this.linkAssembler.ToViewModel(response);
        })
      );
    }

  public ListLinks(limit: number, offset: number, filterHost: string): Observable<LinkViewModel[]> {
    return this.linkAPI.listLinks({
      limit: limit,
      offset: offset,
      filterHost: filterHost
    }).pipe(
      map(response => {
        this.totalAmount = response.totalCount ?? 0;

        return response.items?.map(x => this.linkAssembler.ToViewModel(x)) ?? [];
      })
    );
  }

  public CreateLink(model: LinkViewModel): Observable<string> {
    return this.linkAPI.createLink({
      body: this.linkAssembler.ToDTO(model)
    });
  }

  public PatchLink(model: LinkViewModel): Observable<boolean> {
    return this.linkAPI.patchLink({
      id: model?.id ?? '',
      body: this.linkAssembler.ToDTO(model)
    });
  }
}
