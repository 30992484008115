import { Injectable } from '@angular/core';
import { InvoiceDto, InvoiceItemCreativeDto, InvoiceItemDto } from 'src/api/legal/models';
import { InvoiceItemCreativeAggregationViewModel, InvoiceItemViewModel, InvoiceViewModel } from 'src/app/view-models/invoice/invoice-view-model';

@Injectable({
  providedIn: 'root'
})
export class InvoiceAssemblerService {

  constructor() { }

  public ToViewModel(other: InvoiceDto): InvoiceViewModel {
    const model = new InvoiceViewModel(other.contractId ?? '', other.serialNumber ?? '',
    other.dateOfConclusion?.split('T')[0] ?? '', other.dateStart?.split('T')[0] ?? '', other.dateEnd?.split('T')[0] ?? '', other.amount ?? 0);

    model.id = other.id ?? null;
    model.isVatIncluded = other.isVatIncluded ?? false;

    model.isSynced = other.syncState === 'synced';
    model.isSyncing = other.syncState === 'syncing';
    model.isAcceptedByErir = other.isAcceptedByErir ?? false;
    model.erirId = other.erirId ?? '';
    
    if (other.items) {
      other.items.forEach(x => {
        const converted = this.ItemToViewModel(x);

        model.addItem(converted);
      });
    }

    model.createdAt = other.createdAt ?? '';

    return model;
  }

  public ItemToViewModel(other: InvoiceItemDto): InvoiceItemViewModel {
    const model = new InvoiceItemViewModel(other.contractId ?? '', other.amount ?? 0);
    
    model.id = other.id ?? null;
    model.isVatIncluded = other.isVatIncluded ?? false;

    if (other.creativeAggregations) {
      other.creativeAggregations.forEach(x => {
        const converted = this.ItemCreativeAggregationToViewModel(x);

        model.creativeAggregation.push(converted);
      });
    }

    return model;
  }

  public ItemCreativeAggregationToViewModel(other: InvoiceItemCreativeDto): InvoiceItemCreativeAggregationViewModel {
    const model = new InvoiceItemCreativeAggregationViewModel(other.creativeId ?? '', other.platformId ?? '', other.impressions ?? 0, other.amount ?? 0);
  
    model.id = other.id ?? null;
    model.isVatIncluded = other.isVatIncluded ?? false;

    return model;
  }

  public ToDto(other: InvoiceViewModel): InvoiceDto {
    return {
      id: other.id ?? undefined,
      contractId: other.contractId ?? undefined,
      serialNumber: other.serialNumber ?? undefined,
      dateOfConclusion: other.dateOfConclusion,
      dateStart: other.dateStart,
      dateEnd: other.dateEnd,
      isVatIncluded: other.isVatIncluded,
      amount: other.amount,
      items: other.items?.map(x => this.ItemToDto(x))
    };
  }

  public ItemToDto(other: InvoiceItemViewModel): InvoiceItemDto {
    return {
      id: other.id ?? undefined,
      contractId: other.contractId,
      amount: other.amount,
      isVatIncluded: other.isVatIncluded,
      creativeAggregations: other.creativeAggregation?.map(x => this.ItemCreativeAggregationToDto(x))
    }
  }

  public ItemCreativeAggregationToDto(other: InvoiceItemCreativeAggregationViewModel): InvoiceItemCreativeDto {
    return {
      id: other.id ?? undefined,
      creativeId: other.creativeId,
      platformId: other.platformId,
      impressions: other.impressions,
      amount: other.amount,
      isVatIncluded: other.isVatIncluded,
    };
  }
}
