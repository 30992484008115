<ng-container *ngIf="isSyncing; else notSyncing">
    <button pButton type="button" icon="pi pi-clock" label="Передаётся в ОРД" class="w-full p-button-sm p-button-outlined" [disabled]="true"></button>
</ng-container>
<ng-template #notSyncing>
    <ng-container *ngIf="isSynced; else notSynced">
        <button pButton type="button" icon="pi pi-check" label="Передано в ОРД" class="w-full p-button-sm p-button-success" disabled="true"></button>
    
        <div class="mt-2 text-center flex justify-content-center align-items-center">
            <i [ngClass]="{'text-small flex-shrink-0 pi': true, 
            'pi-check success': isAcceptedByErir, 
            'pi-clock warning': !isAcceptedByErir}"></i>

            <span class="text-muted text-small ml-2 mr-2">{{isAcceptedByErir ? 'Принято ЕРИР' : 'Пока не принято ЕРИР'}}</span>

            <i *ngIf="erirId && erirId.length > 1" class="pi pi-copy" style="cursor: pointer;"
            pTooltip="Нажмите, чтобы копировать идентификатор ЕРИР" tooltipPosition="bottom"
            ngxClipboard [cbContent]="erirId" ></i>
        </div>
    </ng-container>
    <ng-template #notSynced>
        <button [disabled]="disabled" pButton type="button" icon="pi pi-send" label="Передать в ОРД" class="w-full p-button-sm" (click)="Click()"></button>
    </ng-template>
</ng-template>