<p-panel header="Инструменты поиска" toggler="header" [toggleable]="true" [collapsed]="true">
    <div class="grid">
        <div class="col-12 sm:col-6 md:col-3">
            <label>Название/описание</label>
            <input type="text" class="w-full" pInputText [(ngModel)]="name" placeholder="Любое" />
        </div>

        <div class="col-12 sm:col-6 md:col-3">
            <label>Формы</label>
            <p-multiSelect [options]="forms" [(ngModel)]="selectedForms" [showClear]="true"
                styleClass="w-full" placeholder="Любые" display="chip"
                optionLabel="name"  optionValue="code"></p-multiSelect>
        </div>

        <div class="col-12 sm:col-6 md:col-3">
            <label>Типы кампании</label>
            <p-multiSelect [options]="campaignTypes" [(ngModel)]="selectedCampaignTypes" [showClear]="true"
                styleClass="w-full" placeholder="Любые" display="chip"
                optionLabel="name"  optionValue="code">
            </p-multiSelect>
        </div>

        <div class="col-12 sm:col-6 md:col-3">
            <label>ОКВЭДы</label>
            <p-multiSelect [options]="okveds" [(ngModel)]="selectedOkveds" [showClear]="true"
                styleClass="w-full" placeholder="Любые" display="chip"
                optionLabel="name"  optionValue="id">
                <ng-template let-option pTemplate="item">
                    <div class="gap-2">
                        <span class="text-muted mr-1">{{option.value}}</span>
                        <span class="">{{ option.name }}</span>
                    </div>
                </ng-template>
            </p-multiSelect>
        </div>

        <div class="col-12"></div>
        <div class="col"></div>

        <div class="col-12 sm:col-6 md:col-3">
            <p-button (click)="reset()" icon="pi pi-times" label="Очистить форму" styleClass="w-full p-button-text p-button-danger"></p-button>
        </div>

        <div class="col-12 sm:col-6 md:col-3">
            <p-button (click)="send()" icon="pi pi-search" label="Выполнить поиск" styleClass="w-full p-button-outlined"></p-button>
        </div>
    </div>
</p-panel>