<div class="mb-5 flex align-items-center">
    <p-dropdown style="flex-grow: 1" styleClass="w-full" [filter]="true" filterBy="login" filterPlaceholder="Поиск по логину..." [showClear]="true" [options]="grantableUsers" [(ngModel)]="userToGrantRights" optionLabel="login" optionDisabled="isAdminOrSuperadmin" placeholder="Выберите пользователя, которому нужно выдать права доступа">
        <ng-template let-user pTemplate="item">
            <div class="flex align-items-center">
                <p-tag [value]="user.accountName" styleClass="mr-3" [severity]="user.isAdminOrSuperadmin ? 'warning' : 'info'"></p-tag>
                <div>{{user.login}}</div>
            </div>
        </ng-template>
    </p-dropdown>

    <button class="ml-3" pButton type="button" icon="fa-solid fa-plus" label="Выдать права доступа" (click)="GrantRights()" [loading]="isGrantingRights" [disabled]="!canGrantToThisUser"></button>
</div>

<p-table [value]="rights" [paginator]="true" [rows]="10" [showCurrentPageReport]="true" [loading]="isLoadingRights"
    [showLoader]="false" [lazy]="true" dataKey="id" [totalRecords]="rights.length"
    currentPageReportTemplate="Показаны с {first} по {last} из {totalRecords} строчек" [rowsPerPageOptions]="[5,10,25,50]">
    <ng-template pTemplate="header">
        <tr>
            <th>Пользователь</th>
            <th style="width: 200px">Редактирование</th>
            <th *ngIf="isRemovingPossible" style="width: 200px">Удаление</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rights>
        <tr>
            <td>
                <div class="flex align-items-center">
                    <button pButton type="button" icon="fa-solid fa-trash-can" [loading]="isRevokingRights" [disabled]="isRevokingRights" (click)="RevokeRights(rights)"
                            class="p-button-sm p-button-danger p-button-text mr-1"></button>

                    <p-tag [value]="rights.user.accountName" styleClass="mr-3" [severity]="rights.user.isAdminOrSuperadmin ? 'warning' : 'info'"></p-tag>
                    
                    <div>{{rights.user?.login ?? '???'}}</div>
                </div>
            </td>

            <td>
                <p-inputSwitch [(ngModel)]="rights.canModify" [disabled]="isPatchingRights" (onChange)="PatchCanModify(rights)"></p-inputSwitch>
            </td>

            <td *ngIf="isRemovingPossible">
                <p-inputSwitch [(ngModel)]="rights.canRemove" [disabled]="isPatchingRights" (onChange)="PatchCanRemove(rights)"></p-inputSwitch>
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="loadingbody">
        <tr *ngFor="let item of [1,2,3,4,5]">
            <td>
                <p-skeleton width="100%" height="2rem"></p-skeleton>
            </td>
            
            <td>
                <p-skeleton width="100%" height="2rem"></p-skeleton>
            </td>

            <td *ngIf="isRemovingPossible">
                <p-skeleton width="100%" height="2rem"></p-skeleton>
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
        <tr>
            <td [attr.colspan]="isRemovingPossible ? 3 : 2">
                <div class="text-center">
                    <h3>
                        Не выдано прав доступа
                    </h3>
                    <p>
                        К этой сущности ещё не выдано особых прав доступа
                    </p>
                </div>
            </td>
        </tr>
    </ng-template>
</p-table>