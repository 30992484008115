<h1>
    Акты
</h1>

<div class="mt-5">
    <div class="grid">
        <div class="col-12 sm:col-10">
            <div class="flex align-items-center h-full">
                Здесь отображается список всех созданных актов.
            </div>
        </div>

        <div class="col-12 sm:col-2">
            <button pButton type="button" label="Создать" class="w-full" (click)="CreateItemClicked()"></button>
        </div>
    </div>
</div>

<div class="mt-3 fancy-card">
    <div class="fancy-card-content">
        <p-table [value]="items" [loading]="isLoading" dataKey="id" [showCurrentPageReport]="true"
                [lazy]="true" (onLazyLoad)="OnLazyLoad($event)"
                [paginator]="true" [rows]="10" [totalRecords]="itemsTotalCount" [rowsPerPageOptions]="[10,25,50]"
                currentPageReportTemplate="Показаны с {first} по {last} из {totalRecords} строчек" >
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Номер акта</th>
                            <th>Договор</th>
                            <th style="width: 250px">Клиент</th>
                            <th style="width: 200px">Сумма акта</th>
                            <th style="width: 230px;">Операции</th>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-model>
                        <tr>
                            <td>
                                <div class="text-muted text-small">
                                    от {{model.dateOfConclusion}}
                                </div>
                                <div>
                                    <strong>{{model.serialNumber}}</strong>
                                </div>

                                <div class="text-small text-muted">Создано {{model.createdAt |date}}</div>

                                <!-- <div class="mt-2">
                                    <span class="text-small text-muted">Отчетный период</span><br>
                                    <i class="text-small pi pi-play mr-2"></i> <span class="text-small" pTooltip="Дата начала отчетного периода">{{model.dateStart}}</span> <br>
                                    <i class="text-small pi pi-stop mr-2"></i> <span class="text-small" pTooltip="Дата окончания отчетного периода">{{model.dateEnd}}</span>
                                </div> -->
                            </td>

                            <td>
                                <app-contract-snippet [contract]="model.contract"></app-contract-snippet>
                            </td>

                            <td>
                                <app-counterparty-snippet [counterparty]="model.contract?.client"></app-counterparty-snippet>
                            </td>

                            <td>
                                <ng-container *ngIf="model.items">
                                    <ng-container *ngFor="let item of model.items">
                                        <ng-container *ngFor="let aggregation of item.creativeAggregation; last as isLast">
                                            <span class="text-muted text-small">
                                                {{aggregation.amount | number}} руб.
                                            </span>

                                            <span *ngIf="!isLast; else last" class="text-muted text-small">+</span>
                                            <ng-template #last>
                                                =
                                            </ng-template>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>

                                <div>
                                    <strong>{{model.amount | number}}</strong> руб.
                                </div>
                            </td>

                            <td>
                                <div class="grid">
                                    <div class="col-12">
                                        <button [disabled]="model.isSyncing" pButton type="button" icon="pi pi-pencil" label="Правка" class="w-full p-button-sm p-button-text" (click)="UpdateItemClicked(model)"></button>
                                    </div>
                                    <div class="col-12">
                                        <app-sync-button 
                                        [erirId]="model.erirId"
                                        [isAcceptedByErir]="model.isAcceptedByErir" 
                                        [isSyncing]="model.isSyncing" 
                                        [isSynced]="model.isSynced" 
                                        [isSynced]="model.isSynced" 
                                        (onClicked)="SyncClick(model)"></app-sync-button>
                                    </div>
                                </div>
                            </td>
                        </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<p-confirmDialog acceptIcon="pi pi-send" acceptLabel="Передать в ОРД" rejectLabel="Отмена" rejectButtonStyleClass="p-button-danger p-button-text" [style]="{width: '50vw'}"></p-confirmDialog>