<div class="flex align-items-center">
    <div class="mr-auto">
        <ng-container *ngIf="!isLoading; else loadingLinkData">
            <h1>
                Статистика "{{viewModel?.name}}"
            </h1>
            <div class="text-small text-bare">
                <span>{{viewModel?.url}}</span><a [attr.href]="viewModel?.url" rel="nofollow noopener noreferrer"
                    target="_blank" class="ml-2" title="Ссылка на целевой ресурс">
                    <i class="fa-solid fa-up-right-from-square"></i>
                </a>
            </div>
        </ng-container>
        <ng-template #loadingLinkData>
            <p-skeleton styleClass="mb-1" width="320px" height="58px"></p-skeleton>
            <p-skeleton width="160px" height="18px"></p-skeleton>
        </ng-template>
    </div>


    <button pButton type="button" icon="fa-solid fa-chevron-left" label="Назад к списку" (click)="GoToLinks()"></button>
</div>

<div class="mt-5 fancy-card">
    <div class="fancy-card-content">
        <h2>
            Переходы по ссылке
        </h2>

        <div class="flex align-items-end mb-3">
            <button pButton type="button" label="Этот месяц" class="p-button-sm p-button-outlined mr-3"
                (click)="AssignDatesThisMonth()"></button>

            <button pButton type="button" label="7 дней" class="p-button-sm p-button-outlined mr-3"
                (click)="AssignDates7Days()"></button>
            <button pButton type="button" label="14 дней" class="p-button-sm p-button-outlined mr-3"
                (click)="AssignDates14Days()"></button>
            <button pButton type="button" label="28 дней" class="p-button-sm p-button-outlined mr-3"
                (click)="AssignDates28Days()"></button>

            <div class="ml-auto mr-3">
                <label class="block w-full">Показывать с</label>
                <p-calendar [(ngModel)]="dateFrom" dateFormat="dd.mm.yy" [minDate]="dateMin" [maxDate]="dateTo"
                    (onSelect)="LoadLinkVisits()"></p-calendar>
            </div>

            <div>
                <label class="block w-full">Показывать по</label>
                <p-calendar [(ngModel)]="dateTo" dateFormat="dd.mm.yy" [minDate]="dateFrom" [maxDate]="dateMax"
                    (onSelect)="LoadLinkVisits()"></p-calendar>
            </div>
        </div>

        <div class="flex justify-content-end align-items-center mb-3">
            <!-- <button pButton type="button" label="Экспорт в .CSV" class="p-button-outlined mr-3"></button> -->

            <p-selectButton [options]="dataDisplayModes" [(ngModel)]="dataDisplayMode" optionLabel="icon"
                optionValue="value">
                <ng-template let-item>
                    <i [class]="item.icon"></i><span class="ml-1">{{item.label}}</span>
                </ng-template>
            </p-selectButton>
        </div>

        <ng-container *ngIf="linkVisitViewModel.length > 0 || isLoading; else noTotalStats" [ngSwitch]="dataDisplayMode">
            
            <ng-container *ngSwitchCase="'chart'">
                <p-chart *ngIf="!isLoading; else chartLoading" type="line" [data]="basicData" [options]="basicOptions"></p-chart>
                <ng-template #chartLoading>
                    <p-skeleton width="100%" height="700px"></p-skeleton>
                </ng-template>
            </ng-container>
            

            <p-table *ngSwitchCase="'table'" [value]="linkVisitViewModel" [loading]="isLoading"
                [globalFilterFields]="['rawDate','overallVisits','uniqueVisits']">
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="rawDate">
                            <div class="flex align-items-center">
                                Дата

                                <p-sortIcon field="rawDate"></p-sortIcon>
                                <p-columnFilter type="date" field="rawDate" display="menu"></p-columnFilter>
                            </div>
                        </th>

                        <th pSortableColumn="overallVisits">
                            <div class="flex align-items-center">
                                Всего переходов

                                <p-sortIcon field="overallVisits"></p-sortIcon>
                                <p-columnFilter type="numeric" field="overallVisits" display="menu"></p-columnFilter>
                            </div>
                        </th>

                        <th pSortableColumn="uniqueVisits">
                            <div class="flex align-items-center">
                                Уников

                                <p-sortIcon field="uniqueVisits"></p-sortIcon>
                                <p-columnFilter type="numeric" field="uniqueVisits" display="menu"></p-columnFilter>
                            </div>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item>
                    <tr>
                        <td>{{item.date}}</td>
                        <td [ngClass]="{'text-bare': item.overallVisits <= 0}">{{item.overallVisits|number}}</td>
                        <td [ngClass]="{'text-bare': item.uniqueVisits <= 0}">{{item.uniqueVisits|number}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </ng-container>
        <ng-template #noTotalStats>
            <div class="h4">
                Нет данных
            </div>
            <div class="text-bare text-small">
                Не удалось обнаружить данные за указанный вами временной промежуток.
            </div>
        </ng-template>
    </div>
</div>

<div class="mt-5 fancy-card">
    <div class="fancy-card-content">
        <h2>
            Источники переходов
        </h2>

        <ng-container *ngIf="linkPageVisits.length > 0; else noPageStats">
            <div class="mt-5" *ngFor="let group of linkPageVisits">
                <div class="mb-3">
                    <span class="h5">
                        marketing-tech.ru{{group.path}}
                    </span>

                    <a [attr.href]="'https://marketing-tech.ru'+group.path" rel="nofollow noopener noreferrer"
                                    target="_blank" class="ml-1 text-small" pTooltip="Переход на страницы">
                                    <i class="fa-solid fa-up-right-from-square"></i>
                                </a>
                    
                </div>

                <p-table [value]="group.aggregations" [globalFilterFields]="['rawDate','overallVisits','uniqueVisits']">
                    <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn="rawDate">
                                <div class="flex align-items-center">
                                    Дата

                                    <p-sortIcon field="rawDate"></p-sortIcon>
                                    <p-columnFilter type="date" field="rawDate" display="menu"></p-columnFilter>
                                </div>
                            </th>
        
                            <th pSortableColumn="overallVisits">
                                <div class="flex align-items-center">
                                    Всего переходов

                                    <p-sortIcon field="overallVisits"></p-sortIcon>
                                    <p-columnFilter type="numeric" field="overallVisits" display="menu"></p-columnFilter>
                                </div>
                            </th>
        
                            <th pSortableColumn="uniqueVisits">
                                <div class="flex align-items-center">
                                    Уников

                                    <p-sortIcon field="uniqueVisits"></p-sortIcon>
                                    <p-columnFilter type="numeric" field="uniqueVisits" display="menu"></p-columnFilter>
                                </div>
                            </th>
                        </tr>
                    </ng-template>
        
                    <ng-template pTemplate="body" let-item>
                        <tr>
                            <td>{{item.date}}</td>
                            <td [ngClass]="{'text-bare': item.overallVisits <= 0}">{{item.overallVisits|number}}</td>
                            <td [ngClass]="{'text-bare': item.uniqueVisits <= 0}">{{item.uniqueVisits|number}}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </ng-container>
        <ng-template #noPageStats>
            <div class="h4">
                Нет данных
            </div>
            <div class="text-bare text-small">
                Не удалось обнаружить данные за указанный вами временной промежуток.
            </div>
        </ng-template>
    </div>
</div>

<div class="mt-3 text-smaller text-bare">
    Уникальным переходом является первый переход посетителя по ссылке в указанный день.
    Все последующие переходы по этой ссылке посетителем учитываются только в кривой "всего переходов".
</div>