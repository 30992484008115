import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { DialogService } from 'primeng/dynamicdialog';
import { of } from 'rxjs';
import { delay, mergeMap, switchMap, tap } from 'rxjs/operators';
import { IntegrationDialogComponent } from 'src/app/dialogues/integration-dialog/integration-dialog.component';
import { IntegrationPlatformsService } from 'src/app/services/api/integration-platforms.service';
import { IntegrationsService } from 'src/app/services/api/integrations.service';
import { LeadApiService } from 'src/app/services/api/lead-api.service';
import { LeadFormApiService } from 'src/app/services/api/lead-form-api.service';
import { Integration } from 'src/app/view-models/integrations/integration';
import { IntegrationPlatform, IntegrationPlatformObjectField } from 'src/app/view-models/integrations/integration-platform';
import { Lead } from 'src/app/view-models/leads/lead';
import { LeadForm } from 'src/app/view-models/leads/lead-form';
import { LeadStats } from 'src/app/view-models/leads/lead-stats';

@Component({
  selector: 'app-lead-form-single',
  templateUrl: './lead-form-single.component.html',
  styleUrls: ['./lead-form-single.component.scss']
})
export class LeadFormSingleComponent implements OnInit {
  public isLoading: boolean = true;
  public leadForm: LeadForm | null = null;

  public isLoadingLeads: boolean = true;
  public leads: Lead[] = [];
  public leadsOverall: number = 0;

  public isLoadingLeadStats: boolean = true;
  public leadStats: LeadStats[] = [];

  private get id(): string {
    return this.route.snapshot.paramMap.get('leadFormId') ?? '';
  }

  public basicData: any;
  public basicOptions: any;

  private filterDateFrom: any;
  private filterDateTo: any;

  public isLoadingIntegrations: boolean = true;
  public integrations: Integration[] = [];

  public platforms: IntegrationPlatform[] = [];

  private sourceObjectId: string | null = null;
  private sourceEntityId: string | null = null;

  constructor(private _leadFormApi: LeadFormApiService,
    private _leadApi: LeadApiService,
    private _integrationPlatform: IntegrationPlatformsService,
    private _integrationApi: IntegrationsService,
    private dialogService: DialogService,
    private route: ActivatedRoute, private router: Router) {

    this.filterDateFrom = moment().add(-1, 'month');
    this.filterDateTo = moment();


    this._leadFormApi.getById(this.id).pipe(
      delay(250),
      tap(response => {
        this.leadForm = response;
        this.isLoading = false;
      }),
      delay(500)
    ).subscribe(x => {
      localStorage.setItem(`leadform_${this.id}`, moment().utc().unix().toString());
      this.loadIntegrations();
      this.loadStats();
      this.loadLeads();
    });
  }

  ngOnInit(): void {
    this.basicOptions = {
      plugins: {
        legend: {
          labels: {
            color: '#495057'
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: '#495057'
          },
          grid: {
            color: '#ebedef'
          }
        },
        y: {
          ticks: {
            beginAtZero: true,
            callback: function (value: any) { if (value % 1 === 0) { return value; } },
            color: '#495057'
          },
          grid: {
            color: '#ebedef'
          }
        }
      }
    };
  }

  public goBackClicked(): void {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  private loadLeads(): void {
    this.isLoadingLeads = true;
    this.leads = [];

    this._leadApi.fetch([this.id]).pipe(
      delay(250),
      tap(response => {
        this.leadsOverall = response[1];
      })
    ).subscribe(response => {
      this.leads = response[0];

      this.leads.forEach(lead => {
        this.enrich(lead);
      });

      this.isLoadingLeads = false;
    });
  }

  private loadIntegrations(): void {
    this.isLoadingIntegrations = true;
    this.integrations = [];

    this._integrationPlatform.list().pipe(
      switchMap(response => {
        this.platforms = response;

        const lk = response.find(x => x.codename === 'lk');

        if (!lk) {
          return of([]);
        }

        const leadForms = lk.findObjectByCodename('leadform');

        if (!leadForms) {
          return of([]);
        }

        this.sourceObjectId = leadForms.id;
        this.sourceEntityId = this.id;

        return this._integrationApi.list({
          sourceObjectId: this.sourceObjectId,
          sourceEntityId: this.sourceEntityId
        });
      }),
      delay(1000),
      tap(response => {
        this.isLoadingIntegrations = false;
      })
    ).subscribe(response => {
      this.integrations = response;
    });
  }

  public createIntegration(): void {
    const integration = new Integration(this.sourceObjectId ?? '', this.sourceEntityId ?? '');

    const ref = this.dialogService.open(IntegrationDialogComponent, {
      header: 'Создать интеграцию',
      width: '80%',
      data: this.encrichIntegration(integration)
    });

    ref.onClose.subscribe(response => {
      this.loadIntegrations()
    });
  }

  public updateIntegration(integration: Integration): void {
    const ref = this.dialogService.open(IntegrationDialogComponent, {
      header: 'Редактировать интеграцию',
      width: '80%',
      data: this.encrichIntegration(integration)
    });

    ref.onClose.subscribe(response => {
      this.loadIntegrations()
    });
  }

  private encrichIntegration(integration: Integration): Integration {
    integration.source = this.platforms.find(x => x.hasObjectWithId(integration.sourceObjectId)) ?? null;

    console.log('integration.source', integration.source);

    if (integration.source) {
      integration.sourceObject = integration.source.findObjectById(integration.sourceObjectId);

      if (integration.sourceObject) {
        integration.sourceObject.fields = [];

        this.leadForm?.fields?.forEach(field => {
          integration.sourceObject?.fields.push(new IntegrationPlatformObjectField(field.codename, field.name));
        });
      }
    }

    integration.destination = this.platforms.find(x => integration.destinationObjectId && x.hasObjectWithId(integration.destinationObjectId)) ?? null;

    if (integration.destination && integration.destinationObjectId) {
      integration.destinationObject = integration.destination.findObjectById(integration.destinationObjectId);
    }

    return integration;
  }

  private loadStats(): void {
    this.isLoadingLeadStats = true;
    this._leadApi.getStatistics(this.id, this.filterDateFrom.toDate(), this.filterDateTo.toDate()).pipe(
      delay(250)
    ).subscribe(response => {
      this.leadStats = response;
      this.isLoadingLeadStats = false;

      this.buildGraph();
    });
  }

  private buildGraph(): void {
    const data: any[] = [];

    let theMoment: any = moment(this.filterDateFrom);

    while (!theMoment.isSameOrAfter(this.filterDateTo, 'day')) {
      const iterationMoment = moment(theMoment).add(1, 'day');
      //const formattedMoment = iterationMoment.format('YYYY-MM-dd');

      const stats = this.leadStats.find(x => x.aggregationMoment.isSame(iterationMoment, 'day'));

      data.push({
        moment: iterationMoment,
        value: stats?.count ?? 0
      });

      theMoment = moment(iterationMoment);
    }

    // //
    // let previousMoment: any = undefined;

    // this.leadStats.forEach(stats => {

    //   // Добавим текущее значение
    //   const currentMoment = moment(stats.date.split('T')[0]);

    //   //console.log(`Primary step: ${previousMoment?.format("YYYY-MM-DD")} -> ${currentMoment.format("YYYY-MM-DD")}`);
    //   //

    //   if (previousMoment) {
    //     let newMoment = moment(previousMoment).add(1, 'day');

    //     while (newMoment < currentMoment) {
    //       //console.log(`\tSub step: ${newMoment?.format("YYYY-MM-DD")} -> ${currentMoment.format("YYYY-MM-DD")}`);
    //       data.push({
    //         moment: moment(newMoment),
    //         value: 0
    //       });

    //       newMoment = newMoment.add(1, 'day');
    //     }
    //   }

    //   previousMoment = moment(currentMoment);

    //   data.push({
    //     moment: currentMoment,
    //     value: stats.count
    //   });
    // });

    // data.sort((a,b) => a.moment - b.moment);

    this.basicData = {
      labels: data.map(x => x.moment.format("Do MMM")),
      datasets: [
        {
          label: 'Кол-во лидов',
          data: data.map(x => x.value),
          fill: true,
          borderColor: '#26ffbe',
          tension: .0,
          backgroundColor: 'rgba(38, 255, 190,.8)'

        }
      ]
    };
  }

  // ; 

  private enrich(lead: Lead): void {
    lead.fields.forEach(field => {
      if (field.leadFormFieldId) {
        field.leadFormField = this.leadForm?.fields.find(x => x.id === field.leadFormFieldId) ?? null;
      }
    });

    lead.sortFields();
  }
}
