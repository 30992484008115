import { BrowserModule } from '@angular/platform-browser';
import { forwardRef, LOCALE_ID, NgModule, Provider } from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiModule } from 'src/api/administration/api.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';

import { DatePipe, registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';

import {TableModule} from 'primeng/table';
import {CheckboxModule} from 'primeng/checkbox';
import {ButtonModule} from 'primeng/button';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import {InputTextModule} from 'primeng/inputtext';
import {InputSwitchModule} from 'primeng/inputswitch';
import {TooltipModule} from 'primeng/tooltip';
import {ChartModule} from 'primeng/chart';
import {CalendarModule} from 'primeng/calendar';
import {SelectButtonModule} from 'primeng/selectbutton';
import {SkeletonModule} from 'primeng/skeleton';
import {DropdownModule} from 'primeng/dropdown';
import { TagModule } from 'primeng/tag';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {InputNumberModule} from 'primeng/inputnumber';
import { MultiSelectModule } from 'primeng/multiselect';
import { TabViewModule } from 'primeng/tabview';
import { FieldsetModule } from 'primeng/fieldset';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { PanelModule } from 'primeng/panel';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LinkListComponent } from './routes/link-list/link-list.component';
import { LinkSingleComponent } from './routes/link-single/link-single.component';
import { LinkRepositoryService } from './services/repositories/link-repository.service';
import { EventRepositoryService } from './services/repositories/event-repository.service';
import { LinkConfigureDialogueComponent } from './dialogues/link-configure-dialogue/link-configure-dialogue.component';
import { LinkVisitRepositoryService } from './services/repositories/link-visit-repository.service';
import { AuthService } from './services/auth.service';

registerLocaleData(localeRu, 'ru');

import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { SignInDialogueComponent } from './dialogues/sign-in-dialogue/sign-in-dialogue.component';
import { SignService } from 'src/api/auth/services';
import { ApiInterceptor } from './api-interceptor';
import { EntityRightsComponent } from './dialogues/entity-rights/entity-rights.component';
import { RightsRepositoryService } from './services/repositories/rights-repository.service';
import { UserRepositoryService } from './services/repositories/user-repository.service';
import { UserListComponent } from './routes/user-list/user-list.component';
import { UserSingleComponent } from './routes/user-single/user-single.component';
import { UserDialogComponent } from './dialogues/user-dialog/user-dialog.component';
import { HomeComponent } from './routes/home/home.component';
import * as moment from 'moment';
import { ClipboardModule } from 'ngx-clipboard';
import { CounterpartyListComponent } from './routes/legal/counterparty-list/counterparty-list.component';
import { CounterpartySingleComponent } from './routes/legal/counterparty-single/counterparty-single.component';
import { CounterpartyDialogueComponent } from './dialogues/counterparty-dialogue/counterparty-dialogue.component';
import { ContractDialogueComponent } from './dialogues/contract-dialogue/contract-dialogue.component';
import { ContractListComponent } from './routes/legal/contract-list/contract-list.component';
import { CreativeListComponent } from './routes/legal/creative-list/creative-list.component';
import { CreativeDialogueComponent } from './dialogues/creative-dialogue/creative-dialogue.component';
import { InvoiceListComponent } from './routes/legal/invoice-list/invoice-list.component';
import { InvoiceDialogueComponent } from './dialogues/invoice-dialogue/invoice-dialogue.component';
import { CounterpartySnippetComponent } from './components/counterparty-snippet/counterparty-snippet.component';
import { ContractSnippetComponent } from './components/contract-snippet/contract-snippet.component';
import { SyncButtonComponent } from './components/sync-button/sync-button.component';
import { ContractStatisticsComponent } from './dialogues/contract-statistics/contract-statistics.component';
import { ContractSearchFormComponent } from './components/search-forms/contract-search-form/contract-search-form.component';
import { CounterpartySearchFormComponent } from './components/search-forms/counterparty-search-form/counterparty-search-form.component';
import { CreativeSearchFormComponent } from './components/search-forms/creative-search-form/creative-search-form.component';
import { UploadImageComponent } from './dialogues/upload-image/upload-image.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export const API_INTERCEPTOR_PROVIDER: Provider = {
  provide: HTTP_INTERCEPTORS,
  useExisting: forwardRef(() => ApiInterceptor),
  multi: true
};

moment.locale('ru');

@NgModule({
  declarations: [
    AppComponent,

    // Routes >>>
    LinkListComponent,
    LinkSingleComponent,
    LinkConfigureDialogueComponent,
    SignInDialogueComponent,
    EntityRightsComponent,
    UserListComponent,
    UserSingleComponent,
    UserDialogComponent,
    HomeComponent,
    CounterpartyListComponent,
    CounterpartySingleComponent,
    CounterpartyDialogueComponent,
    ContractDialogueComponent,
    ContractListComponent,
    CreativeListComponent,
    CreativeDialogueComponent,
    InvoiceListComponent,
    InvoiceDialogueComponent,
    CounterpartySnippetComponent,
    ContractSnippetComponent,
    SyncButtonComponent,
    ContractStatisticsComponent,
    ContractSearchFormComponent,
    CounterpartySearchFormComponent,
    CreativeSearchFormComponent,
    UploadImageComponent,
    // Routes <<<
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'rus',
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  }),
    FormsModule,
    ReactiveFormsModule,
    ApiModule.forRoot({ rootUrl: '' }),
    HttpClientModule,
    
    // PrimeNG starts >>>
    TableModule,
    CheckboxModule,
    ButtonModule,
    DynamicDialogModule,
    InputTextModule,
    InputSwitchModule,
    TooltipModule,
    ChartModule,
    CalendarModule,
    SelectButtonModule,
    SkeletonModule,
    DropdownModule,
    TagModule,
    MessagesModule,
    MessageModule,
    InputNumberModule,
    MultiSelectModule,
    TabViewModule,
    FieldsetModule,
    TriStateCheckboxModule,
    PanelModule,
    ConfirmDialogModule,
    // PrimeNG ends   <<<

    AppRoutingModule,
    ClipboardModule
  ],
  providers: [
    ApiInterceptor,
    API_INTERCEPTOR_PROVIDER,
    { provide: LOCALE_ID, useValue: 'ru' },
    // Repositories >>>
    LinkRepositoryService,
    EventRepositoryService,
    LinkVisitRepositoryService,
    RightsRepositoryService,
    UserRepositoryService,
    // Repositories <<<
    AuthService,
    SignService,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
