<p-panel header="Инструменты поиска" toggler="header" [toggleable]="true" [collapsed]="true">
    <div class="grid">
        <div class="col-12 sm:col-6 md:col-3">
            <label>Название</label>
            <input type="text" class="w-full" pInputText [(ngModel)]="searchTerm" placeholder="Любое" />
        </div>

        <div class="col-12 sm:col-6 md:col-3">
            <label>ИНН</label>
            <input type="text" class="w-full" pInputText [(ngModel)]="inn" placeholder="Любой" />
        </div>

        <div class="col-12 sm:col-6 md:col-3">
            <label>ОГРН</label>
            <input type="text" class="w-full" pInputText [(ngModel)]="ogrn" placeholder="Любой" />
        </div>

        <div class="col-12 sm:col-6 md:col-3">
            <label>Типы контрагента</label>
            <p-multiSelect [options]="types" [(ngModel)]="selectedTypes" [showClear]="true"
                styleClass="w-full" placeholder="Любые" display="chip"
                optionLabel="name"  optionValue="code"></p-multiSelect>
        </div>

        <div class="col-12 sm:col-6 md:col-3">
            <label>Статус передачи в ОРД</label>
            <p-dropdown [options]="syncStates" [(ngModel)]="syncState" [showClear]="true"
                optionLabel="name" optionValue="code"
                styleClass="w-full" placeholder="Любой">

            </p-dropdown>
        </div>

        <div class="col-12 sm:col-6 md:col-3 flex align-items-center">
            <div class="mt-4 flex align-items-center">
                <label class="mr-3">Принято ЕРИР</label>

                <p-triStateCheckbox class="flex" [(ngModel)]="acceptedByErir" inputId="acceptedByErir"></p-triStateCheckbox>
            </div>
        </div>

        <div class="col-12"></div>
        <div class="col"></div>

        <div class="col-12 sm:col-6 md:col-3">
            <p-button (click)="reset()" icon="pi pi-times" label="Очистить форму" styleClass="w-full p-button-text p-button-danger"></p-button>
        </div>

        <div class="col-12 sm:col-6 md:col-3">
            <p-button (click)="send()" icon="pi pi-search" label="Выполнить поиск" styleClass="w-full p-button-outlined"></p-button>
        </div>
    </div>
</p-panel>