import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, filter, switchMap, take} from "rxjs/operators";
import { AuthService } from "./services/auth.service";

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  
  constructor(private authService: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = this.addTokenHeader(req, this.authService.accessToken);

    // Also handle errors globally
    return next.handle(req).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && !req.url.includes('auth/') && error.status === 401) {
          return this.handle401Error(req, next);
        }
        return throwError(error);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      const token = this.authService.refreshToken;

      if (token) {
        return this.authService.RefreshToken(token).pipe(
          switchMap((token: any) => {
            this.isRefreshing = false;

            this.refreshTokenSubject.next(this.authService.accessToken);
            
            return next.handle(this.addTokenHeader(request, this.authService.accessToken));
          }),
          catchError((err) => {
            this.isRefreshing = false;
            
            this.authService.SignOut();
            return throwError(err);
          })
        );
      }
    }

    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({ headers: request.headers.set('Authorization', `Bearer ${token}`) });
  }
}