import { ContractViewModel } from "../contract/contract-view-model";
import { CreativeViewModel } from "../creative/creative-view-model";
import { PlatformViewModel as PlatformViewModel } from "../platform/platform-view-model";

export class InvoiceViewModel {
    public id: string | null = null;

    public contractId: string;
    public contract : ContractViewModel | null = null;

    public serialNumber: string;

    public dateOfConclusion: string;
    public dateStart: string;
    public dateEnd: string;

    public amount: number;
    public isVatIncluded: boolean = false;

    private _items: InvoiceItemViewModel[] = [];
    public get items(): InvoiceItemViewModel[] {
        return this._items;
    }

    public isSynced: boolean = false;
    public isSyncing: boolean = false;
    public isAcceptedByErir: boolean = false;
    public erirId: string = '';
    
    public createdAt: string = '';

    constructor(contractId: string, 
        serialNumber: string, 
        dateOfConclusion: string, 
        dateStart: string, 
        dateEnd: string,
        amount: number) {
        this.contractId = contractId;
        this.serialNumber = serialNumber;
        this.dateOfConclusion = dateOfConclusion;
        this.dateStart = dateStart;
        this.dateEnd = dateEnd;
        this.amount = amount;
    }

    public addItem(item: InvoiceItemViewModel): void {
        this._items = [...this._items, item];
    }
}

export class InvoiceItemViewModel {
    public id: string | null = null;

    public contractId: string;
    public amount: number;
    public isVatIncluded: boolean = false;

    private _creativeAggregation: InvoiceItemCreativeAggregationViewModel[] = [];
    public get creativeAggregation(): InvoiceItemCreativeAggregationViewModel[] {
        return this._creativeAggregation;
    }

    constructor(contractId: string, amount: number) {
        this.contractId = contractId;
        this.amount = amount;
    }

    public addCreativeAggregation(creativeAggregation: InvoiceItemCreativeAggregationViewModel): void {
        this._creativeAggregation = [...this._creativeAggregation, creativeAggregation];
    }
}

export class InvoiceItemCreativeAggregationViewModel {
    public id: string | null = null;

    public creativeId: string;
    public creative: CreativeViewModel | null = null;

    public platformId: string;
    public platform: PlatformViewModel | null = null;

    public impressions: number;

    public amount: number;
    public isVatIncluded: boolean = false;

    constructor(creativeId: string, platformId: string, impressions: number, amount: number) {
        this.creativeId = creativeId;
        this.platformId = platformId;
        this.impressions = impressions;
        this.amount = amount;
    }
}