import { Injectable } from '@angular/core';
import { StatInfo } from 'src/api/legal/models';
import { StatsViewModel } from 'src/app/view-models/stats/stats';

@Injectable({
  providedIn: 'root'
})
export class StatsAssemblerService {

  public ToViewModel(other: StatInfo): StatsViewModel {
    const model = new StatsViewModel(new Date(other.date ?? ''), other.impressionsAmount ?? 0);


    return model;
  }
}
