import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ContractApiService } from 'src/app/services/api/contract-api.service';
import { CreativeApiService } from 'src/app/services/api/creative-api.service';
import { InvoiceApiService } from 'src/app/services/api/invoice-api.service';
import { PlatformApiService } from 'src/app/services/api/platform-api.service';
import { ContractViewModel } from 'src/app/view-models/contract/contract-view-model';
import { CreativeViewModel } from 'src/app/view-models/creative/creative-view-model';
import { InvoiceItemCreativeAggregationViewModel, InvoiceItemViewModel, InvoiceViewModel } from 'src/app/view-models/invoice/invoice-view-model';
import { PlatformViewModel } from 'src/app/view-models/platform/platform-view-model';

@Component({
  selector: 'app-invoice-dialogue',
  templateUrl: './invoice-dialogue.component.html',
  styleUrls: ['./invoice-dialogue.component.scss']
})
export class InvoiceDialogueComponent implements OnInit {
  public isBusy: boolean = false;

  public form: FormGroup;

  public isCreateMode: boolean = false;
  private _values: InvoiceViewModel | null;

  public get contracts(): ContractViewModel[] {
    return this.contractApi.items;
  }

  public get platforms(): PlatformViewModel[] {
    return this.platformApi.items;
  }

  public get creatives(): CreativeViewModel[] {
    const contractId = this.form.getRawValue().contractId;

    if (!contractId) {
      return [];
    }

    return this.creativeApi.items.filter(x => x.contracts.findIndex(y => y.contractId ===contractId) >= 0);
  }

  public get freeCreatives(): CreativeViewModel[] {
    return this.creativeApi.items.filter(x => this.items.findIndex(y => y.creativeId === x.id) < 0);
  }

  public get isInvalid(): boolean {
    return !this.form.valid;// || (!this.isCreateMode && !this.hasValidItems);
  }

  public items: InvoiceDialogItem[] = [];

  public get hasValidItems(): boolean {
    return this.items.findIndex(x => !x.isRemoved) >= 0;
  }

  constructor(private api: InvoiceApiService, 
    private contractApi: ContractApiService,
    private platformApi: PlatformApiService,
    private creativeApi: CreativeApiService,
    public ref: DynamicDialogRef, 
    public config: DynamicDialogConfig) {
    this._values = this.config.data ?? null;

    this.isCreateMode = (!this.config.data?.id) ?? false;

    this.form = new FormGroup({
      id: new FormControl({
        value: this._values?.id ?? null,
        disabled: false
      }),
      contractId: new FormControl({
        value: this._values?.contractId ?? null,
        disabled: !this.isCreateMode
      }),
      serialNumber: new FormControl({
        value: this._values?.serialNumber,
        disabled: false
      }, [Validators.maxLength(1024)]),
      dateOfConclusion: new FormControl({
        value: this._values?.dateOfConclusion,
        disabled: false
      }, [Validators.required]),
      dateStart: new FormControl({
        value: this._values?.dateStart,
        disabled: false
      }, [Validators.required]),
      dateEnd: new FormControl({
        value: this._values?.dateEnd,
        disabled: false
      }, [Validators.required, this.validateDateEnd]),
      amount: new FormControl({
        value: this._values?.amount,
        disabled: true,
      }, [Validators.required]),
      isVatIncluded: new FormControl({
        value: this._values?.isVatIncluded ?? false,
        disabled: false
      }, [Validators.required])
    });
  }

  ngOnInit(): void {
    this.contractApi.Load(1, 500).subscribe();
    this.platformApi.Load().subscribe();
    this.creativeApi.Load(1, 500).subscribe(x => {
      if (this._values) {
        this.BuildDialogItems(this._values);
      }
    });


  }

  private validateDateEnd(form: FormGroup): ValidationErrors {
    const errors: ValidationErrors = {

    };

    // const dateOfConclusion = form.get('dateOfConclusion')?.value ?? null;
    
    // if (!dateOfConclusion) {
    //   console.log('dateOfConclusion is required', dateOfConclusion);
    //   errors['dateOfConclusionRequired'] = 'dateOfConclusion is required';
    // }

    // const dateEnd = form.get('dateEnd')?.value ?? null;

    // if (!dateEnd) {
    //   console.log('dateEnd is required', dateEnd);
    //   errors['dateEndRequired'] = 'dateEnd is required';
    // }

    // if (dateOfConclusion && dateEnd) {
    //   if (dateEnd > dateOfConclusion) {
    //     console.log('dateEnd must be <= dateOfConclusion', dateEnd, dateOfConclusion);
    //     errors['dateEndTooBig'] = 'dateEnd must be <= dateOfConclusion';
    //   }
    // }

    return errors;
  }

  public AddItem(): void {
    const model = new InvoiceDialogItem();
    model.platformId = this.platforms[this.platforms.length - 1].id ?? '';

    if (!this.isCreateMode) {
      const values = this.form.getRawValue();

      model.contractId = values.contractId;
    }

    this.items = [...this.items, model];
  }

  public RemoveItem(item: InvoiceDialogItem): void {
    item.isRemoved = true;

    this.RecalculateAmount();
  }

  public RestoreItem(item: InvoiceDialogItem): void {
    item.isRemoved = false;

    this.RecalculateAmount();
  }

  public RecalculateAmount(): void {
    let amount = 0;

    this.items.forEach(item => {
      if (!item.isRemoved) {
        amount = amount + item.amount;
      }
    });

    this.form.controls['amount'].setValue(amount);
  }

  public Submit(): void {
    if (!this.form.valid) {
      return;
    }

    const model = this.GenerateModel();

    this.isBusy = true;

    if (model.id) {
      this.UpdateEntity(model.id, model);
    } else {
      this.CreateEntity(model);
    }
  }

  private GenerateModel(): InvoiceViewModel {
    const values = this.form.getRawValue();

    const model = new InvoiceViewModel(values.contractId, values.serialNumber, values.dateOfConclusion, values.dateStart, values.dateEnd, values.amount);

    model.id = values.id ?? null;
    model.isVatIncluded = values.isVatIncluded ?? false;

    // Generate items
    const contractIds = [...new Set(this.items.map(item => item.contractId))];

    let overallAmount = 0;

    contractIds?.forEach(contractId => {
      const groupId = contractId ?? model.contractId;

      let itemViewModel = this._values?.items.find(x => x.contractId === groupId) ?? new InvoiceItemViewModel(groupId, 0);

      let contractAmount = 0;

      const contractItems = this.items.filter(item => !item.isRemoved && item.contractId === contractId);

      console.log('contractItems', contractItems, contractId);

      contractItems.forEach(item => {
        let creativeViewModel = itemViewModel?.creativeAggregation.find(x => x.id === item.creativeAggregationId);
      
        if (!creativeViewModel) {
          creativeViewModel = new InvoiceItemCreativeAggregationViewModel(item.creativeId, item.platformId, item.impressions, item.amount);
        } else {
          creativeViewModel.platformId = item.platformId;
          creativeViewModel.impressions = item.impressions;
          creativeViewModel.amount = item.amount;
        }

        creativeViewModel.isVatIncluded = item.isVatIncluded;
        
        contractAmount = contractAmount + creativeViewModel.amount;

        itemViewModel.addCreativeAggregation(creativeViewModel);
      });

      itemViewModel.amount = contractAmount;
      overallAmount = overallAmount + itemViewModel.amount;
      
      model.addItem(itemViewModel);
    });

    model.amount = overallAmount;

    return model;
  }

  private CreateEntity(model: InvoiceViewModel): void {
    this.api.Create(model).subscribe(id => {

      // TODO RemoveItems (that already stored)

      this.isBusy = false;
        this.ref.close(true);
    });
  }

  private UpdateEntity(id: string, model: InvoiceViewModel): void {
    const removeIds = [...new Set(this.items.filter(x => x.isRemoved && x.creativeAggregationId).map(item => item.creativeAggregationId ?? ''))] ?? [];

    this.api.Update(id, model, removeIds).subscribe(x => {

      this.isBusy = false;
        this.ref.close(x);
    });
  }

  private BuildDialogItems(invoice: InvoiceViewModel): void {
    console.log('BuildDialogItems', invoice);
    this.items = [];

    invoice.items.forEach(item => {
      item.creativeAggregation.forEach(creativeAggregation => {
        const dialogItem = new InvoiceDialogItem();
          dialogItem.creativeAggregationId = creativeAggregation.id
          dialogItem.contractId = item.contractId;
          dialogItem.creativeId = creativeAggregation.creativeId;
          dialogItem.platformId = creativeAggregation.platformId;
          dialogItem.impressions = creativeAggregation.impressions;
          dialogItem.amount = creativeAggregation.amount;
          dialogItem.isVatIncluded = creativeAggregation.isVatIncluded;

          if (dialogItem.creativeId  && dialogItem.platformId) {
            this.items = [...this.items, dialogItem];
          }
      });
    });
  }
}

export class InvoiceDialogItem {
  public creativeAggregationId: string | null = null;

  public contractId: string | null = null;
  public creativeId: string = '';
  public platformId: string = '';
  public impressions: number = 0;
  public amount: number = 0;
  public isVatIncluded: boolean = false;

  public isRemoved: boolean = false;
}