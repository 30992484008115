import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LinkRepositoryService } from 'src/app/services/repositories/link-repository.service';
import { LinkVisitRepositoryService } from 'src/app/services/repositories/link-visit-repository.service';
import { LinkPageVisitAggregation } from 'src/app/view-models/link-visit/link-page-visit-aggregation-view-model';
import { LinkVisitAggregation as LinkVisitAggregationViewModel } from 'src/app/view-models/link-visit/link-visit-aggregation-view-model';
import { LinkViewModel } from 'src/app/view-models/link/link-view-model';

@Component({
  selector: 'app-link-single',
  templateUrl: './link-single.component.html',
  styleUrls: ['./link-single.component.scss']
})
export class LinkSingleComponent implements OnInit {
  public isLoading: boolean = false;

  private _viewModel: LinkViewModel | null = null;
  public get viewModel(): LinkViewModel | null {
    return this._viewModel;
  }

  private _linkVisitViewModel: LinkVisitAggregationViewModel[] = [];
  public get linkVisitViewModel(): LinkVisitAggregationViewModel[] {
    return this._linkVisitViewModel;
  }

  private _linkPageVisits: LinkPageVisitAggregation[] = [];
  public get linkPageVisits(): LinkPageVisitAggregation[] {
    return this._linkPageVisits;
  }

  public basicData: any;
  public basicOptions: any;

  public pagesData: any;
  public pagesOptions: any;

  private _linkId: string = '';

  public dateFrom: Date;
  public dateTo: Date;

  public dateMin: Date = new Date();
  public dateMax: Date = new Date();

  public dataDisplayMode: string = 'chart';
  public dataDisplayModes = [
    {
      icon: 'fa-solid fa-table',
      label: 'Таблицей',
      value: 'table'
    }, {
      icon: 'fa-solid fa-chart-line',
      label: 'Графиком',
      value: 'chart'
    }
  ];

  constructor(private linkAPI: LinkRepositoryService,
    private linkVisitAPI: LinkVisitRepositoryService,
    private route: ActivatedRoute,
    private router: Router) {
    this.dateTo = new Date();
    this.dateFrom = new Date();
  }

  ngOnInit(): void {
    this._linkId = this.route.snapshot.paramMap.get('websiteId') ?? '';

    this.isLoading = true;
    this.linkAPI.GetLink(this._linkId).subscribe(x => {
      this._viewModel = x;

        if (x.overallStats?.rawSince) {
          this.dateMin = new Date(x.overallStats.rawSince);
        }

        this.AssignDates14Days();
    }, err => {
      this.isLoading = false;
    });

    
  }

  public AssignDatesThisWeek(): void {
    this.dateTo = new Date();

    this.LoadLinkVisits();
  }

  public AssignDatesThisMonth(): void {
    this.dateTo = new Date();
    this.dateFrom = new Date(this.dateTo.getFullYear(), this.dateTo.getMonth(), 1);

    this.UpdateDates();
    this.LoadLinkVisits();
  }

  public AssignDates7Days(): void {
    this.dateTo = new Date();
    let fromDate = new Date();

    fromDate.setDate(fromDate.getDate() - 7);

    this.dateFrom = new Date(fromDate);

    this.UpdateDates();
    this.LoadLinkVisits();
  }

  public AssignDates14Days(): void {
    this.dateTo = new Date();
    let fromDate = new Date();

    fromDate.setDate(fromDate.getDate() - 14);

    this.dateFrom = new Date(fromDate);

    this.UpdateDates();
    this.LoadLinkVisits();
  }

  public AssignDates28Days(): void {
    this.dateTo = new Date();
    let fromDate = new Date();

    fromDate.setDate(fromDate.getDate() - 28);

    this.dateFrom = new Date(fromDate);

    this.UpdateDates();
    this.LoadLinkVisits();
  }

  private UpdateDates(): void {
    if (this.dateFrom < this.dateMin) {
      this.dateFrom = new Date(this.dateMin);
    }
  }

  public LoadLinkVisits(): void {
    if (!this._linkId) {
      return;
    }

    this.isLoading = true;

    this._linkVisitViewModel = [];
    this.linkVisitAPI.GetLinkVisits(this._linkId, this.dateFrom, this.dateTo).subscribe(result => {

      let iterationDate = new Date(this.dateFrom);

      while (iterationDate <= this.dateTo) {
        const found = result.find(x => x.rawDate.toDateString() === iterationDate.toDateString());

        if (found) {
          this._linkVisitViewModel.push(found);
        } else {
          this._linkVisitViewModel.push(new LinkVisitAggregationViewModel(new Date(iterationDate), 0, 0));
        }

        iterationDate.setDate(iterationDate.getDate() + 1);
      }

      this.RebuildTotalGraph();

      this.isLoading = false;
    }, err => {
      this.isLoading = false;
    });

    this._linkPageVisits = [];

    this.linkVisitAPI.GetLinkPageVisits(this._linkId, this.dateFrom, this.dateTo).subscribe(result => {
      this._linkPageVisits = result;
      // let iterationDate = new Date(this.dateFrom);

      // while (iterationDate <= this.dateTo) {
      //   const found = result.find(x => x.rawDate.toDateString() === iterationDate.toDateString());

      //   if (found) {
      //     this._linkPageVisits.push(found);
      //   } else {
      //     this._linkPageVisits.push(new Page(new Date(iterationDate), 0, 0));
      //   }

      //   iterationDate.setDate(iterationDate.getDate() + 1);
      // }

      this.RebuildPagesGraph();
    });
  }

  private RebuildTotalGraph(): void {
    this.basicData = {
      labels: [],
      datasets: [
        {
          label: 'Всего переходов',
          data: [],
          borderWidth: 2,
          fill: false,
          borderColor: '#42A5F5',
          tension: .2
        },
        {
          label: 'Уникальных переходов',
          data: [],
          fill: true,
          borderDash: [10, 5],
          borderColor: '#FFA726',
          tension: .2,
          backgroundColor: 'rgba(255,167,38,0.2)'
        }
      ]
    };

    this._linkVisitViewModel.forEach(x => {
      this.basicData.labels.push(x.date);
      this.basicData.datasets[0].data.push(x.overallVisits);
      this.basicData.datasets[1].data.push(x.uniqueVisits);
    });

    this.basicOptions = {
      plugins: {
        legend: {
          labels: {
            color: '#495057'
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: '#495057'
          },
          grid: {
            color: '#ebedef'
          }
        },
        y: {
          ticks: {
            beginAtZero: true,
            callback: function(value:any) {if (value % 1 === 0) {return value;}},
            color: '#495057'
          },
          grid: {
            color: '#ebedef'
          }
        }
      }
    };
  }

  private RebuildPagesGraph(): void {
    this.pagesData = {
      labels: [1],
      datasets: []
    };

    this._linkPageVisits.forEach(x => {
      this.pagesData.datasets.push({
        label: `${x.path} Всего переходов`,
        data: x.aggregations.map(y => y.overallVisits),
        borderDash: [10, 5],
        borderWidth: 2,
        fill: false,
        borderColor: '#42A5F5',
        tension: .2
      });

      this.pagesData.datasets.push({
        label: `${x.path} Уникальных переходов`,
        data: x.aggregations.map(y => y.uniqueVisits),
        fill: false,
        borderColor: '#FFA726',
        tension: .2,
        backgroundColor: 'rgba(255,167,38,0.2)'
      });
      // this.basicData.labels.push(x.date);
      // this.basicData.datasets[0].data.push(x.overallVisits);
      // this.basicData.datasets[1].data.push(x.uniqueVisits);
    });

    this.pagesOptions = {
      plugins: {
        legend: {
          labels: {
            color: '#495057'
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: '#495057'
          },
          grid: {
            color: '#ebedef'
          }
        },
        y: {
          ticks: {
            color: '#495057'
          },
          grid: {
            color: '#ebedef'
          }
        }
      }
    };
  }

  public GoToLinks(): void {
    this.router.navigate(['/link']);
  }
}
