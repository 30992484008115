import { Injectable } from '@angular/core';
import { CreativeContractDto, CreativeDto, CreativeEconomicsClassifierDto } from 'src/api/legal/models';
import { CreativeContractViewModel, CreativeEconomicClassificationViewModel, CreativeViewModel } from 'src/app/view-models/creative/creative-view-model';

@Injectable({
  providedIn: 'root'
})
export class CreativeAssemblerService {

  constructor() { }

  public ToViewModel(other: CreativeDto): CreativeViewModel {
    const model = new CreativeViewModel(other.name ?? '', other.form ?? '', other.campaignType ?? '');
    model.id = other.id ?? null;

    model.selfAdvertisementCounterpartyId = other.selfAdvertiseCounterpartyId ?? null;

    model.description = other.description ?? '';
    model.adLink = other.adLink ?? '';
    model.previewLink = other.previewLink ?? '';
    model.isPoliticAd = other.isPoliticAd ?? false;
    model.isSocialAd = other.isSocialAd ?? false;

    model.contracts = other.contracts?.map(x => this.ToContractViewModel(x)) ?? [];

    model.isSynced = other.syncState === 'synced';
    model.isSyncing = other.syncState === 'syncing';
    model.isAcceptedByErir = other.isAcceptedByErir ?? false;
    model.erirId = other.erirId ?? '';
    model.marker = other.marker ?? null;
    
    model.economicClassifications = other.economicsClassifiers?.map(x => this.ToEconomicClassificationViewModel(x))?? [];

    model.createdAt = other.createdAt ?? '';

    return model;
  }

  public ToContractViewModel(other: CreativeContractDto): CreativeContractViewModel {
    const model = new CreativeContractViewModel(other.contractId ?? '');


    model.id = other.id ?? null;
    model.marker = other.marker ?? '';
    model.creativeId = other.creativeId ?? '';
    model.isSynced = other.syncState == 'synced';
    model.isSyncing = other.syncState == 'syncing';
    model.isAcceptedByErir = other.isAcceptedByErir ?? false;
    model.erirId = other.erirId ?? '';
    
    model.createdAt = other.createdAt ?? '';

    return model;
  }

  public ToDto(other: CreativeViewModel): CreativeDto {
    return {
      id: other.id ?? undefined,
      name: other.name ?? '',
      selfAdvertiseCounterpartyId: other.selfAdvertisementCounterpartyId ?? undefined,
      description: other.description ?? '',
      form: other.formCode ?? '',
      campaignType: other.campaignTypeCode ?? '',
      adLink: other.adLink ?? undefined,
      previewLink: other.previewLink ?? undefined,
      isPoliticAd: other.isPoliticAd,
      isSocialAd: other.isSocialAd,
    }
  }

  public ToCreativeContractDto(other: CreativeContractViewModel): CreativeContractDto {
    return {
      id: other.id ?? undefined,
      creativeId: other.creativeId ?? undefined,
      contractId: other.contractId ?? undefined,
      syncState: undefined,
      marker: undefined
    };
  }

  public ToEconomicClassificationViewModel(other: CreativeEconomicsClassifierDto): CreativeEconomicClassificationViewModel {
    const model = new CreativeEconomicClassificationViewModel(other.economicClassificationId ?? '');

    return model;
  }

  public ToEconomicClassificationDto(other: CreativeEconomicClassificationViewModel):CreativeEconomicsClassifierDto {
    return {
      economicClassificationId: other.economicClassificationId ?? undefined
    };
  }
}
