import { Injectable } from '@angular/core';
import { ContractDto } from 'src/api/legal/models';
import { ContractViewModel } from 'src/app/view-models/contract/contract-view-model';
import { CreativeAssemblerService } from './creative-assembler.service';

@Injectable({
  providedIn: 'root'
})
export class ContractAssemblerService {

  constructor(private creativeAssembler: CreativeAssemblerService) { }

  public ToViewModel(other: ContractDto): ContractViewModel {
    const model = new ContractViewModel(
      other?.type ?? '',
      other.subject ?? '',
      other.serialNumber ?? '',
      other.dateOfConclusion ?? '',
      other.contractorCounterpartyId ?? '',
      other.clientCounterpartyId ?? '',
      other.amount ?? 0,
    );

    model.id = other.id ?? null;
    model.parentId = other.parentContractId ?? null;
    model.isVatIncluded = other.isVatIncluded ?? false;
    model.isSynced = other.syncState === 'synced';
    model.isSyncing = other.syncState === 'syncing';
    
    model.invoiceAutomation = other.invoiceAutomation ?? false;
    model.invoiceAutomationProgress = other.invoiceAutomationProgress ?? '';

    model.isAcceptedByErir = other.isAcceptedByErir ?? false;
    model.erirId = other.erirId ?? '';

    model.creatives = other.creatives?.map(x => this.creativeAssembler.ToContractViewModel(x)) ?? [];

    model.createdAt = other.createdAt ?? '';

    return model;
  }

  public ToDto(other: ContractViewModel): ContractDto {
    return {
      id: other.id ?? undefined,
      parentContractId: (other.parentId && other.parentId !== '') ? other.parentId : undefined,
      type: other.typeCode,
      subject: other.subjectCode,
      serialNumber: other.serialNumber,
      dateOfConclusion: other.dateOfConclusion,
      contractorCounterpartyId: other.contractorCounterpartyId,
      clientCounterpartyId: other.clientCounterpartyId,
      amount: other.amount,
      isVatIncluded: other.isVatIncluded,
      creatives: []
    };
  }
}
