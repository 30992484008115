<div class="layout">
    <div class="layout-sidebar flex flex-column">

        <img src="https://marketing-tech.ru/mtwp/wp-content/uploads/2020/09/logo.jpg" class="main-logo mb-3" alt="image">


        <nav class="flex flex-column">
            <button pButton type="button" icon="fa-solid fa-link" label="Ссылки" class="w-full p-button-text" (click)="RouteToLinks()"></button>
            <button *ngIf="isAdmin" pButton type="button" icon="fa-solid fa-users" label="Пользователи" class="w-full p-button-text" (click)="RouteToUsers()"></button>
            <button style="opacity: 0.5;" *ngIf="isAdmin" pTooltip="В разработке" pButton type="button" icon="fa-solid fa-file" label="Страницы" class="w-full p-button-text"></button>

            
            <button pButton type="button" *ngIf="isAdmin" icon="fa-solid fa-people-roof" label="Контрагенты" class="w-full p-button-text" (click)="RouteToCounterparties()"></button>
            <button pButton type="button" *ngIf="isAdmin" icon="fa-solid fa-file-contract" label="Договоры" class="w-full p-button-text" (click)="RouteToContracts()"></button>
            <button pButton type="button" *ngIf="isAdmin" icon="fa-solid fa-rectangle-ad" label="Креативы" class="w-full p-button-text" (click)="RouteToCreatives()"></button>
            <button pButton type="button" *ngIf="isAdmin" icon="fa-solid fa-file-invoice-dollar" label="Акты" class="w-full p-button-text" (click)="RouteToInvoices()"></button>
            <button pButton type="button" *ngIf="isAdmin" icon="fa-solid fa-brands fa-wpforms" label="Лид-формы" class="w-full p-button-text" (click)="RouteToLeadForms()"></button>
        </nav>
        
        

        <section class="mt-auto">
            <hr class="my-3">

            <ng-container *ngIf="isAuthenticated; else isNotAuthenticated">
                <div class="mb-3 text-small text-bare">
                    Вы вошли как <strong>{{login}}</strong>
                </div>

                <button pButton type="button" icon="fa-solid fa-right-from-bracket" label="Выйти" class="w-full p-button-text" (click)="SignOut()"></button>
            </ng-container>
            <ng-template #isNotAuthenticated>
                <button pButton type="button" icon="fa-solid fa-right-to-bracket" label="Войти" class="w-full" (click)="SignInOpenDialog()" [loading]="isAutheticating" [disabled]="isAutheticating"></button>
            </ng-template>
        </section>
    </div>

    <div class="layout-content">
        <router-outlet></router-outlet>
    </div>
</div>