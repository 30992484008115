import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LazyLoadEvent } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { EntityRightsComponent } from 'src/app/dialogues/entity-rights/entity-rights.component';
import { LinkConfigureDialogueComponent } from 'src/app/dialogues/link-configure-dialogue/link-configure-dialogue.component';
import { AuthService } from 'src/app/services/auth.service';
import { LinkRepositoryService } from 'src/app/services/repositories/link-repository.service';
import { LinkVisitRepositoryService } from 'src/app/services/repositories/link-visit-repository.service';
import { LinkViewModel } from 'src/app/view-models/link/link-view-model';

@Component({
  selector: 'app-link-list',
  templateUrl: './link-list.component.html',
  styleUrls: ['./link-list.component.scss'],
  providers: [DialogService]
})
export class LinkListComponent implements OnInit {
  public isLoading: boolean = false;

  private _items: LinkViewModel[] = [];
  public get items(): LinkViewModel[] {
    return this._items;
  }

  public get totalAmount(): number {
    return this.linkAPI.totalAmount;
  }

  private _previousEvent: LazyLoadEvent | null = null;

  public isStatsLoading: boolean = false;

  public get isAdmin(): boolean {
    return this.authService.isAdmin;
  }

  constructor(private linkAPI: LinkRepositoryService,
    private linkVisitsAPI: LinkVisitRepositoryService,
    private dialogService: DialogService,
    private authService: AuthService,
    private router: Router) { }

  ngOnInit(): void {
    //this.LoadData(null);
  }

  public ShowRightsManagement(model: LinkViewModel): void {
    const ref = this.dialogService.open(EntityRightsComponent, {
      header: 'Права доступа',
      width: '70%',
      dismissableMask: true,
      data: {
        entityType: 'link',
        entityId: model.id
      }
    });

    ref.onClose.subscribe((data: any) => {
      if (data) {
        // data.id = model.id;
        // this.isLoading = true;
        // this.linkAPI.PatchLink(data).subscribe(x => {

        //   // Reload links
        //   this.LoadData(null);
        // }, err => {
        //   this.isLoading = false;
        // });
      }
    });
  }

  public GoToStatistics(model: LinkViewModel): void {
    this.router.navigate(['/link',model.id]);
  }

  private LoadViaAPI(limit: number, offset: number, filterHost: string): void {
    this.isLoading = true;
    // this.isStatsLoading = false;
    this._items = [];

    this.linkAPI.ListLinks(limit, offset, filterHost).subscribe(x => {
      this._items = x;
      this.isLoading = false;

      // this.isStatsLoading = true;
      // this.linkVisitsAPI.GetOverallLinksVisits(this.items.map(item => item.id ?? '')).subscribe(result => {
      //   this.isStatsLoading = false;
      //   result.forEach(stats => {
      //     const relatedLink = this.items.find(link => link.id === stats.linkId);

      //     if (relatedLink) {
      //       relatedLink.overallStats = stats;
      //     }
      //   })
      // }, err => {
      //   this.isStatsLoading = false;
      // });
    }, err => {
      this.isLoading = false;
    });
  }

  public OpenEditDialog(model: LinkViewModel): void {
    const ref = this.dialogService.open(LinkConfigureDialogueComponent, {
      header: 'Настройка ссылки',
      width: '70%',
      dismissableMask: true,
      data: {
        forbidNameEditing: !this.authService.isAdmin,
        isEditing: true,
        model: model
      }
    });

    ref.onClose.subscribe((data: LinkViewModel) => {
      if (data) {
        data.id = model.id;
        this.isLoading = true;
        this.linkAPI.PatchLink(data).subscribe(x => {

          // Reload links
          this.LoadData(null);
        }, err => {
          this.isLoading = false;
        });
      }
    });
  }

  public OpenCreateDialog(): void {
    const ref = this.dialogService.open(LinkConfigureDialogueComponent, {
      header: 'Создание новой ссылки',
      width: '70%',
      dismissableMask: true,
      data: {
        forbidNameEditing: false,
        isEditing: false
      }
    });

    ref.onClose.subscribe((data: LinkViewModel) => {
      if (data) {
        this.isLoading = true;
        this.linkAPI.CreateLink(data).subscribe(x => {

          // Reload links
          this.LoadData(null);
        }, err => {
          this.isLoading = false;
        });
      }
    });
  }

  public LoadData(event: LazyLoadEvent | null) {
    let current = event ?? this._previousEvent;

    if (event) {
      this._previousEvent = event;
    }
    //this.loading = true;
    console.log(event);

    this.LoadViaAPI(current?.rows ?? 10, current?.first ?? 0, current?.filters?.host?.value ?? '');
  }
}
