import { LinkVisitAggregation } from "./link-visit-aggregation-view-model";

export class LinkPageVisitAggregation {
    public path: string;

    public aggregations: LinkVisitAggregation[] = [];

    constructor(path: string, aggregations: LinkVisitAggregation[]) {
        this.path = path;
        this.aggregations = aggregations;
    }
}