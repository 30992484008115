/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ContractDto } from '../models/contract-dto';

@Injectable({
  providedIn: 'root',
})
export class ContractService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation listContracts
   */
  static readonly ListContractsPath = '/api/contract';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listContracts()` instead.
   *
   * This method doesn't expect any request body.
   */
  listContracts$Response(params?: {
    page?: number;
    pageSize?: number;
    filterTypes?: Array<string>;
    filterSubjects?: Array<string>;
    filterParentId?: string;
    filterDateOfConclusionBefore?: string;
    filterDateOfConclusionAfter?: string;
    filterClientId?: string;
    filterSyncState?: string;
    filterAcceptedByErir?: boolean;
  }): Observable<StrictHttpResponse<Array<ContractDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ContractService.ListContractsPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('filterTypes', params.filterTypes, {});
      rb.query('filterSubjects', params.filterSubjects, {});
      rb.query('filterParentId', params.filterParentId, {});
      rb.query('filterDateOfConclusionBefore', params.filterDateOfConclusionBefore, {});
      rb.query('filterDateOfConclusionAfter', params.filterDateOfConclusionAfter, {});
      rb.query('filterClientId', params.filterClientId, {});
      rb.query('filterSyncState', params.filterSyncState, {});
      rb.query('filterAcceptedByErir', params.filterAcceptedByErir, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ContractDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listContracts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listContracts(params?: {
    page?: number;
    pageSize?: number;
    filterTypes?: Array<string>;
    filterSubjects?: Array<string>;
    filterParentId?: string;
    filterDateOfConclusionBefore?: string;
    filterDateOfConclusionAfter?: string;
    filterClientId?: string;
    filterSyncState?: string;
    filterAcceptedByErir?: boolean;
  }): Observable<Array<ContractDto>> {

    return this.listContracts$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ContractDto>>) => r.body as Array<ContractDto>)
    );
  }

  /**
   * Path part for operation createContract
   */
  static readonly CreateContractPath = '/api/contract';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createContract()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createContract$Response(params?: {
    body?: ContractDto
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, ContractService.CreateContractPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createContract$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createContract(params?: {
    body?: ContractDto
  }): Observable<string> {

    return this.createContract$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getContractsByIds
   */
  static readonly GetContractsByIdsPath = '/api/contract/byids';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getContractsByIds()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContractsByIds$Response(params: {
    ids: Array<string>;
  }): Observable<StrictHttpResponse<Array<ContractDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ContractService.GetContractsByIdsPath, 'get');
    if (params) {
      rb.query('ids', params.ids, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ContractDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getContractsByIds$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContractsByIds(params: {
    ids: Array<string>;
  }): Observable<Array<ContractDto>> {

    return this.getContractsByIds$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ContractDto>>) => r.body as Array<ContractDto>)
    );
  }

  /**
   * Path part for operation addContractToSyncQueue
   */
  static readonly AddContractToSyncQueuePath = '/api/contract/{entityId}/sync';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addContractToSyncQueue()` instead.
   *
   * This method doesn't expect any request body.
   */
  addContractToSyncQueue$Response(params: {
    entityId: string;
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ContractService.AddContractToSyncQueuePath, 'post');
    if (params) {
      rb.path('entityId', params.entityId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addContractToSyncQueue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addContractToSyncQueue(params: {
    entityId: string;
  }): Observable<boolean> {

    return this.addContractToSyncQueue$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation updateContract
   */
  static readonly UpdateContractPath = '/api/contract/{entityId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateContract()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateContract$Response(params: {
    entityId: string;
    body?: ContractDto
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, ContractService.UpdateContractPath, 'put');
    if (params) {
      rb.path('entityId', params.entityId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateContract$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateContract(params: {
    entityId: string;
    body?: ContractDto
  }): Observable<boolean> {

    return this.updateContract$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
