import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from 'src/api/administration/services';
import { UserViewModel } from 'src/app/view-models/user/user-view-model';
import { UserAssemblerService } from '../assemblers/user-assembler.service';

@Injectable({
  providedIn: 'root'
})
export class UserRepositoryService {
  private _totalAmount: number=  0;
  public get totalAmount(): number {
    return this._totalAmount;
  }

  constructor(private api: UserService,private assembler: UserAssemblerService) { }

  public ListUsers(offset: number = 0, limit: number = 500): Observable<UserViewModel[]> {
    return this.api.listUsers({
      limit: limit,
      offset: offset
    }).pipe(
      map(result => {
        this._totalAmount = result.totalCount ?? 0;

        return result.items?.map(x => this.assembler.ToViewModel(x)) ?? [];
      })
    );
  }

  public CreateUser(model: UserViewModel): Observable<string> {
    return this.api.createUser({
      body: this.assembler.ToDto(model)
    });
  }

  public ResetPassword(userId: string, password: string): Observable<boolean> {
    return this.api.resetUserPassword({
      userId:userId,
      body: {
        password: password
      }
    });
  }
}
