import { Injectable } from '@angular/core';
import { PlatformDto, PlatformObjectDto, PlatformObjectFieldDto } from 'src/api/integrations/models';
import { IntegrationPlatform, IntegrationPlatformObject, IntegrationPlatformObjectField } from 'src/app/view-models/integrations/integration-platform';

@Injectable({
  providedIn: 'root'
})
export class IntegrationPlatformAssemblerService {

  public toViewModel(other: PlatformDto): IntegrationPlatform {
    const model = new IntegrationPlatform(other.codename ?? '', other.name ?? '');
    model.id = other.id ?? null;

    other.objects?.forEach(object => {
      model.addObject(this.toObjectViewModel(object));
    });

    return model;
  }

  public toObjectViewModel(other: PlatformObjectDto): IntegrationPlatformObject {
    const model = new IntegrationPlatformObject(other.codename ?? '', other.name ?? '');
    model.id = other.id ?? null;

    other.fields?.forEach(field => {
      model.fields.push(this.toObjectFieldViewModel(field));
    });

    return model;
  }

  public toObjectFieldViewModel(other: PlatformObjectFieldDto): IntegrationPlatformObjectField {
    const model = new IntegrationPlatformObject(other.codename ?? '', other.name ?? '');
    model.id = other.id ?? null;

    return model;
  }
}
