import { Injectable } from '@angular/core';
import { UserDto } from 'src/api/administration/models';
import { UserViewModel } from 'src/app/view-models/user/user-view-model';

@Injectable({
  providedIn: 'root'
})
export class UserAssemblerService {

  constructor() { }

  public ToViewModel(other: UserDto): UserViewModel {
    const model = new UserViewModel(other.login ?? '');
    model.id = other.id ?? null;
    model.accountType = other.type ?? '';
    model.accessCodeGrantedAt = other.accessCodeGrantedAt ?? null;
    return model;
  }

  public ToDto(other: UserViewModel): UserDto{
    return {
      id: other.id ?? undefined,
      login: other.login ?? undefined,
      password: other.password ?? undefined,
      type: other.accountType ?? undefined
    };
  }
}
