import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, of, zip } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { LeadFormApiService } from 'src/app/services/api/lead-form-api.service';
import { LeadForm, LeadFormField, LeadFormFieldType, leadFormFieldTypes, LeadFormOrigin, leadFormOrigins } from 'src/app/view-models/leads/lead-form';

@Component({
  selector: 'app-lead-form-dialog',
  templateUrl: './lead-form-dialog.component.html',
  styleUrls: ['./lead-form-dialog.component.scss']
})
export class LeadFormDialogComponent implements OnInit {
  public form: FormGroup;
  private _values: LeadForm | null = null;
  public isCreateMode: boolean;
  public isBusy: boolean = false;

  public fieldForms: FormGroup[] = [];

  public get fieldTypes(): LeadFormFieldType[] {
    return leadFormFieldTypes;
  }

  public get formOrigins(): LeadFormOrigin[] {
    return leadFormOrigins;
  }

  constructor(private _api: LeadFormApiService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig) {
    this._values = this.config.data ?? null;
    this.isCreateMode = !this._values;

    this.form = new FormGroup({
      codename: new FormControl<string>(this._values?.codename ?? '', [Validators.required]),
      name: new FormControl<string>(this._values?.name ?? '', [Validators.required]),
      origin: new FormControl<LeadFormOrigin>({value: this._values?.origin ?? leadFormOrigins[0], disabled: !this.isCreateMode}, [Validators.required])
    });

    this._values?.fields?.forEach(field => {
      this.addField(field);
    });
  }

  ngOnInit(): void {
  }

  public get isInvalid(): boolean {
    return !this.isMainDataValid || this.fieldForms.findIndex(x => !x.valid) >= 0;// || (!this.isCreateMode && !this.hasValidItems);
  }

  public get isMainDataValid(): boolean {
    return this.form.valid;
  }

  public Submit(): void {
    if (!this.form.valid) {
      return;
    }

    const model = this.GenerateViewModel();

    this.isBusy = true;

    if (this.isCreateMode) {
      this.CreateEntity(model);
    } else {
      this.UpdateEntity(model);
    }
  }

  public addField(field: LeadFormField | null = null): void {
    const fieldForm = new FormGroup({
      id: new FormControl<string | null>(field?.id ?? null),
      codename: new FormControl<string>(field?.codename ?? '', [Validators.required]),
      name: new FormControl<string>(field?.name ?? '', [Validators.required]),
      type: new FormControl<LeadFormFieldType>(field?.type ?? this.fieldTypes[0], [Validators.required]),
      order: new FormControl<number>(field?.order ?? 0),
      ignore: new FormControl<boolean>(field?.ignore ?? false),
      shouldRemove: new FormControl<boolean>(false)
    });

    this.fieldForms.push(fieldForm);
  }

  public removeField(fieldForm: FormGroup): void {
    this.updateRemovalState(fieldForm, true);
  }

  public restoreField(fieldForm: FormGroup): void {
    this.updateRemovalState(fieldForm, false);
  }

  public isRemoved(fieldForm: FormGroup): boolean {
    return fieldForm.controls['shouldRemove'].value;
  }

  private updateRemovalState(fieldForm: FormGroup, shouldRemove: boolean): void {
    fieldForm.controls['shouldRemove'].setValue(shouldRemove);

    for (let key in fieldForm.controls) {
      if (key === 'shouldRemove')
        continue;

      if (shouldRemove)
        fieldForm.controls[key].disable();
      else
        fieldForm.controls[key].enable();
    }
  }

  private GenerateViewModel(): LeadForm {
    const values = this.form.getRawValue();

    const model = new LeadForm(values.codename, values.name, values.origin);

    this.fieldForms.forEach(field => {
      const fieldValues = field.getRawValue();

      const fieldModel = new LeadFormField(fieldValues.codename, fieldValues.name, fieldValues.type);
      fieldModel.id = fieldValues.id;
      fieldModel.order = fieldValues.order;
      fieldModel.ignore = fieldValues.ignore;


      fieldModel.hasCreateMarker = fieldModel.id === null;
      fieldModel.hasUpdateMarker = field.touched && fieldModel.id !== null;
      fieldModel.hasRemoveMarker = fieldValues.shouldRemove === true;

      model.addField(fieldModel);
    });

    console.log(model);

    return model;
  }

  private UpdateEntity(model: LeadForm): void {
    if (!this._values || !this._values.id) {
      return;
    }

    const requests: Observable<any>[] = [];
    const formId = this._values.id;

    // Основная форма
    if (this.form.touched)
      requests.push(this._api.edit(formId, model));

    // Создать
    model.fields.filter(x => x.hasCreateMarker && !x.hasRemoveMarker).map(field => {
      requests.push(this._api.addField(formId, field));
    });

    // Редактировать
    model.fields.filter(x => x.hasUpdateMarker && !x.hasRemoveMarker).map(field => {
      if (field.id)
        requests.push(this._api.updateField(formId, field.id, field));
    });

    // Удалить
    const fieldsToRemove = model.fields.filter(x => !x.hasCreateMarker && x.hasRemoveMarker);

    if (fieldsToRemove.length > 0)
      requests.push(this._api.removeFields(formId, fieldsToRemove));

    console.log(requests);

    zip(...requests).subscribe(result => {
      this.ref.close(model);
    });
  }

  private CreateEntity(model: LeadForm): void {
    this._api.create(model).pipe(
      switchMap(id => {
        const fieldRequests: Observable<any>[] = model.fields.filter(x => x.hasCreateMarker && !x.hasRemoveMarker).map(field => {
          return this._api.addField(id, field)
        });

        if (fieldRequests.length > 0) {
          return zip(...fieldRequests);
        } else {
          return of(true);
        }
      })
    ).subscribe(result => {
      this.ref.close(model);
    });
  }
}
