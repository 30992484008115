export class EconomicClassification {
    public id: string | null = null;
    public parentId: string | null = null;
    public name: string = '';
    public value: string = '';

    constructor(name: string, value: string) {
        this.name = name;
        this.value = value;
    }
}