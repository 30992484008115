import { Component, Input, OnInit } from '@angular/core';
import { LeadField } from 'src/app/view-models/leads/lead';

@Component({
  selector: 'app-lead-field-values',
  templateUrl: './lead-field-values.component.html',
  styleUrls: ['./lead-field-values.component.scss']
})
export class LeadFieldValuesComponent implements OnInit {
  @Input() public field!: LeadField|null;

  constructor() { }

  ngOnInit(): void {
  }

}
