<h1>
    Контрагенты
</h1>

<div class="mt-5">
    <div class="grid">
        <div class="col-12 sm:col-10">
            <div class="flex align-items-center h-full">
                Здесь отображается список всех созданных контрагентов.
            </div>
        </div>

        <div class="col-12 sm:col-2">
            <button pButton type="button" label="Создать" class="w-full" (click)="CreateItemClicked()"></button>
        </div>
    </div>
</div>

<div class="mt-3">
    <app-counterparty-search-form (onQueryChanged)="QueryChanged($event)"></app-counterparty-search-form>
</div>

<div class="mt-3 fancy-card">
    <div class="fancy-card-content">
        <p-table [value]="items" [loading]="isLoading" dataKey="id" [showCurrentPageReport]="true"
                [lazy]="true" (onLazyLoad)="OnLazyLoad($event)"
                [paginator]="true" [rows]="10" [totalRecords]="itemsTotalCount" [rowsPerPageOptions]="[10,25,50]"
                currentPageReportTemplate="Показаны с {first} по {last} из {totalRecords} строчек" >
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width: 300px">Данные</th>
                            <th>ИНН</th>
                            <th>КПП</th>
                            <th>ОГРН/ОГРНИП</th>
                            <th style="width: 230px;">Операции</th>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-model>
                        <tr>
                            <td>
                                <div class="text-muted text-small">
                                    {{model.type.name}}
                                </div>
                                <div>{{model.name}}</div>

                                <div class="text-small text-muted">Создано {{model.createdAt |date}}</div>
                            </td>

                            <td>
                                <ng-container *ngIf="model.inn; else noINN">
                                    {{model.inn}}
                                </ng-container>
                                <ng-template #noINN>
                                    <span class="text-muted text-small">
                                        Нет данных
                                    </span>
                                </ng-template>
                            </td>

                            <td>
                                <ng-container *ngIf="model.kpp; else noKPP">
                                    {{model.kpp}}
                                </ng-container>
                                <ng-template #noKPP>
                                    <span class="text-muted text-small">
                                        Нет данных
                                    </span>
                                </ng-template>
                            </td>

                            <td>
                                <ng-container *ngIf="model.ogrn || model.ogrnip; else noOGRN">
                                    {{model.ogrn}}

                                    <ng-container *ngIf="model.ogrn && model.ogrnip">/</ng-container>

                                    {{model.ogrnip}}
                                </ng-container>
                                <ng-template #noOGRN>
                                    <span class="text-muted text-small">
                                        Нет данных
                                    </span>
                                </ng-template>
                            </td>

                            <td>
                                <div class="grid">
                                    <div class="col-12">
                                        <button pButton type="button" icon="pi pi-pencil" label="Правка" class="w-full p-button-sm p-button-text" (click)="UpdateItemClicked(model)"></button>
                                    </div>
                                    <div class="col-12">
                                        <app-sync-button 
                                        [erirId]="model.erirId"
                                        [isAcceptedByErir]="model.isAcceptedByErir" 
                                        [isSyncing]="model.isSyncing" 
                                        [isSynced]="model.isSynced" 
                                        (onClicked)="SyncClick(model)"></app-sync-button>
                                    </div>
                                </div>
                            </td>
                        </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<p-confirmDialog acceptIcon="pi pi-send" acceptLabel="Передать в ОРД" rejectLabel="Отмена" rejectButtonStyleClass="p-button-danger p-button-text" [style]="{width: '50vw'}"></p-confirmDialog>