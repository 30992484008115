import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-sync-button',
  templateUrl: './sync-button.component.html',
  styleUrls: ['./sync-button.component.scss']
})
export class SyncButtonComponent implements OnInit {
  @Input() public disabled: boolean = false;
  @Input() public isSynced: boolean = false;
  @Input() public isSyncing: boolean = false;

  @Input() public isAcceptedByErir: boolean = false;
  @Input() public erirId: string = '';

  @Output() public onClicked: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  public Click(): void {
    this.onClicked.emit();
  }
}
