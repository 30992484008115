<form [formGroup]="form" class="from-grid grid h-full">
    <div class="col-12 md:col-6 lg:col-4">
        <div class="field">
            <label for="type">Договор<span class="required">*</span></label>
            <p-dropdown styleClass="w-full" [options]="contracts" formControlName="contractId" optionLabel="serialNumber" optionValue="id" placeholder="Выберите договор"
                [filter]="true" [filterPlaceholder]="'Поиск по названию'">
                <ng-template let-model pTemplate="item">
                    <div class="text-muted text-small">
                        от {{model.dateOfConclusion}}
                    </div>
                    <div>{{model.serialNumber}}</div>
                </ng-template>
            </p-dropdown>
        </div>
    </div>

    <div class="col-12 md:col-6 lg:col-4">
        <div class="field">
            <label for="serialNumber">Номер акта<span class="required">*</span></label>
            <input id="serialNumber" formControlName="serialNumber" type="text" class="w-full" placeholder="Введите номер акта" pInputText />
        </div>
    </div>

    <div class="col-12 md:col-6 lg:col-4">
        <div class="field">
            <label for="serialNumber">Сумма акта <span class="text-muted">(автоматически)</span></label>
            <input id="serialNumber" formControlName="amount" type="text" class="w-full" placeholder="Cумма акта в рублях" pInputText />
        </div>
    </div>

    <div class="col-12 md:col-6 lg:col-4">
        <div class="field">
            <label for="dateOfConclusion">Дата акта<span class="required">*</span></label>

            <p-calendar styleClass="w-full" formControlName="dateOfConclusion" dataType="string" dateFormat="yy-mm-dd" placeholder="Выберите дату заключения акта"></p-calendar>

            <div class="text-small text-muted text-center">
                Должно быть не больше "текущей даты";
            </div>
        </div>
    </div>


    <div class="col-12 md:col-6 lg:col-4">
        <div class="field">
            <label for="dateOfConclusion">Дата начала отчетного периода<span class="required">*</span></label>

            <p-calendar styleClass="w-full" formControlName="dateStart" dataType="string" dateFormat="yy-mm-dd" placeholder="Выберите дату начала отчетного периода"></p-calendar>
            
            <div class="text-small text-muted text-center">
                Должно быть не больше "текущей даты";
            </div>
        </div>
    </div>

    <div class="col-12 md:col-6 lg:col-4">
        <div class="field">
            <label for="dateOfConclusion">Дата окончания отчетного периода<span class="required">*</span></label>

            <p-calendar styleClass="w-full" formControlName="dateEnd" dataType="string" dateFormat="yy-mm-dd" placeholder="Выберите дату окончания отчетного периода"></p-calendar>

            <div class="text-small text-muted text-center">
                Должно быть: 1) не больше "даты акта"; 2) не больше "текущей даты"; 3) не больше "даты акта"
            </div>
        </div>
    </div>

    <div class="col-12">
        <div class="field">
            <p-table *ngIf="!isCreateMode; else createMode" [value]="items">
                <ng-template pTemplate="caption">
                    <div class="flex align-items-center justify-content-between">
                        <div>
                            <h4>Строки акта</h4>
                            <div class="text-muted">
                                Отображены креативы, которые связаны с выбранным договором!
                            </div>
                        </div>
                        <p-button styleClass="p-button-text" (click)="AddItem()" label="Добавить строку" icon="pi pi-plus"></p-button>
                    </div>
                </ng-template>

                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 350px">
                            <i class="pi pi-question-circle" pTooltip="Отображает для какого именно креатива приведена статистика"></i>
                            <span class="ml-2">Креатив</span>
                        </th>
                        <th style="width: 350px">
                            <i class="pi pi-question-circle" pTooltip="Отображает на какой платформе указанный креатив имеет приведенную статистику"></i>
                            <span class="ml-2">Платформа</span>
                        </th>
                        <th>Просмотры</th>
                        <th>Сумма</th>
                        <th style="width: 250px"></th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-model>
                    <tr>
                        <td class="invoice-item-key">
                            <p-dropdown [disabled]="model.isRemoved || (model.creativeAggregationId && !isCreateMode)" styleClass="w-full" [(ngModel)]="model.creativeId" [ngModelOptions]="{standalone: true}" [options]="creatives" optionLabel="name" optionValue="id" optionDisabled="used" placeholder="Выберите креатив"
                                [filter]="true" [filterPlaceholder]="'Поиск по названию'">
                                <ng-template let-model pTemplate="item">
                                    <div [pTooltip]="model.description">
                                        <div class="text-muted text-small">
                                            {{model.form.name}} / {{model.campaignType.name}}
                                        </div>
                                        <div>{{model.name}}</div>
                                    </div>
                                </ng-template>
                            </p-dropdown>

                            <p-message *ngIf="!model.isRemoved && !model.creativeId" severity="warn" text="Обязательное" styleClass="w-full text-small mt-2"></p-message>  
                        </td>

                        <td class="invoice-item-key">
                            <p-dropdown [disabled]="model.isRemoved || (model.creativeAggregationId && !isCreateMode)" styleClass="w-full" [(ngModel)]="model.platformId" [ngModelOptions]="{standalone: true}" [options]="platforms" optionLabel="name" optionValue="id" placeholder="Выберите платформу"
                                [filter]="true" [filterPlaceholder]="'Поиск по названию'">
                            </p-dropdown>

                            <p-message *ngIf="!model.isRemoved && !model.platformId" severity="warn" text="Обязательное" styleClass="w-full text-small mt-2"></p-message>  
                        </td>

                        <td>
                            <p-inputNumber [disabled]="model.isRemoved" [(ngModel)]="model.impressions" [ngModelOptions]="{standalone: true}" styleClass="w-full" placeholder="Кол-во просмотров"></p-inputNumber>
                        </td>

                        <td>
                            <p-inputNumber [disabled]="model.isRemoved" [(ngModel)]="model.amount" [ngModelOptions]="{standalone: true}" styleClass="w-full" placeholder="Сумма" (ngModelChange)="RecalculateAmount()"></p-inputNumber>
                        </td>

                        <td>
                            <ng-container *ngIf="model.isRemoved; else notRemoved">
                                <p-button styleClass="p-button-text w-full" (click)="RestoreItem(model)" icon="pi pi-times" label="Вернуть"></p-button>
                            </ng-container>
                            <ng-template #notRemoved>
                                <p-button styleClass="p-button-danger p-button-text w-full" (click)="RemoveItem(model)" icon="pi pi-times" label="Убрать"></p-button>
                            </ng-template>
                        </td>
                    </tr>
                </ng-template>
            </p-table>

            <ng-template #createMode>
                <h4>Строки акта</h4>
                <div class="text-muted">
                    Акт можно будет детализировать после создания.
                </div>
            </ng-template>
        </div>
    </div>

    <div class="mt-auto col-12">
        <div class="field flex">
            <div class="mr-auto">
                <p-message *ngIf="!isCreateMode && !hasValidItems" severity="warn" text="В акте должна быть хотя бы одна строчка!"></p-message>  
            </div>
            <button pButton type="button" [disabled]="isInvalid" [label]="isCreateMode ? 'Создать' : 'Сохранить'" [loading]="isBusy" (click)="Submit()"></button>
        </div>
    </div>

    <div *ngIf="isInvalid" class="col-12">
        form.valid:{{form.valid}}
        <br>
        Errors: {{form.errors|json}}
        <br>
        HasItems: {{hasValidItems}}
        <br>
        isCreateMode: {{isCreateMode}}
        <br>
        Items:{{items|json}}
    </div>
</form>