import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { EconomicClassificationApiService } from 'src/app/services/api/economic-classification-api.service';
import { CreativeCampaignType, CreativeCampaignTypes, CreativeForm, CreativeForms } from 'src/app/view-models/creative/creative-view-model';
import { EconomicClassification } from 'src/app/view-models/economic-classification/economic-classification';

@Component({
  selector: 'app-creative-search-form',
  templateUrl: './creative-search-form.component.html',
  styleUrls: ['./creative-search-form.component.scss']
})
export class CreativeSearchFormComponent implements OnInit {
  public name: string = '';
  public selectedForms: string[] = [];
  public selectedCampaignTypes: string[] = [];
  public selectedOkveds: string[] = [];

  public get forms(): CreativeForm[] {
    return CreativeForms;
  }

  public get campaignTypes(): CreativeCampaignType[] {
    return CreativeCampaignTypes;
  }

  private _okveds: EconomicClassification[] = [];

  public get okveds(): EconomicClassification[] {
    return this._okveds;
  }

  @Output() public onQueryChanged: EventEmitter<CreativeSearchQuery> = new EventEmitter<CreativeSearchQuery>();


  constructor(private economicClassificationApi: EconomicClassificationApiService) {

    this.economicClassificationApi.list(1, 500).subscribe(result => {
      this._okveds = [...result];
    });
  }

  ngOnInit(): void {
  }


  public reset(): void {
    this.name = '';
    this.selectedForms = [];
    this.selectedCampaignTypes = [];
    this.selectedOkveds = [];

    this.send();
  }

  public send(): void {
    const query = new CreativeSearchQuery();

    query.name = this.name;
    query.forms = this.selectedForms;
    query.campaignTypes = this.selectedCampaignTypes;
    query.okveds = this.selectedOkveds;

    this.onQueryChanged.next(query);
  }
}

export class CreativeSearchQuery {
  public name: string = '';
  public forms: string[] = [];
  public campaignTypes: string[] = [];
  public okveds: string[] = [];
}