export class LeadForm {
    public id: string | null = null;
    public codename: string;
    public name: string;
    public origin: LeadFormOrigin;

    private _fields: LeadFormField[] = [];
    public get fields(): LeadFormField[] {
        return this._fields;
    }

    public get visibleFields(): LeadFormField[] {
        return this._fields.filter(x => !x.ignore);
    }

    public get originUrl(): string {
        switch(this.origin.codename) {
            case wpcf7Origin.codename:
                return `https://marketing-tech.ru/mtwp/wp-admin/admin.php?page=wpcf7&post=${this.codename}&action=edit#/`;
            default: return '#';
        }
    }

    
    public createdAt: string|null = null;
    public lastModifiedAt: string|null = null;

    public newLeads: number = 0;

    constructor(codename: string, name: string, origin: LeadFormOrigin) {
        this.codename = codename;
        this.name = name;
        this.origin = origin;
    }

    public addField(field: LeadFormField): void {
        if (this.fields.length > 0) {
            const lastItem = this.fields[this.fields.length - 1];

            field.order = lastItem.order + 1;
        }

        this._fields.push(field);
    }
}

export class LeadFormField {
    public id: string | null = null;
    public get mapId(): string {
        return this.id ?? '';
    }

    public codename: string;
    public name: string;
    public type: LeadFormFieldType;

    public order: number = 0;
    public ignore: boolean = false;

    public createdAt: string|null = null;
    public lastModifiedAt: string|null = null;

    public hasCreateMarker: boolean = false;
    public hasRemoveMarker: boolean = false;
    public hasUpdateMarker: boolean = false;

    constructor(codename: string, name: string, type: LeadFormFieldType) {
        this.codename = codename;
        this.name = name;
        this.type = type;
    }
}

export class LeadFormOrigin {
    public codename: string;
    public name: string;

    constructor(codename: string, name: string) {
        this.codename = codename;
        this.name = name;
    }
}

export class LeadFormFieldType {
    public codename: string;
    public name: string;

    constructor(codename: string, name: string) {
        this.codename = codename;
        this.name = name;
    }
}

export const wpcf7Origin: LeadFormOrigin =new LeadFormOrigin('contact-form-wordpress-plugin', 'WordPress\'s ContactForm 7');

export const leadFormOrigins: LeadFormOrigin[] = [
    wpcf7Origin
];

export const otherLeadFormFieldType = new LeadFormFieldType('other', 'Прочее');

export const leadFormFieldTypes: LeadFormFieldType[] = [
    otherLeadFormFieldType,
    new LeadFormFieldType('name', 'Имя'),
    new LeadFormFieldType('phone', 'Номер телефона'),
    new LeadFormFieldType('email', 'Адрес E-mail'),
    new LeadFormFieldType('telegram', 'Telegram'),
    new LeadFormFieldType('vk', 'ВКонтакте'),
    new LeadFormFieldType('utm', 'UTM')
];