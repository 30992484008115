import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Params } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { debounceTime, delay, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { LeadFormDialogComponent } from 'src/app/dialogues/lead-form-dialog/lead-form-dialog.component';
import { LeadApiService } from 'src/app/services/api/lead-api.service';
import { LeadFormApiService } from 'src/app/services/api/lead-form-api.service';
import { LeadForm } from 'src/app/view-models/leads/lead-form';

@Component({
  selector: 'app-lead-form-archive',
  templateUrl: './lead-form-archive.component.html',
  styleUrls: ['./lead-form-archive.component.scss']
})
export class LeadFormArchiveComponent implements OnInit {
  public isLoadingLeadForms: boolean = false;
  public isLoadingLeadFormsStats: boolean = false;
  public leadForms: LeadForm[] = [];

  private _page: number = 1;
  private _pageSize: number = 50;

  constructor(private _leadformApi: LeadFormApiService,
    private _leadApi: LeadApiService,
    private route: ActivatedRoute,
    private dialogService: DialogService
  ) {
    this.route.queryParamMap.pipe(
      distinctUntilChanged(),
      debounceTime(500),
      switchMap(params => this.loadLeadForms(params))
    ).subscribe();
  }

  ngOnInit(): void {
  }

  private loadLeadForms(params: ParamMap) {
    this._page = params.has('page') ? parseInt(params.get('page') ?? '1') : 1;
    this._pageSize = params.has('pageSize') ? parseInt(params.get('pageSize') ?? '10') : 10;

    return this.fetchLeadFormsPage();
  }

  private fetchLeadFormsPage() {
    this.isLoadingLeadForms = true;
    this.isLoadingLeadFormsStats = true;
    this.leadForms = [];

    return this._leadformApi.list(this._page, this._pageSize).pipe(
      tap(x => {
        this.leadForms = x;
        this.isLoadingLeadForms = false;
      }),
      switchMap(response => {
        const filter: { [key: string]: number; } = {};

        response.forEach(leadform => {
          if (leadform.id) {
            const leadLastVisit = localStorage.getItem(`leadform_${leadform.id}`);

            if (leadLastVisit) {
              filter[leadform.id] = parseInt(leadLastVisit);
            } else {
              filter[leadform.id] = 0;
            }
          }
        })

        console.log('filter', filter);

        // ToDo Запросить лиды, пришедшие с моментов последних посещений
        return this._leadApi.getNewLeadCounters(filter).pipe(
          delay(1000),
          tap(response => {
            this.isLoadingLeadFormsStats = false;
            response?.forEach(item => {
              const leadform = this.leadForms.find(x => x.id === item[0]);

              if (leadform) {
                leadform.newLeads = item[1];
              }
            });
          })
        );
      }));
  }

  public openEditDialog(leadForm: LeadForm): void {
    if (!leadForm || !leadForm.id) {
      return;
    }

    const ref = this.dialogService.open(LeadFormDialogComponent, {
      header: 'Редактировать лид-форму',
      width: '90%',
      data: leadForm
    });

    ref.onClose.pipe(
      switchMap(x => this.fetchLeadFormsPage())
    ).subscribe();
  }

  public createLeadFormClicked(): void {
    const ref = this.dialogService.open(LeadFormDialogComponent, {
      header: 'Создать лид-форму',
      width: '90%'
    });

    ref.onClose.pipe(
      switchMap(x => this.fetchLeadFormsPage())
    ).subscribe();
  }
}
