import { Injectable } from '@angular/core';
import { IntegrationService } from 'src/api/integrations/services';
import { IntegrationAssemblerService } from '../assemblers/integration-assembler.service';
import { Observable } from 'rxjs';
import { Integration, IntegrationMaping } from 'src/app/view-models/integrations/integration';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IntegrationsService {

  constructor(private _api: IntegrationService, private _assembler: IntegrationAssemblerService) { }

  public list(filter: IntegrationServiceFilter | null = null): Observable<Integration[]> {
    return this._api.listIntegrations({
      SourceObjectId: filter?.sourceObjectId ?? undefined,
      SourceEntityId: filter?.sourceEntityId ?? undefined
    }).pipe(
      map(response => {
        return response.map(x => this._assembler.toViewModel(x));
      })
    );
  }

  public getById(id: string): Observable<Integration | null> {
    return this._api.getIntegrationById({
      Id: id
    }).pipe(
      map(response => {
        return response ? this._assembler.toViewModel(response) : null;
      })
    );
  }

  public create(integration: Integration): Observable<string> {
    return this._api.createIntegration({
      body: this._assembler.toDto(integration)
    });
  }

  public update(id: string, integration: Integration): Observable<void> {
    return this._api.updateIntegration({
      Id: id,
      body: this._assembler.toDto(integration)
    });
  }

  public addMapping(id: string, mapping: IntegrationMaping): Observable<string> {
    return this._api.createIntegrationMapping({
      Id: id,
      body: this._assembler.toMappingDto(mapping)
    });
  }

  public updateMapping(id: string, mappingId: string, mapping: IntegrationMaping): Observable<void> {
    return this._api.updateIntegrationMapping({
      Id: id,
      MappingId: mappingId,
      body: this._assembler.toMappingDto(mapping)
    });
  }

  public removeMapping(id: string, mappingId: string): Observable<void> {
    return this._api.removeIntegrationMapping({
      Id: id,
      MappingId: mappingId
    });
  }
}

export interface IntegrationServiceFilter {
  sourceObjectId: string | null;
  sourceEntityId: string | null;
}