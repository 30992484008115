<form [formGroup]="form" class="from-grid grid">
    <div class="col-12">
        <div class="field">
            <label for="okveds">ОКВЭД<span class="required">*</span></label>

            <p-multiSelect styleClass="w-full" [options]="economicClassifications"
                [(ngModel)]="selectedEconomicClassifications" [ngModelOptions]="{standalone: true}" optionLabel="value"
                display="chip" appendTo="body" placeholder="Выберите ОКВЭД">
                <ng-template let-option pTemplate="item">
                    <div class="gap-2">
                        <span class="text-muted mr-1">{{option.value}}</span>
                        <span class="">{{ option.name }}</span>
                    </div>
                </ng-template>
            </p-multiSelect>
        </div>
    </div>

    <div class="col-12 sm:col-6">
        <div class="field">
            <label for="fromCode">Форма<span class="required">*</span></label>
            <p-dropdown styleClass="w-full" [options]="creativeForms" formControlName="formCode" optionLabel="name"
                optionValue="code" placeholder="Выберите форму креатива" [filter]="true"
                [filterPlaceholder]="'Поиск по названию'">
            </p-dropdown>
        </div>
    </div>

    <div class="col-12 sm:col-6">
        <div class="field">
            <label for="campaignType">Тип кампании<span class="required">*</span></label>
            <p-dropdown styleClass="w-full" [options]="creativeCampaignTypes" formControlName="campaignType"
                optionLabel="name" optionValue="code" placeholder="Выберите тип рекламной кампании" [filter]="true"
                [filterPlaceholder]="'Поиск по названию'">
            </p-dropdown>
        </div>
    </div>

    <div class="col-12 sm:col-6">
        <div class="field">
            <label for="name">Название<span class="required">*</span></label>
            <input id="name" formControlName="name" type="text" class="w-full" placeholder="Введите название креатива"
                pInputText />

            <div class="text-small text-muted text-center">Название не передаётся в ОРД</div>
        </div>
    </div>

    <div class="col-12 sm:col-6">
        <div class="field">
            <label for="adUrl">Рекламная ссылка<span class="required">*</span></label>
            <input id="adUrl" formControlName="adUrl" type="text" class="w-full" placeholder="Введите рекламную ссылку"
                pInputText />
        </div>
    </div>


    <div *ngIf="!isTextForm" class="col-12 sm:col-6">
        <div class="field">
            <label for="previewLink">Ссылка на превью<span class="required">*</span></label>

            <div class="p-inputgroup">
                <input id="previewLink" formControlName="previewLink" type="text" class="w-full"
                placeholder="Вставьте ссылку или загрузите файл" pInputText />

                <button type="button" pButton icon="pi pi-file" styleClass="p-button-warn" (click)="openUploadImageDialog()"></button>
            </div>
            
        </div>
    </div>

    <div [ngClass]="{
        'col-12': true,
        'sm:col-6': !isTextForm
    }">
        <div class="field">
            <label for="description">Описание<span *ngIf="isTextForm" class="required">*</span></label>
            <input id="description" formControlName="description" type="text" class="w-full"
                placeholder="Введите описание внешего вида креатива" pInputText />

            <div class="text-small text-muted text-center">
                <ng-container *ngIf="isTextForm; else localDescription">
                    <strong>Внимание! Описание будет передано в ОРД!!!</strong>
                    Оно должно описывать внешний вид креатива или содержать весь его текст.
                </ng-container>
                <ng-template #localDescription>
                    Описание не передаётся в ОРД
                </ng-template>
            </div>
        </div>
    </div>

    <div class="col-12">
        <div class="field flex">
            <button pButton type="button" [disabled]="isInvalid" [label]="isCreateMode ? 'Создать' : 'Сохранить'"
                class="ml-auto" [loading]="isBusy" (click)="Submit()"></button>
        </div>
    </div>
</form>