<form [formGroup]="form" class="from-grid grid">
    <div class="col-12 sm:col-6">
        <div class="field">
            <label for="type">Тип<span class="required">*</span></label>
            <p-dropdown styleClass="w-full" [options]="counterpartyTypes" formControlName="type" optionLabel="name" optionValue="code" placeholder="Тип контрагента">
                
            </p-dropdown>
        </div>
    </div>

    <div class="col-12 sm:col-6">
        <div class="field">
            <label for="name">Название<span class="required">*</span></label>
            <input id="name" formControlName="name" type="text" class="w-full" placeholder="Название" pInputText />
        </div>
    </div>

    <div class="col-12 sm:col-6">
        <div class="field">
            <label for="name">ИНН<span class="required">*</span></label>
            <input id="inn" formControlName="inn" type="text" class="w-full" placeholder="ИНН" pInputText />
        </div>
    </div>

    <ng-container *ngIf="useOgrnipInsteadOfOgrn; else useOgrn">
        <div class="col-12 sm:col-6">
            <div class="field">
                <label for="name">ОГРНИП</label>
                <input id="ogrnip" formControlName="ogrnip" type="text" class="w-full" placeholder="ОГРНИП" pInputText />
            </div>
        </div>
    </ng-container>
    <ng-template #useOgrn>
        <div class="col-12 sm:col-6">
            <div class="field">
                <label for="name">ОГРН</label>
                <input id="ogrn" formControlName="ogrn" type="text" class="w-full" placeholder="ОГРН" pInputText />
            </div>
        </div>
    </ng-template>

    <div class="col-12 sm:col-6">
        <div class="field">
            <label for="kpp">КПП</label>
            <input id="kpp" formControlName="kpp" type="text" class="w-full" placeholder="КПП" pInputText />
        </div>
    </div>
    
    <div class="col-12">
        <div class="field flex">
            <button pButton type="button" [disabled]="isInvalid" [label]="isCreateMode ? 'Создать' : 'Сохранить'" class="ml-auto" [loading]="isBusy" (click)="Submit()"></button>
        </div>
    </div>
</form>