import { Injectable } from '@angular/core';
import { StatsAssemblerService } from '../assemblers/stats-assembler.service';
import { StatsService } from 'src/api/legal/services';
import { StatsViewModel } from 'src/app/view-models/stats/stats';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StatsApiService {

  constructor(private api: StatsService, private assembler: StatsAssemblerService) { }

  public GetStatistics(entityType: string, entityId: string, dateFrom: string, dateTo: string): Observable<StatsViewModel[]> {
    return this.api.listStats({
      entityType: entityType,
      entityId: entityId,
      from: dateFrom,
      to: dateTo
    }).pipe(
      map(stats => {
        return stats.map(x => this.assembler.ToViewModel(x));
      })
    );
  }

  public ToDateToString(someDate: Date): string {
    return `${someDate.getFullYear()}-${this.pad(someDate.getMonth() + 1)}-${this.pad(someDate.getDate())}`;
  }

  private pad(num: number): string {
    return ("00" + num).slice(-2);
  }
}
