<h1>
    Лид-формы
</h1>

<div class="mt-5">
    <div class="grid">
        <div class="col-12 sm:col-10">
            <div class="flex align-items-center h-full">
                Здесь отображается список всех обрабатываемых лид-форм.
            </div>
        </div>

        <div class="col-12 sm:col-2">
            <button pButton type="button" label="Создать" class="w-full" (click)="createLeadFormClicked()"></button>
        </div>
    </div>
</div>

<div class="mt-3 fancy-card">
    <p-table [value]="leadForms" [loading]="isLoadingLeadForms" styleClass="w-full">
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 200px;">Кодовое название</th>
                <th>Название</th>
                <th>Лиды</th>
                <!-- <th>Источник</th> -->
                <!-- <th>Полей</th> -->
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-leadform>
            <tr>
                <td>
                    <a class="fa-solid fa-up-right-from-square" [attr.href]="leadform.originUrl" target="_blank"
                        pTooltip="Открыть форму в админке МТ"></a>
                    <span class="ml-2">{{ leadform.codename }}</span>
                </td>

                <td class="flex align-items-center gap-2">
                    <button pButton class="p-button-secondary p-button-text p-button-sm"
                        icon="fa-solid fa-pen-to-square" (click)="openEditDialog(leadform)"
                        pTooltip="Редактировать лид-форму"></button>

                    <span>
                        {{ leadform.name }}
                    </span>
                </td>

                <td>
                    <button pButton [routerLink]="[leadform.id]" [loading]="isLoadingLeadFormsStats" class="p-button-secondary p-button-text p-button-sm"
                        title="Открыть страницу лид-формы">
                        <span>Смотреть лиды</span>
                        <p-badge *ngIf="leadform.newLeads > 0" severity="danger" [value]="leadform.newLeads"></p-badge>
                    </button>
                </td>
                <!-- <td>{{ leadform.origin.name }}</td> -->
                <!-- <td>{{ leadform.fields.length | number }}</td> -->
            </tr>
        </ng-template>
    </p-table>
</div>