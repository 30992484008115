import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { CreativeSearchQuery } from 'src/app/components/search-forms/creative-search-form/creative-search-form.component';
import { CreativeDialogueComponent } from 'src/app/dialogues/creative-dialogue/creative-dialogue.component';
import { CreativeApiService } from 'src/app/services/api/creative-api.service';
import { EconomicClassificationApiService } from 'src/app/services/api/economic-classification-api.service';
import { CreativeCampaignTypes, CreativeForm, CreativeForms, CreativeViewModel } from 'src/app/view-models/creative/creative-view-model';
import { EconomicClassification } from 'src/app/view-models/economic-classification/economic-classification';

@Component({
  selector: 'app-creative-list',
  templateUrl: './creative-list.component.html',
  styleUrls: ['./creative-list.component.scss']
})
export class CreativeListComponent implements OnInit {

  public get isLoading(): boolean {
    return this.api.isLoading;
  }

  private _items:CreativeViewModel[] = [];

  public get items(): CreativeViewModel[] {
    return this._items;
  }

  public get itemsTotalCount(): number {
    return this.api.itemsTotalCount;
  }

  public pageIndex: number = 1;
  public pageSize: number = 10;
  private _previousEvent: LazyLoadEvent | null = null;

  private _query: CreativeSearchQuery | null = null;

  private _economics: EconomicClassification[] = [];

  constructor(private api: CreativeApiService, private dialogService: DialogService,
    private econpmicClassificationApi: EconomicClassificationApiService) {

      this.econpmicClassificationApi.list(1, 500).subscribe(result => {
        this._economics = result;

        this.EnrichItems();
      });

    }

  ngOnInit(): void {

  }

  public QueryChanged(query: CreativeSearchQuery): void {
    this._query = query;

    this.LoadCurrentPage();
  }

  public onPreviewLoaded(image:HTMLImageElement, item: CreativeViewModel): void {
    const ratio = image.naturalWidth / image.naturalHeight;

    console.log(image, image.naturalWidth, image.naturalHeight, image.naturalWidth / image.naturalHeight);
    
    item.userVerticalLayout = ratio >= 1.5;
  }

  public OnLazyLoad(event: LazyLoadEvent | null) {
    const current = event ?? this._previousEvent;

    if (event) {
      this._previousEvent = event;
    }
    
    this.pageSize = current?.rows ?? 10;
    this.pageIndex = (current?.first) ? (current.first/this.pageSize)+1 : 1;

    this.LoadCurrentPage();
  }

  public CreateItemClicked(): void {
    const ref = this.dialogService.open(CreativeDialogueComponent, {
      header: 'Создать креатив',
      width: '60%'
    });

    ref.onClose.subscribe((data: CreativeViewModel) => {
      this.LoadCurrentPage();
    });
  }

  public UpdateItemClicked(item: CreativeViewModel): void {
    const ref = this.dialogService.open(CreativeDialogueComponent, {
      header: 'Обновить креатив',
      width: '60%',
      data: item
    });

    ref.onClose.subscribe((data: CreativeViewModel) => {
      this.LoadCurrentPage();
    });
  }

  public SyncClick(model: CreativeViewModel): void {
    
  }

  private LoadCurrentPage(): void {
    this.api.Load(this.pageIndex, this.pageSize,
      this._query?.name,
      this._query?.forms,
      this._query?.campaignTypes,
      this._query?.okveds).subscribe(x => {

        this._items = x;

      this._items.forEach(item => {
        item.form = CreativeForms.find(x => x.code === item.formCode) ?? null;
        item.campaignType = CreativeCampaignTypes.find(x => x.code === item.campaignTypeCode) ?? null;
      });

      this.EnrichItems();
    });
  }

  private EnrichItems(): void {
    this._items.forEach(item => {
      item.economicClassifications.forEach(economics => {
        economics.economicClassification = this._economics.find(x => x.id === economics.economicClassificationId) ?? null;
      });
    });
  }
}
