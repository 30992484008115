import { Component, Input, OnInit } from '@angular/core';
import { ContractViewModel } from 'src/app/view-models/contract/contract-view-model';

@Component({
  selector: 'app-contract-snippet',
  templateUrl: './contract-snippet.component.html',
  styleUrls: ['./contract-snippet.component.scss']
})
export class ContractSnippetComponent implements OnInit {
  @Input() public contract: ContractViewModel | null = null;
  @Input() public showAmount: boolean = true;
  
  constructor() { }

  ngOnInit(): void {
  }

}
