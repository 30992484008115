/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { RightsDto } from '../models/rights-dto';

@Injectable({
  providedIn: 'root',
})
export class RightsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation listEntityRights
   */
  static readonly ListEntityRightsPath = '/api/rights/listByEntity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listEntityRights()` instead.
   *
   * This method doesn't expect any request body.
   */
  listEntityRights$Response(params: {
    entityType: string;
    entityId: string;
  }): Observable<StrictHttpResponse<Array<RightsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, RightsService.ListEntityRightsPath, 'get');
    if (params) {
      rb.query('entityType', params.entityType, {});
      rb.query('entityId', params.entityId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RightsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listEntityRights$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listEntityRights(params: {
    entityType: string;
    entityId: string;
  }): Observable<Array<RightsDto>> {

    return this.listEntityRights$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RightsDto>>) => r.body as Array<RightsDto>)
    );
  }

  /**
   * Path part for operation getMyRightsByEntity
   */
  static readonly GetMyRightsByEntityPath = '/api/rights/getMyRightsByEntity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMyRightsByEntity()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMyRightsByEntity$Response(params: {
    entityType: string;
    entityId: string;
  }): Observable<StrictHttpResponse<RightsDto>> {

    const rb = new RequestBuilder(this.rootUrl, RightsService.GetMyRightsByEntityPath, 'get');
    if (params) {
      rb.query('entityType', params.entityType, {});
      rb.query('entityId', params.entityId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RightsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMyRightsByEntity$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMyRightsByEntity(params: {
    entityType: string;
    entityId: string;
  }): Observable<RightsDto> {

    return this.getMyRightsByEntity$Response(params).pipe(
      map((r: StrictHttpResponse<RightsDto>) => r.body as RightsDto)
    );
  }

  /**
   * Path part for operation getUserRightsByEntity
   */
  static readonly GetUserRightsByEntityPath = '/api/rights/getUserRightsByEntity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserRightsByEntity()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserRightsByEntity$Response(params: {
    userId: string;
    entityType: string;
    entityId: string;
  }): Observable<StrictHttpResponse<RightsDto>> {

    const rb = new RequestBuilder(this.rootUrl, RightsService.GetUserRightsByEntityPath, 'get');
    if (params) {
      rb.query('userId', params.userId, {});
      rb.query('entityType', params.entityType, {});
      rb.query('entityId', params.entityId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RightsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserRightsByEntity$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserRightsByEntity(params: {
    userId: string;
    entityType: string;
    entityId: string;
  }): Observable<RightsDto> {

    return this.getUserRightsByEntity$Response(params).pipe(
      map((r: StrictHttpResponse<RightsDto>) => r.body as RightsDto)
    );
  }

  /**
   * Path part for operation listUserRights
   */
  static readonly ListUserRightsPath = '/api/rights/listByUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listUserRights()` instead.
   *
   * This method doesn't expect any request body.
   */
  listUserRights$Response(params: {
    userId: string;
  }): Observable<StrictHttpResponse<Array<RightsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, RightsService.ListUserRightsPath, 'get');
    if (params) {
      rb.query('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RightsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listUserRights$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listUserRights(params: {
    userId: string;
  }): Observable<Array<RightsDto>> {

    return this.listUserRights$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RightsDto>>) => r.body as Array<RightsDto>)
    );
  }

  /**
   * Path part for operation grantRights
   */
  static readonly GrantRightsPath = '/api/rights';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `grantRights()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  grantRights$Response(params: {
    body: RightsDto
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, RightsService.GrantRightsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `grantRights$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  grantRights(params: {
    body: RightsDto
  }): Observable<string> {

    return this.grantRights$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation revokeRights
   */
  static readonly RevokeRightsPath = '/api/rights/{rightsId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `revokeRights()` instead.
   *
   * This method doesn't expect any request body.
   */
  revokeRights$Response(params: {
    rightsId: string;
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, RightsService.RevokeRightsPath, 'delete');
    if (params) {
      rb.path('rightsId', params.rightsId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `revokeRights$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  revokeRights(params: {
    rightsId: string;
  }): Observable<boolean> {

    return this.revokeRights$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation updateCanModifyScope
   */
  static readonly UpdateCanModifyScopePath = '/api/rights/{rightsId}/canModify';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCanModifyScope()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCanModifyScope$Response(params: {
    rightsId: string;
    body?: boolean
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, RightsService.UpdateCanModifyScopePath, 'patch');
    if (params) {
      rb.path('rightsId', params.rightsId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateCanModifyScope$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCanModifyScope(params: {
    rightsId: string;
    body?: boolean
  }): Observable<boolean> {

    return this.updateCanModifyScope$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation updateCanRemoveScope
   */
  static readonly UpdateCanRemoveScopePath = '/api/rights/{rightsId}/canRemove';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCanRemoveScope()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCanRemoveScope$Response(params: {
    rightsId: string;
    body?: boolean
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, RightsService.UpdateCanRemoveScopePath, 'patch');
    if (params) {
      rb.path('rightsId', params.rightsId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateCanRemoveScope$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCanRemoveScope(params: {
    rightsId: string;
    body?: boolean
  }): Observable<boolean> {

    return this.updateCanRemoveScope$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
