import * as moment from 'moment';

export class LeadStats {
    public aggregationMoment: any;
    public date: string;
    public count: number;

    constructor(date: string, count: number) {
        this.aggregationMoment = moment(date.split('T')[0]);
        this.date = this.aggregationMoment.format('YYYY-MM-DD');
        this.count = count;
    }
}