import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ContractApiService } from 'src/app/services/api/contract-api.service';
import { CounterpartyApiService } from 'src/app/services/api/counterparty-api.service';
import { ContractServiceType, ContractSubjectViewModel, ContractSubjects, ContractTypeViewModel, ContractTypes, ContractViewModel } from 'src/app/view-models/contract/contract-view-model';
import { CounterpartyViewModel } from 'src/app/view-models/counterparty/counterparty-view-model';
import { SyncStateViewModel, SyncStates } from 'src/app/view-models/sync-states';

@Component({
  selector: 'app-contract-search-form',
  templateUrl: './contract-search-form.component.html',
  styleUrls: ['./contract-search-form.component.scss']
})
export class ContractSearchFormComponent implements OnInit {
  public get types(): ContractTypeViewModel[] {
    return ContractTypes;
  }

  public get subjects(): ContractSubjectViewModel[] {
    return ContractSubjects;
  }

  public get syncStates(): SyncStateViewModel[] {
    return SyncStates;
  }

  public get counterparties(): CounterpartyViewModel[] {
    return this.counterpatyApi.items;
  }

  private _parentContracts: ContractViewModel[] = [];
  public get parentContracts(): ContractViewModel[] {
    return this._parentContracts;
  }

  public selectedTypes: string[] = [];
  public selectedSubjects: string[] = [];
  
  public clientId: string | null = null;
  public parentContractId: string | null = null;

  public syncState: string | null = null;
  public acceptedByErir: boolean | null = null;

  public dateOfConclusionAfter: Date | null = null;
  public dateOfConclusionBefore: Date | null = null;

  @Output() public onQueryChanged: EventEmitter<ContractSearchQuery> = new EventEmitter<ContractSearchQuery>();

  constructor(private counterpatyApi: CounterpartyApiService,
    private contractApi: ContractApiService) { }

  ngOnInit(): void {
    this.counterpatyApi.Load(1,500).subscribe();

    this.contractApi.Load(1, 500, [ContractServiceType.code]).subscribe(x => {
      this._parentContracts = x;
    });
  }

  public reset(): void {
    this.selectedTypes = [];
    this.selectedSubjects = [];

    this.parentContractId = null;
    this.clientId = null;

    this.syncState = null;
    this.acceptedByErir = null;

    this.dateOfConclusionAfter = null;
    this.dateOfConclusionBefore = null;

    this.send();
  }

  public send(): void {
    const query = new ContractSearchQuery();
    query.types = this.selectedTypes;
    query.subjects = this.selectedSubjects;

    query.parentContractId = this.parentContractId;
    query.clientId = this.clientId;

    query.synState = this.syncState;
    query.acceptedByErir = this.acceptedByErir;

    query.dateOfConclusionAfter = this.dateOfConclusionAfter;
    query.dateOfConclusionBefore = this.dateOfConclusionBefore;

    this.onQueryChanged.next(query);

    // TODO UPdate query
  }

}

export class ContractSearchQuery {
  public types: string[] = [];
  public subjects: string[] = [];
  
  public parentContractId: string | null = null;
  public clientId: string | null = null;

  public synState: string | null = null;

  public dateOfConclusionAfter: Date | null = null;
  public dateOfConclusionBefore: Date | null = null;

  public acceptedByErir: boolean | null = null; 
}