import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { IntegrationDto, IntegrationMappingDto } from 'src/api/integrations/models';
import { Integration, IntegrationMaping, integrationStatuses } from 'src/app/view-models/integrations/integration';

@Injectable({
  providedIn: 'root'
})
export class IntegrationAssemblerService {

  public toViewModel(other: IntegrationDto): Integration {
    const model = new Integration(other.sourceObjectId ?? '', other.sourceEntityId ?? null);
    model.id = other.id ?? null;
    model.destinationObjectId = other.destinationObjectId ?? null;
    model.name = other.name ?? '';
    model.lastSyncAt = moment(other.lastSyncAt);
    model.status = integrationStatuses.find(x => x.codename === other.status) ?? integrationStatuses[0];

    other.mappings?.forEach(mapping => {
      model.addMapping(this.toMappingViewModel(mapping));
    });

    return model;
  }

  public toMappingViewModel(other: IntegrationMappingDto): IntegrationMaping {
    const model = new IntegrationMaping(other.sourceFieldId ?? '', other.destinationFieldId ?? '');
    model.id = other.id ?? null;
    model.format = other.format ?? '';
    model.subtype = other.subtype ?? '';

    return model;
  }

  public toDto(other: Integration): IntegrationDto {
    return {
      id: other.id ?? undefined,
      name: other.name,
      sourceObjectId: other.sourceObjectId,
      sourceEntityId: other.sourceEntityId ?? undefined,
      destinationObjectId: other.destinationObjectId ?? undefined,
      status: other.status.codename,

    };
  }

  public toMappingDto(other: IntegrationMaping): IntegrationMappingDto {
    return {
      id: other.id ?? undefined,
      sourceFieldId: other.sourceFieldId,
      destinationFieldId: other.destinationFieldId,
      format: other.format,
      subtype: other.subtype
    };
  }
}
