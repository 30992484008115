import { Component, OnInit } from '@angular/core';
import { ConfirmationService, LazyLoadEvent } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { InvoiceDialogueComponent } from 'src/app/dialogues/invoice-dialogue/invoice-dialogue.component';
import { ContractApiService } from 'src/app/services/api/contract-api.service';
import { CounterpartyApiService } from 'src/app/services/api/counterparty-api.service';
import { InvoiceApiService } from 'src/app/services/api/invoice-api.service';
import { PlatformApiService } from 'src/app/services/api/platform-api.service';
import { InvoiceViewModel } from 'src/app/view-models/invoice/invoice-view-model';

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss'],
  providers: [ConfirmationService]
})
export class InvoiceListComponent implements OnInit {

  public get isLoading(): boolean {
    return this.invoiceApi.isLoading;
  }

  public get items(): InvoiceViewModel[] {
    return this.invoiceApi.items;
  }

  public get itemsTotalCount(): number {
    return this.invoiceApi.itemsTotalCount;
  }

  public pageIndex: number = 1;
  public pageSize: number = 10;
  private _previousEvent: LazyLoadEvent | null = null;

  constructor(private dialogService: DialogService,
    private invoiceApi: InvoiceApiService,
    private counterpartyApi: CounterpartyApiService,
    private contractApi: ContractApiService,
    
    private confirmationService: ConfirmationService) { }

  ngOnInit(): void {
  }

  public OnLazyLoad(event: LazyLoadEvent | null) {
    const current = event ?? this._previousEvent;

    if (event) {
      this._previousEvent = event;
    }
    
    this.pageSize = current?.rows ?? 10;
    this.pageIndex = (current?.first) ? (current.first/this.pageSize)+1 : 1;

    this.LoadCurrentPage();
  }

  public CreateItemClicked(): void {
    const ref = this.dialogService.open(InvoiceDialogueComponent, {
      header: 'Создать акт',
      width: '90%',
      height: '90%'
    });

    ref.onClose.subscribe((result: boolean) => {
      this.LoadCurrentPage();
    });
  }

  public UpdateItemClicked(item: InvoiceViewModel): void {
    const ref = this.dialogService.open(InvoiceDialogueComponent, {
      header: 'Обновить акт',
      width: '90%',
      height: '90%',
      data: item
    });

    ref.onClose.subscribe((result: boolean) => {
      if (result) {
        this.LoadCurrentPage();
      }
    });
  }

  public SyncClick(model: InvoiceViewModel): void {
    if (!model || !model.id) {
      return;
    }

    model.isSyncing = false;

    this.confirmationService.confirm({
      header: 'Подтверждение',
      message: 'Вы действительно хотите передать данные в ОРД? Действие нельзя будет отменить.',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        if (model && model.id) {
          this.invoiceApi.Sync(model.id).subscribe(x => {
            model.isSyncing = true;
          });
        }
      }
    });
  }

  private LoadCurrentPage(): void {
    this.invoiceApi.Load(this.pageIndex, this.pageSize).subscribe(x => {

      // Получить данные об договорах
      const contractIds = [...new Set(this.invoiceApi.items.map(item => item.contractId))];

      this.contractApi.GetByIds(contractIds).subscribe(contracts => {
        // Получить контрагентов
        const counterpartyClientIds = [...new Set(contracts.map(item => item.clientCounterpartyId))];

        this.counterpartyApi.GetByIds(counterpartyClientIds).subscribe(counterparties => {
          // Назначить каждому инвойсу соответствующий договор
          this.invoiceApi.items.forEach(invoice => {
            invoice.contract = contracts.find(x => x.id === invoice.contractId) ?? null;

            // Назначить договору контрагента исполнителя
            if (invoice.contract) {
              invoice.contract.client = counterparties.find(y => y.id === invoice.contract?.clientCounterpartyId) ?? null;
            }
          });
        });
      });
    });
  }
}
