import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs/internal/Observable';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { map } from 'rxjs/operators';
import { ContractApiService } from 'src/app/services/api/contract-api.service';
import { CounterpartyApiService } from 'src/app/services/api/counterparty-api.service';
import { CreativeApiService } from 'src/app/services/api/creative-api.service';
import { ContractSubjects, ContractSubjectViewModel, ContractTypes, ContractTypeViewModel, ContractViewModel } from 'src/app/view-models/contract/contract-view-model';
import { CounterpartyViewModel } from 'src/app/view-models/counterparty/counterparty-view-model';
import { CreativeContractViewModel, CreativeViewModel } from 'src/app/view-models/creative/creative-view-model';

@Component({
  selector: 'app-contract-dialogue',
  templateUrl: './contract-dialogue.component.html',
  styleUrls: ['./contract-dialogue.component.scss']
})
export class ContractDialogueComponent implements OnInit {
  public isBusy: boolean = false;

  public form: FormGroup;

  public isCreateMode: boolean = false;
  private _values: ContractViewModel | null;


  public get isInvalid(): boolean {
    return !this.form.valid;
  }

  public get types(): ContractTypeViewModel[] {
    return ContractTypes;
  }

  public get subjects(): ContractSubjectViewModel[] {
    return ContractSubjects;
  }

  private _counterparties: CounterpartyViewModel[] = [];
  public get counterparties(): CounterpartyViewModel[] {
    return this._counterparties;
  }

  public contractCreatives: CreativeContractViewModel[] = [];

  public get creatives(): CreativeViewModel[] {
    return this.creativeApi.items;
  }

  private _contracts: ContractViewModel[] = [];
  public get contracts(): ContractViewModel[] {
    return this.contractApi.items.filter(x => x.typeCode === 'service');
  }

  public get isAdditionalContract(): boolean {
    return this.form.getRawValue()['type'] === 'additional';
  }

  constructor(private api: ContractApiService,
    private contractApi: ContractApiService,
    private counterpartyApi: CounterpartyApiService,
    private creativeApi: CreativeApiService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig) {
    this._values = this.config.data ?? null;
    this.isCreateMode = (!this.config.data?.id) ?? false;

      if (!this.isCreateMode && this.config.data) {
        this.contractCreatives = this.config.data.creatives;
      }

    this.form = new FormGroup({
      id: new FormControl({
        value: this._values?.id ?? null,
        disabled: false
      }),
      parentId: new FormControl({
        value: this._values?.parentId ?? null,
        disabled: false
      }, []),
      type: new FormControl({
        value: this._values?.typeCode ?? this.types[0].code,
        disabled: false
      }, [Validators.required,]),
      subject: new FormControl({
        value: this._values?.subjectCode ?? this.subjects[0].code,
        disabled: false
      }, [Validators.required,]),
      serialNumber: new FormControl({
        value: this._values?.serialNumber,
        disabled: false
      }, [Validators.required, Validators.maxLength(1024)]),
      dateOfConclusion: new FormControl({
        value: this._values?.dateOfConclusion,
        disabled: false
      }, [Validators.required]),
      contractorCounterpartyId: new FormControl({
        value: this._values?.contractorCounterpartyId,
        disabled: !this.isCreateMode
      }, [Validators.required]),
      clientCounterpartyId: new FormControl({
        value: this._values?.clientCounterpartyId,
        disabled: !this.isCreateMode
      }, [Validators.required]),
      amount: new FormControl({
        value: this._values?.amount,
        disabled: false
      }, [Validators.required]),
      isVatIncluded: new FormControl({
        value: this._values?.isVatIncluded ?? false,
        disabled: false
      }, [Validators.required])
    });
  }

  ngOnInit(): void {
    this.counterpartyApi.Load(1, 500).subscribe(x => {
      this._counterparties = this.counterpartyApi.items;
    });

    this.creativeApi.Load(1, 500).subscribe(() => {
        this.EnrichContractsWithCounterparties();
      }
    );

    this.contractApi.Load(1, 500).subscribe(() => {
      this.EnrichContractsWithCounterparties();
      this.RefreshParent();
    });
  }

  private RefreshParent(): void {
    if (!this.isCreateMode && this.config.data) {
      this.form.controls['parentId'].setValue(this._values?.parentId ?? '') ;
    }
  }

  private EnrichContractsWithCounterparties(): void {
    if (this.contracts.length === 0 || this.counterparties.length === 0) {
      return;
    }

    this.contracts.forEach(contract => {
      contract.client = this.counterparties.find(x => x.id === contract.clientCounterpartyId) ?? null;
    });
  }

  public Submit(): void {
    if (!this.form.valid) {
      return;
    }

    const model = this.GenerateModel();

    this.isBusy = true;

    if (model.id) {
      this.UpdateEntity(model.id, model);
    } else {
      this.CreateEntity(model);
    }
  }

  private GenerateModel(): ContractViewModel {
    const values = this.form.getRawValue();
    let yourDate = (typeof values.dateOfConclusion === 'string' || values.dateOfConclusion instanceof String) ? new Date() : values.dateOfConclusion;

    const offset = yourDate.getTimezoneOffset()
    yourDate = new Date(yourDate.getTime() - (offset * 60 * 1000))

    const model = new ContractViewModel(values.type, values.subject, values.serialNumber,
      yourDate.toISOString().split('T')[0],
      values.contractorCounterpartyId, values.clientCounterpartyId,
      parseInt(values.amount, 10));
    model.id = values.id ?? null;
    model.parentId = values.parentId ?? null;

    return model;
  }

  private CreateEntity(model: ContractViewModel): void {
    this.api.Create(model).subscribe(id => {

      this.isBusy = false;
      this.ref.close(true);
    });
  }

  private UpdateEntity(id: string, model: ContractViewModel): void {
    this.api.Update(id, model).subscribe(x => {
      this.cascade();
    });
  }

  public AddItem(): void {
    if (!this._values?.id) {
      return;
    }

    const model = new CreativeContractViewModel(this._values.id);

    this.contractCreatives.push(model);
  }

  public RemoveItem(model: CreativeContractViewModel): void {
    if (!model) {
      return;
    }

    model.isRemoved = true;
  }

  public RestoreItem(model: CreativeContractViewModel): void {
    if (!model) {
      return;
    }

    model.isRemoved = false;
  }

  public SyncClick(model: CreativeContractViewModel): void {
    if (!model || !model.id || !model.creativeId || !model.contractId){
      return;
    }

    if (model.isSynced || model.isSyncing) {
      return;
    }

    model.isSyncing = true;

    this.creativeApi.Sync(model.creativeId, model.contractId).subscribe(x => {
      
    });
  }

  private complete(results: boolean[]): void {
    this.isBusy = false;

    let hasChangedResult: boolean = false;

    results.forEach(result => {
      if (result) {
        hasChangedResult = true;
      }
    });

    this.ref.close(hasChangedResult);
  }

  private cascade(): void {
    const cretiveContractsToRemove = this.contractCreatives.filter(x => x.id && x.isRemoved);
      const cretiveContractsToCreate = this.contractCreatives.filter(x => !x.id && !x.isRemoved);

      const requests: Observable<boolean>[] = []; 

      if (cretiveContractsToRemove.length > 0) {
        cretiveContractsToRemove.forEach(creativeContract => {
          if (creativeContract.creativeId) {
            const request = this.creativeApi.RemoveContract(creativeContract.creativeId, creativeContract.contractId);

            requests.push(request);
          }
        });
      }

      if (cretiveContractsToCreate.length > 0) {
        cretiveContractsToCreate.forEach(creativeContract => {
          if (creativeContract.creativeId) {
            const request = this.creativeApi.AddContract(creativeContract.creativeId, creativeContract).pipe(
              map(x => {
                return true;
              })
            );

            requests.push(request);
          }
        });
      }

      if (requests.length > 0){
        forkJoin(requests).subscribe(x => {
          this.complete(x);
        });
      } else {
        this.complete([]);
      }
  }
}
