export class SyncStateViewModel {
    public code: string;
    public name: string;

    constructor(code: string, name: string) {
        this.code = code;
        this.name = name;
    }
}

export const SyncStates: SyncStateViewModel[] = [
    new SyncStateViewModel('notSynced','Передать в ОРД'),
    new SyncStateViewModel('syncing','Передаётся в ОРД'),
    new SyncStateViewModel('synced','Передано в ОРД'),
];