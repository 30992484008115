<ng-container *ngIf="contract; else noContract">
    <div *ngIf="contract.type" class="text-muted text-small">
        {{contract.type.name}}
    </div>
    
    <div>
        {{contract.serialNumber}}
        <!-- <span class="text-muted">
            от {{contract.dateOfConclusion}}
        </span> -->
    </div>
    <div *ngIf="showAmount" class="text-small">
        <span class="text-muted">на сумму</span> {{contract.amount|number}} <span class="text-muted">руб.</span>
    </div>
</ng-container>
<ng-template #noContract>
    Loading...
</ng-template>