<div class="flex align-items-center">
    <h1 class="mr-auto">
        Отслеживаемые ссылки
    </h1>

    <button *ngIf="isAdmin" pButton type="button" icon="fa-solid fa-plus" label="Создать"
        (click)="OpenCreateDialog()"></button>
</div>

<div class="mt-5 fancy-card">
    <div class="fancy-card-content">
        <p-table [value]="items" [paginator]="true" [rows]="10" [showCurrentPageReport]="true" [loading]="isLoading"
            [showLoader]="false" [lazy]="true" (onLazyLoad)="LoadData($event)" dataKey="id" [totalRecords]="totalAmount"
            [globalFilterFields]="['host']"
            currentPageReportTemplate="Показаны с {first} по {last} из {totalRecords} строчек"
            [rowsPerPageOptions]="[10,50,100,500]">
            <ng-template pTemplate="header">
                <tr>
                    <th class="flex align-items-center">
                        <span class="mr-3">Хост</span>
                        <p-columnFilter class="w-full" type="text" field="host" placeholder="Поиск по адресу" [showMenu]="false"></p-columnFilter>
                    </th>
                    <th style="width: 150px">Всего переходов</th>
                    <th style="width: 250px">Всего уникальных посетителей</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
                <tr>
                    <td class="flex align-items-center">
                        <button pButton type="button" icon="fa-solid fa-pencil" (click)="OpenEditDialog(item)" pTooltip="Настроить параметры ссылки"
                            class="p-button-sm p-button-secondary p-button-text mr-1"></button>

                        <button *ngIf="isAdmin" pButton type="button" icon="fa-solid fa-crown" pTooltip="Настроить права доступа"
                            (click)="ShowRightsManagement(item)" class="p-button-sm p-button-text mr-1"></button>

                        <button *ngIf="isAdmin" pButton type="button" icon="fa-regular fa-clipboard" pTooltip="Копировать трекинговый код" ngxClipboard [cbContent]="item.shortcode"
                            class="p-button-sm p-button-secondary p-button-text mr-1"></button>

                        <div class="mr-3">
                            <div class="h5">
                                <ng-container *ngIf="item.name; else noName">
                                    {{item.name}}
                                </ng-container>
                                <ng-template #noName>
                                    <span class="text-bare">Название не указано</span>
                                </ng-template>
                            </div>

                            <div class="">
                                <span class="text-bare text-small">{{item.host}}</span><a [attr.href]="item.url" rel="nofollow noopener noreferrer"
                                    target="_blank" class="ml-1 text-small" pTooltip="Ссылка на целевой ресурс">
                                    <i class="fa-solid fa-up-right-from-square"></i>
                                </a>
                            </div>
                        </div>





                        <button *ngIf="item.overallStats" pButton type="button" icon="fa-solid fa-chart-line"
                            label="Статистика" (click)="GoToStatistics(item)"
                            class="ml-auto p-button-sm p-button-text"></button>
                    </td>

                    <ng-container *ngIf="item.overallStats; else noOverallStats">
                        <td>
                            {{item.overallStats.overallVisits|number}}
                        </td>
                        <td>
                            {{item.overallStats.uniqueVisits|number}}
                        </td>
                    </ng-container>
                    <ng-template #noOverallStats>
                        <ng-container *ngIf="isStatsLoading; else statsFailedToLoad">
                            <td>
                                <p-skeleton width="100%" height="2rem"></p-skeleton>
                            </td>

                            <td>
                                <p-skeleton width="100%" height="2rem"></p-skeleton>
                            </td>
                        </ng-container>
                        <ng-template #statsFailedToLoad>
                            <td>
                                <span class="text-bare text-small">-</span>
                            </td>

                            <td>
                                <span class="text-bare text-small">-</span>
                            </td>
                        </ng-template>
                    </ng-template>
                </tr>
            </ng-template>

            <ng-template pTemplate="loadingbody">
                <tr *ngFor="let item of [1,2,3,4,5,6,7,8,9,10]">
                    <td>
                        <p-skeleton width="100%" height="2rem"></p-skeleton>
                    </td>

                    <td style="width: 200px">
                        <p-skeleton width="100%" height="2rem"></p-skeleton>
                    </td>

                    <td style="width: 320px">
                        <p-skeleton width="100%" height="2rem"></p-skeleton>
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="3">
                        <div class="text-center">
                            <h3>
                                Нет доступных данных
                            </h3>
                            <p>
                                Не удалось получить данные о доступных ссылках.
                            </p>
                            <button pButton type="button" icon="fa-solid fa-rotate" label="Обновить"
                                (click)="LoadData(null)"></button>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<div class="mt-3 text-smaller text-bare">
    Обратите внимание, что общая статистика на этой странице обновляется несколько раз в час.<br>
    Для просмотра актуальной статистики перейдите в раздел статистики интересующей  вас ссылки.
</div>