/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CounterpartyDto } from '../models/counterparty-dto';
import { CounterpartyFieldDto } from '../models/counterparty-field-dto';

@Injectable({
  providedIn: 'root',
})
export class CounterpartyService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation listCounterparties
   */
  static readonly ListCounterpartiesPath = '/api/counterparty';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listCounterparties()` instead.
   *
   * This method doesn't expect any request body.
   */
  listCounterparties$Response(params?: {
    page?: number;
    pageSize?: number;
    filterName?: string;
    filterInn?: string;
    filterOgrn?: string;
    filterTypes?: Array<string>;
    filterSyncState?: string;
    filterAcceptedByErir?: boolean;
  }): Observable<StrictHttpResponse<Array<CounterpartyDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CounterpartyService.ListCounterpartiesPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('filterName', params.filterName, {});
      rb.query('filterInn', params.filterInn, {});
      rb.query('filterOgrn', params.filterOgrn, {});
      rb.query('filterTypes', params.filterTypes, {});
      rb.query('filterSyncState', params.filterSyncState, {});
      rb.query('filterAcceptedByErir', params.filterAcceptedByErir, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CounterpartyDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listCounterparties$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listCounterparties(params?: {
    page?: number;
    pageSize?: number;
    filterName?: string;
    filterInn?: string;
    filterOgrn?: string;
    filterTypes?: Array<string>;
    filterSyncState?: string;
    filterAcceptedByErir?: boolean;
  }): Observable<Array<CounterpartyDto>> {

    return this.listCounterparties$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CounterpartyDto>>) => r.body as Array<CounterpartyDto>)
    );
  }

  /**
   * Path part for operation createCounterpary
   */
  static readonly CreateCounterparyPath = '/api/counterparty';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCounterpary()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCounterpary$Response(params?: {
    body?: CounterpartyDto
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, CounterpartyService.CreateCounterparyPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createCounterpary$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCounterpary(params?: {
    body?: CounterpartyDto
  }): Observable<string> {

    return this.createCounterpary$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getCounterpartyByIds
   */
  static readonly GetCounterpartyByIdsPath = '/api/counterparty/byids';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCounterpartyByIds()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCounterpartyByIds$Response(params: {
    ids: Array<string>;
  }): Observable<StrictHttpResponse<Array<CounterpartyDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CounterpartyService.GetCounterpartyByIdsPath, 'get');
    if (params) {
      rb.query('ids', params.ids, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CounterpartyDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCounterpartyByIds$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCounterpartyByIds(params: {
    ids: Array<string>;
  }): Observable<Array<CounterpartyDto>> {

    return this.getCounterpartyByIds$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CounterpartyDto>>) => r.body as Array<CounterpartyDto>)
    );
  }

  /**
   * Path part for operation updateCounterparty
   */
  static readonly UpdateCounterpartyPath = '/api/counterparty/{entityId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCounterparty()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCounterparty$Response(params: {
    entityId: string;
    body?: CounterpartyDto
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, CounterpartyService.UpdateCounterpartyPath, 'put');
    if (params) {
      rb.path('entityId', params.entityId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateCounterparty$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCounterparty(params: {
    entityId: string;
    body?: CounterpartyDto
  }): Observable<boolean> {

    return this.updateCounterparty$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation updateCounterpartyFields
   */
  static readonly UpdateCounterpartyFieldsPath = '/api/counterparty/{entityId}/fields';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCounterpartyFields()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCounterpartyFields$Response(params: {
    entityId: string;
    body?: Array<CounterpartyFieldDto>
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, CounterpartyService.UpdateCounterpartyFieldsPath, 'put');
    if (params) {
      rb.path('entityId', params.entityId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateCounterpartyFields$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCounterpartyFields(params: {
    entityId: string;
    body?: Array<CounterpartyFieldDto>
  }): Observable<boolean> {

    return this.updateCounterpartyFields$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation createCounterpartyFields
   */
  static readonly CreateCounterpartyFieldsPath = '/api/counterparty/{entityId}/fields';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCounterpartyFields()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCounterpartyFields$Response(params: {
    entityId: string;
    body?: Array<CounterpartyFieldDto>
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, CounterpartyService.CreateCounterpartyFieldsPath, 'post');
    if (params) {
      rb.path('entityId', params.entityId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createCounterpartyFields$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCounterpartyFields(params: {
    entityId: string;
    body?: Array<CounterpartyFieldDto>
  }): Observable<boolean> {

    return this.createCounterpartyFields$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation removeCounterpartyFields
   */
  static readonly RemoveCounterpartyFieldsPath = '/api/counterparty/{entityId}/fields';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeCounterpartyFields()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  removeCounterpartyFields$Response(params: {
    entityId: string;
    body?: Array<string>
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, CounterpartyService.RemoveCounterpartyFieldsPath, 'delete');
    if (params) {
      rb.path('entityId', params.entityId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeCounterpartyFields$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  removeCounterpartyFields(params: {
    entityId: string;
    body?: Array<string>
  }): Observable<boolean> {

    return this.removeCounterpartyFields$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation addCounterpartyToSyncQueue
   */
  static readonly AddCounterpartyToSyncQueuePath = '/api/counterparty/{entityId}/sync';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addCounterpartyToSyncQueue()` instead.
   *
   * This method doesn't expect any request body.
   */
  addCounterpartyToSyncQueue$Response(params: {
    entityId: string;
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, CounterpartyService.AddCounterpartyToSyncQueuePath, 'post');
    if (params) {
      rb.path('entityId', params.entityId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addCounterpartyToSyncQueue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addCounterpartyToSyncQueue(params: {
    entityId: string;
  }): Observable<boolean> {

    return this.addCounterpartyToSyncQueue$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
