import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContractDto } from 'src/api/legal/models';
import { ContractService } from 'src/api/legal/services';
import { ContractViewModel } from 'src/app/view-models/contract/contract-view-model';
import { ContractAssemblerService } from '../assemblers/contract-assembler.service';

@Injectable({
  providedIn: 'root'
})
export class ContractApiService {
  private _isLoading: boolean = false;
  public get isLoading(): boolean {
    return this._isLoading;
  }

  private _items: ContractViewModel[] = [];
  public get items(): ContractViewModel[] {
    return this._items;
  }

  private _itemsTotalCount: number = 0;
  public get itemsTotalCount(): number {
    return this._itemsTotalCount;
  }

  constructor(private api: ContractService, private assembler: ContractAssemblerService) { }

  public Sync(id: string): Observable<boolean> {
    return this.api.addContractToSyncQueue({
      entityId: id
    });
  }

  public Load(pageIndex: number = 1, pageSize: number = 10,
    filterTypes: string[] | null = null,
    filterSubjects: string[] | null = null,
    filterClientId: string | null = null,
    filterParentId: string | null = null,
    filterDateOfConclusionBefore: Date | null = null,
    filterDateOfConclusionAfter: Date | null = null,
    filterSyncState: string | null = null,
    filterAcceptedByErir: boolean | null = null): Observable<ContractViewModel[]> {
    this._isLoading = true;
    this._items = [];

    return this.api.listContracts$Response({
      page: pageIndex,
      pageSize: pageSize,
      filterTypes: filterTypes ?? undefined,
      filterSubjects: filterSubjects ?? undefined,
      filterClientId: (filterClientId && filterClientId !== '') ? filterClientId : undefined,
      filterParentId: (filterParentId && filterParentId !== '') ? filterParentId : undefined,
      filterDateOfConclusionBefore: filterDateOfConclusionBefore?.toISOString().split('T')[0] ?? undefined,
      filterDateOfConclusionAfter: filterDateOfConclusionAfter?.toISOString().split('T')[0] ?? undefined,
      filterSyncState: (filterSyncState && filterSyncState !== '') ? filterSyncState : undefined,
      filterAcceptedByErir: filterAcceptedByErir ?? undefined
    }).pipe(
      map(x => {
        const totaCountRaw = x.headers.get('x-total-count');
        
        if (totaCountRaw) {
          this._itemsTotalCount = parseInt(totaCountRaw, 10);
        }

        return x.body as Array<ContractDto>;
      }),
      map(response => {
        this._isLoading = false;

        const result = response.map(x => this.assembler.ToViewModel(x));

        this._items = result;

        // this._items.forEach(x => {
        //   x.type = CounterpartyTypes.find(type => type.code === x.typeCode) ?? null;
        //   x.fields.forEach(field => {
        //     field.isSynced = true;
        //   });
        // });

        return result;
      })
    );
  }

  public GetByIds(ids: string[]): Observable<ContractViewModel[]> {
    return this.api.getContractsByIds({
      ids: ids
    }).pipe(
      map(response => {
        return response.map(x => this.assembler.ToViewModel(x));
      })
    );
  }

  public Create(model: ContractViewModel): Observable<string> {
    const dto = this.assembler.ToDto(model);

    return this.api.createContract({
      body: dto
    });
  }

  public Update(id: string, model: ContractViewModel): Observable<boolean> {
    const dto = this.assembler.ToDto(model);

    return this.api.updateContract({
      entityId: id,
      body: dto
    });
  }
}
