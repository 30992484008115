import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UserViewModel } from 'src/app/view-models/user/user-view-model';

@Component({
  selector: 'app-user-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.scss']
})
export class UserDialogComponent implements OnInit {
  public form: FormGroup;
  
  private _login:string = '';

  public blockLogin: boolean = false;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
    this._login = this.config.data?.login ?? '';

    if (this._login) {
      this.blockLogin = true;
    }

    this.form = new FormGroup({
      login: new FormControl({
        value: this._login,
        disabled: this.blockLogin
      }, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(32)
      ]),
      password: new FormControl({
        value: '',
        disabled: false
      }, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(128)
      ])
    });
  }

  ngOnInit(): void {
  }

  public Cancel(): void {
    this.ref.close(null);
  }

  public Confirm(): void {
    if (!this.form.valid) {
      return; // TODO Iform about validation error
    }
    
    const values = this.form.getRawValue();

    const model = new UserViewModel(values.login);
    model.password = values.password;

    this.ref.close(model);
  }

}
