import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CounterpartyApiService } from 'src/app/services/api/counterparty-api.service';
import { CounterpartyTypeViewModel, CounterpartyTypes, CounterpartyViewModel } from 'src/app/view-models/counterparty/counterparty-view-model';
import { SyncStateViewModel, SyncStates } from 'src/app/view-models/sync-states';

@Component({
  selector: 'app-counterparty-search-form',
  templateUrl: './counterparty-search-form.component.html',
  styleUrls: ['./counterparty-search-form.component.scss']
})
export class CounterpartySearchFormComponent implements OnInit {
  public get types(): CounterpartyTypeViewModel[] {
    return CounterpartyTypes;
  }
  
  public get syncStates(): SyncStateViewModel[] {
    return SyncStates;
  }

  public searchTerm: string = '';
  public inn: string = '';
  public ogrn: string = '';
  public selectedTypes: string[] = [];

  public syncState: string | null = null;
  public acceptedByErir: boolean | null = null;

  @Output() public onQueryChanged: EventEmitter<CounterpartySearchQuery> = new EventEmitter<CounterpartySearchQuery>();

  constructor(private counterpatyApi: CounterpartyApiService) { }

  ngOnInit(): void {
    
  }

  public reset(): void {
    this.selectedTypes = [];

    this.searchTerm = '';
    this.inn = '';
    this.ogrn = '';
    this.syncState = null;
    this.acceptedByErir = null;

    this.send();
  }

  public send(): void {
    const query = new CounterpartySearchQuery();

    query.searchTerm = this.searchTerm;
    query.inn = this.inn;
    query.ogrn = this.ogrn;
    query.synState = this.syncState;
    query.acceptedByErir = this.acceptedByErir;
    query.selectedTypes = this.selectedTypes;

    this.onQueryChanged.next(query);
  }

}

export class CounterpartySearchQuery {
  public searchTerm: string = '';
  public inn: string = '';
  public ogrn: string = '';

  public selectedTypes: string[] = [];

  public synState: string | null = null;
  public acceptedByErir: boolean | null = null; 
}