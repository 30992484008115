export class OverallVisitAggregationViewModel {
    public linkId: string;

    public overallVisits: number;
    public uniqueVisits: number;

    private _since: Date;
    public get since(): string {
        return this._since.toLocaleDateString();
    }

    public get rawSince(): Date {
        return this._since;
    }

    constructor(linkId: string, overallVisits: number, uniqueVisits: number, since: Date) {
        this.linkId = linkId;
        this.overallVisits = overallVisits;
        this.uniqueVisits = uniqueVisits;

        this._since = since;
    }
}