import { Injectable } from '@angular/core';
import { EconomicClassificationService } from 'src/api/legal/services';
import { EconomicClassificationAssemblerService } from '../assemblers/economic-classification-assembler.service';
import { EconomicClassification } from 'src/app/view-models/economic-classification/economic-classification';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EconomicClassificationApiService {

  constructor(private api: EconomicClassificationService, private assembler: EconomicClassificationAssemblerService) { }

  public list(page: number = 1, pageSize: number = 500): Observable<EconomicClassification[]> {
    return this.api.listEconomicClassifications({
      page: page,
      pageSize: pageSize
    }).pipe(
      map(dtos => {
        return dtos.map(dto => this.assembler.ToViewModel(dto));
      })
    );
  }
}
