import { HttpClient, HttpErrorResponse, HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss']
})
export class UploadImageComponent implements OnInit {
  public progress: number = -1;
  public message: string = '';

  public uploaded: string[] = [];

  constructor(private http: HttpClient,
    public ref: DynamicDialogRef, 
    public config: DynamicDialogConfig) { }

  ngOnInit(): void {
    this.loadFiles();
  }

  private loadFiles(): void {
    this.http.get<string[]>('/api/upload').subscribe(x =>{
      this.uploaded = x ?? [];
    });
  }

  public selectimage(filename: string): void {
    this.ref.close(filename);
  }

  public uploadFile = (files:FileList|null) => {
    if (!files || files.length === 0) {
      return;
    }
    let fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    
    this.http.post('/api/upload', formData, {reportProgress: true, observe: 'events'})
      .subscribe({
        next: (event) => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round(100 * event.loaded / (event.total ?? 1));
        else if (event.type === HttpEventType.Response) {
          this.message = 'Upload success.';
          this.selectimage((event?.body as string) ?? '');
        }
      },
      error: (err: HttpErrorResponse) => this.selectimage(err.error.text as string)
    });
  }
}
