import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './routes/home/home.component';
import { ContractListComponent } from './routes/legal/contract-list/contract-list.component';
import { CounterpartyListComponent } from './routes/legal/counterparty-list/counterparty-list.component';
import { CounterpartySingleComponent } from './routes/legal/counterparty-single/counterparty-single.component';
import { CreativeListComponent } from './routes/legal/creative-list/creative-list.component';
import { InvoiceListComponent } from './routes/legal/invoice-list/invoice-list.component';
import { LinkListComponent } from './routes/link-list/link-list.component';
import { LinkSingleComponent } from './routes/link-single/link-single.component';
import { UserListComponent } from './routes/user-list/user-list.component';
import { UserSingleComponent } from './routes/user-single/user-single.component';
import { AuthGuard } from './services/auth-guard.guard';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: "link",
    component: LinkListComponent,
    canActivate: [AuthGuard]
  }, {
    path: "link/:websiteId",
    component: LinkSingleComponent,
    canActivate: [AuthGuard]
  }, {
    path: "user",
    component: UserListComponent,
    canActivate: [AuthGuard]
  }, {
    path: "user/:userId",
    component: UserSingleComponent,
    canActivate: [AuthGuard]
  }, {
    path: "counterparty",
    component: CounterpartyListComponent,
    canActivate: [AuthGuard]
  }, {
    path: "contract",
    component: ContractListComponent,
    canActivate: [AuthGuard]
  }, {
    path: "creative",
    component: CreativeListComponent,
    canActivate: [AuthGuard]
  }, {
    path: "invoice",
    component: InvoiceListComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule { }
