import { ContractViewModel } from "../contract/contract-view-model";
import { CounterpartyViewModel } from "../counterparty/counterparty-view-model";
import { EconomicClassification } from "../economic-classification/economic-classification";

export class CreativeViewModel {
    public id: string | null = null;

    public selfAdvertisementCounterpartyId: string|null = null;;
    public selfAdvertisementCounterparty: CounterpartyViewModel|null = null;;

    public name: string;
    public description: string = '';

    public formCode: string;
    public form: CreativeForm | null = null;

    public campaignTypeCode: string;
    public campaignType: CreativeCampaignType | null = null;

    public adLink: string = '';
    public previewLink: string = '';

    public isSocialAd: boolean = false;
    public isPoliticAd: boolean = false;

    public contracts: CreativeContractViewModel[] = [];
    public media: CreativeMediaViewModel[] = [];
    public economicClassifications: CreativeEconomicClassificationViewModel[] = [];
// TODO fias
// TODO geo region

    public isSynced: boolean = false;
    public isSyncing: boolean = false;
    public isAcceptedByErir: boolean = false;
    public erirId: string = '';
    public marker: string|null = null;

    public createdAt: string = '';

    public userVerticalLayout: boolean = false;

    constructor(name: string, formCode: string, campaignTypeCode: string) {
        //this.marker = marker;
        this.name = name;
        this.formCode = formCode;
        this.campaignTypeCode = campaignTypeCode;
    }
}

export class CreativeContractViewModel {
    public id: string | null = null;

    public creativeId: string | null = null;
    public creative: CreativeViewModel | null = null;

    public contractId: string;
    public contract: ContractViewModel | null = null;

    public marker: string = '';

    public isRemoved: boolean = false;
    public isSynced: boolean = false;
    public isSyncing: boolean = false;
    public isAcceptedByErir: boolean = false;
    public erirId: string = '';
    
    public createdAt: string = '';

    constructor(contractId: string) {
        this.contractId = contractId;
    }
}

export class CreativeEconomicClassificationViewModel {
    public economicClassificationId: string | null = null;
    public economicClassification: EconomicClassification | null = null;
    
    public isRemoved: boolean = false;

    constructor(economicClassificationId: string)
    {
        this.economicClassificationId = economicClassificationId;
    }
}

export class CreativeMediaViewModel {
    public uri: string = '';
    public description: string = '';
}

export class CreativeForm {
    public code: string;
    public name: string;

    constructor(code: string, name: string) {
        this.code = code;
        this.name = name;
    }
}

export const CreativeForms: CreativeForm[] = [
    new CreativeForm('banner', 'Графический баннер'),
    new CreativeForm('video', 'Видеоролик'),
    new CreativeForm('audio', 'Аудиоролик'),
    new CreativeForm('text', 'Текстовый блок'),
    new CreativeForm('textWithGraphics', 'Текстово-графический блок'),
    new CreativeForm('stream', 'Аудио/видео траснялция, прямой эфир и т.п.'),
    new CreativeForm('other', 'Прочее'),
];

export class CreativeCampaignType {
    public code: string;
    public name: string;

    constructor(code: string, name: string) {
        this.code = code;
        this.name = name;
    }
}

export const CreativeCampaignTypes: CreativeCampaignType[] = [
    new CreativeCampaignType('cpa', 'CPA - оплата за действие'),
    new CreativeCampaignType('cpc', 'CPC - оплата за переходы'),
    new CreativeCampaignType('cpm', 'CPM - оплата за тысячу показов'),
    new CreativeCampaignType('other', 'Прочее'),
];