import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { RightsRepositoryService } from 'src/app/services/repositories/rights-repository.service';
import { UserRepositoryService } from 'src/app/services/repositories/user-repository.service';
import { RightsViewModel } from 'src/app/view-models/rights/rights-view-model';
import { UserViewModel } from 'src/app/view-models/user/user-view-model';

@Component({
  selector: 'app-entity-rights',
  templateUrl: './entity-rights.component.html',
  styleUrls: ['./entity-rights.component.scss']
})
export class EntityRightsComponent implements OnInit {
  public get entityType(): string {
    return this.config.data.entityType ?? '';
  }

  public get entityId(): string {
    return this.config.data.entityId ?? '';
  }

  public isLoadingRights: boolean = false;

  private _rights: RightsViewModel[] = [];
  public get rights(): RightsViewModel[] {
    return this._rights;
  }

  private _users: UserViewModel[] = [];
  public get users(): UserViewModel[] {
    return this._users;
  }

  public grantableUsers: UserViewModel[] = [];

  public userToGrantRights: UserViewModel | null = null;

  public get canGrantToThisUser(): boolean {
    return this.userToGrantRights != null;
  }

  public isGrantingRights: boolean = false;
  public isRevokingRights: boolean = false;
  public isPatchingRights: boolean = false;

  public get isRemovingPossible() : boolean {
    switch (this.entityType) {
      default: return false;
    }
  }

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private userAPI: UserRepositoryService,
    private rightsAPI: RightsRepositoryService) { }

  ngOnInit(): void {

    this.LoadAll();
  }

  private LoadAll(): void {
    this._users = [];
    this.userAPI.ListUsers().subscribe(result => {
      this._users = result;

      this.LoadRights();
    });
  }

  private LoadRights(): void {
    this.isLoadingRights = true;

    this._rights = [];

    this.rightsAPI.ListEntityRights(this.entityType, this.entityId).subscribe(x => {
      this._rights = x;

      this.rights.forEach(rights => {
        rights.user = this.users.find(y => y.id === rights.userId) ?? null;
      });

      this.grantableUsers = [];
      this.users.forEach(user => {
        if (this.rights.findIndex(y => y.userId == user.id) < 0) {
          this.grantableUsers.push(user);
        }
      });

      this.isLoadingRights = false;
    }, error => {
      this.isLoadingRights = false;
    });
  }

  public RevokeRights(rights: RightsViewModel): void {
    this.isRevokingRights = true;
    this.rightsAPI.RevokeRights(rights).subscribe(result => {
      this.isRevokingRights = false;

      if (result) {
        if (this.grantableUsers && rights.user) {
          this.grantableUsers.push(rights.user);
        }

        const index = this.rights.indexOf(rights);

        if (index !== -1) {
          this.rights.splice(index, 1);
        }
      }
    }, error => {
      this.isRevokingRights = false;
    });
  }

  public GrantRights(): void {
    if (this.userToGrantRights === null) {
      return;
    }

    this.isGrantingRights = true;

    const rights = new RightsViewModel(this.entityType, this.entityId, this.userToGrantRights?.id ?? '');
    rights.user = this.userToGrantRights;
    
    this.rightsAPI.GrantRights(rights).subscribe(result => {
      rights.id = result;

      this.rights.push(rights);

      if (this.userToGrantRights !== null) {
        const index = this.grantableUsers.indexOf(this.userToGrantRights);

        if (index !== -1) {
          this.grantableUsers.splice(index, 1);
        }
      }

      this.userToGrantRights = null;

      this.isGrantingRights = false;
    }, error => {
      this.isGrantingRights = false;
    });
  }

  public PatchCanModify(rights: RightsViewModel) : void {
    this.isPatchingRights = true;
    this.rightsAPI.PatchCanModify(rights).subscribe(x => {
      this.isPatchingRights = false;
    }, error => {
      rights.canModify = !rights.canModify;
      this.isPatchingRights = false;
    });
  }

  public PatchCanRemove(rights: RightsViewModel) : void {
    this.isPatchingRights = true;
    this.rightsAPI.PatchCanRemove(rights).subscribe(x => {
      this.isPatchingRights = false;
    }, error => {
      rights.canRemove = !rights.canRemove;
      this.isPatchingRights = false;
    });
  }
}
