import { Injectable } from '@angular/core';
import { CounterpartyDto, CounterpartyFieldDto } from 'src/api/legal/models';
import { CounterpartyFieldViewModel, CounterpartyViewModel } from 'src/app/view-models/counterparty/counterparty-view-model';

@Injectable({
  providedIn: 'root'
})
export class CounterpartyAssemblerService {

  constructor() { }

  public ToViewModel(other: CounterpartyDto): CounterpartyViewModel {
    const model = new CounterpartyViewModel(other.type ?? '', other.name ?? '');
    model.id = other.id ?? null;
    model.isSynced = other.syncState === 'synced';
    model.isSyncing = other.syncState === 'syncing';

    model.isAcceptedByErir = other.isAcceptedByErir ?? false;
    model.erirId = other.erirId ?? '';
    
    if (other.fields) {
      other.fields.forEach(x => {
        const fieldModel = this.FieldToViewModel(x);

        model.fields.push(fieldModel);
      });
    }

    model.createdAt = other.createdAt ?? '';
    
    return model;
  }

  public ToDto(other: CounterpartyViewModel): CounterpartyDto {
    return {
      id: other.id ?? undefined,
      type: other.typeCode ?? '',
      name: other.name,
      fields: [] // TODO Fields
    };
  }

  public FieldToViewModel(other: CounterpartyFieldDto): CounterpartyFieldViewModel {
    const model = new CounterpartyFieldViewModel(other.type ?? '', other.value ?? '');
    model.id = other.id ?? null;
    
    return model;
  }

  public FieldToDto(other: CounterpartyFieldViewModel): CounterpartyFieldDto {
    return {
      id: other.id ?? undefined,
      type: other.type,
      value: other.value
    };
  }
}
