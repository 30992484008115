import { Injectable } from '@angular/core';
import { LinkDto } from 'src/api/administration/models';
import { OverallVisitAggregationViewModel } from 'src/app/view-models/link-visit/overall-visit-aggregation-view-model';
import { LinkViewModel } from 'src/app/view-models/link/link-view-model';

@Injectable({
  providedIn: 'root'
})
export class LinkAssemblerService {

  constructor() { }

  public ToViewModel(other: LinkDto): LinkViewModel {
    const model = new LinkViewModel(other?.shortcode ?? '', other?.uri ?? '');
    model.id = other.id ?? '';
    model.name = other.name ?? '';
    model.overrideUtms = other.overrideUtms ?? false;
    model.host = other.domain ?? '';

    model.marks.source = other.utm?.source ?? '';
    model.marks.campaign = other.utm?.campaign ?? '';
    model.marks.medium = other.utm?.medium ?? '';
    model.marks.term = other.utm?.term ?? '';
    model.marks.content = other.utm?.content ?? '';

    if (other.statisticsStartsFrom) {
    model.overallStats = new OverallVisitAggregationViewModel( model.id, 
      other.overallStats?.overall ?? 0,
      other.overallStats?.unique ?? 0,
      new Date(other.statisticsStartsFrom));
    }

    return model;
  }

  public ToDTO(other: LinkViewModel): LinkDto {
    return {
      id: other.id ?? undefined,
      name: other.name ?? '',
      shortcode: other?.shortcode ?? '',
      uri: other?.url ?? '',
      overrideUtms: other?.overrideUtms ?? false,
      utm: {
        source: other?.marks?.source ?? '',
        campaign: other?.marks?.campaign ?? '',
        medium: other?.marks?.medium ?? '',
        term: other?.marks?.term ?? '',
        content: other?.marks?.content ?? '',
      }
    };
  }
}
