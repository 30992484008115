<ng-container *ngIf="isEditing && !isLoadingRights && !canEdit">
    <p-messages severity="warn">
        <ng-template pTemplate>
            У вас нет прав для редактирования этой ссылки! Доступ в режиме только для чтения.
        </ng-template>
    </p-messages>
</ng-container>

<form [formGroup]="form" class="formgrid grid">
    <div class="field col-12">
        <label for="link-name">Название ссылки</label>
        <input id="link-name" formControlName="name" type="text" class="w-full" placeholder="Например ссылка на сайт" pInputText />
    </div>

    <div class="field col">
        <label for="link-url">Адрес ресурса</label>
        <input id="link-url" formControlName="url" type="text" class="w-full" placeholder="Например https://marketing-tech.ru" pInputText />
    </div>

    <div class="field col-4">
        <label for="link-overrideUTM" class="w-full mb-3">
            Подставлять UTM
        </label>
        <p-inputSwitch formControlName="overrideUTM"></p-inputSwitch>
    </div>

    <div class="mt-3 col-12">
        <div class="h5">
            UTM-метки
        </div>
    </div>

    <div class="field col-4">
        <label for="link-utm_source">
            Source
            <i class="text-small text-bare fa-solid fa-circle-question" pTooltip="Источник перехода"></i>
        </label>
        <input id="link-utm_source" formControlName="utmSource" type="text" class="w-full" placeholder="Название рекламной площадки" pInputText />
        <div class="mt-2 text-bare text-small">
            Рекомендуем указывать <strong>marketing-tech</strong>
        </div>
    </div>

    <div class="field col-4">
        <label for="link-utm_campaign">
            Campaign
            <i class="text-small text-bare fa-solid fa-circle-question" pTooltip="Название рекламной кампании"></i>
        </label>
        <input id="link-utm_campaign" formControlName="utmCampaign" type="text" class="w-full" placeholder="Название рекламной кампании" pInputText />
        <div class="mt-2 text-bare text-small">
            Название вашей рекламной кампании латиницей без пробелов.
        </div>
    </div>

    <div class="field col-4">
        <label for="link-utm_medium">
            Medium
            <i class="text-small text-bare fa-solid fa-circle-question" pTooltip="Рекламная модель"></i>
        </label>
        <input id="link-utm_medium" formControlName="utmMedium" type="text" class="w-full" placeholder="Тип рекламы, например cpc" pInputText />
        <div class="mt-2 text-bare text-small">
            Рекомендуем один из вариантов: <strong>organic</strong>, cpa, cpc, cpm, social, banner
        </div>
    </div>

    <div class="field col-4">
        <label for="link-utm_term">
            Term
            <i class="text-small text-bare fa-solid fa-circle-question" pTooltip="Ключевая фраза"></i>
        </label>
        <input id="link-utm_term" formControlName="utmTerm" type="text" class="w-full" placeholder="Ключевая фраза" pInputText />
        <div class="mt-2 text-bare text-small">
            Ключевая фраза латиницей без пробелов.
        </div>
    </div>

    <div class="field col-4">
        <label for="link-utm_content">
            Content
            <i class="text-small text-bare fa-solid fa-circle-question" pTooltip="Конкретный элемент контента"></i>
        </label>
        <input id="link-utm_content" formControlName="utmContent" type="text" class="w-full" placeholder="Идентификатор объявления" pInputText />
        <div class="mt-2 text-bare text-small">
            <strong>{{ "{{page_url}}" }}</strong> - адрес страницы, с которой производится переход.<br>
            <strong>{{ "{{hub_source}}" }}</strong> - адрес последнего хаба, с которого пришёл пользователь.
        </div>
    </div>

    <div class="mt-5 field col-12 flex align-items-center">
        <div *ngIf="!isEditing" class="mr-3">
            <p-message severity="warn" text="Создавайте разные ссылки для карточки компании и рекламного баннера."></p-message>
        </div>

        <div class="ml-auto">
            <button pButton (click)="Cancel()" type="button" label="Отменить" class="p-button-text "></button>
        </div>

        <div class="ml-3">
            <button pButton (click)="Confirm()" type="button" [label]="buttonText"  [disabled]="isEditing && !canEdit"></button>
        </div>
        
        
    </div>
</form>