import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { CounterpartyApiService } from 'src/app/services/api/counterparty-api.service';
import { CounterpartyFieldViewModel, CounterpartyTypes, CounterpartyTypeViewModel, CounterpartyViewModel } from 'src/app/view-models/counterparty/counterparty-view-model';

@Component({
  selector: 'app-counterparty-dialogue',
  templateUrl: './counterparty-dialogue.component.html',
  styleUrls: ['./counterparty-dialogue.component.scss']
})
export class CounterpartyDialogueComponent implements OnInit {
  public isBusy: boolean = false;

  public form: FormGroup;

  public isCreateMode: boolean = false;
  private _values: CounterpartyViewModel | null;

  public get counterpartyTypes(): CounterpartyTypeViewModel[] {
    return CounterpartyTypes;
  }

  public get useOgrnipInsteadOfOgrn(): boolean {
    const values = this.form.getRawValue();
    return values.type === 'individualEntrepreneur';
  }

  public get isInvalid(): boolean {
    return !this.form.valid;
  }

  constructor(private api: CounterpartyApiService, public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
    this._values = this.config.data ?? null;
    this.isCreateMode = (!this.config.data?.id) ?? false;

    this.form = new FormGroup({
      id: new FormControl({
        value: this._values?.id ?? null,
        disabled: false
      }),
      name: new FormControl({
        value: this._values?.name,
        disabled: false
      }, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(512)
      ]),
      type: new FormControl({
        value: this._values?.typeCode,
        disabled: !this.isCreateMode
      }, [
        Validators.required,
      ]),
      inn: new FormControl({
        value: this._values?.getField('inn')?.value,
        disabled: false
      }, [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(12)
      ]),
      ogrn: new FormControl({
        value: this._values?.getField('ogrn')?.value,
        disabled: false
      }, [
        Validators.minLength(10),
        Validators.maxLength(13)
      ]),
      ogrnip: new FormControl({
        value: this._values?.getField('ogrnip')?.value,
        disabled: false
      }, [
        Validators.minLength(10),
        Validators.maxLength(16)
      ]),
      kpp: new FormControl({
        value: this._values?.getField('kpp')?.value,
        disabled: false
      }, [
        Validators.maxLength(9)
      ]),
    });
  }

  ngOnInit(): void {
  }

  public Submit(): void {
    if (!this.form.valid) {
      return;
    }

    const model = this.GenerateModel();

    this.isBusy = true;

    if (model.id) {
      this.UpdateEntity(model.id, model);
    } else {
      this.CreateEntity(model);
    }
  }

  private GenerateModel(): CounterpartyViewModel {
    const values = this.form.getRawValue();

    const model = new CounterpartyViewModel(values.type, values.name);
    model.id = values.id ?? null;

    if (this._values?.fields) {
      model.fields = this._values.fields;
    }


    // 
    if (values.inn) {
      let inn = model.getField('inn');

      if (!inn) {
        inn = new CounterpartyFieldViewModel('inn', values.inn);
      } else {
        inn.updateValue(values.inn);
      }

      model.upsertField(inn);
    }

    if (this.useOgrnipInsteadOfOgrn) {
      if (values.ogrnip) {
        let ogrnip = model.getField('ogrnip');

        if (!ogrnip) {
          ogrnip = new CounterpartyFieldViewModel('ogrnip', values.ogrnip);
        } else {
          ogrnip.updateValue(values.ogrnip);
        }

        model.upsertField(ogrnip);
      }
    } else {
      if (values.ogrn) {
        let ogrn = model.getField('ogrn');

        if (!ogrn) {
          ogrn = new CounterpartyFieldViewModel('ogrn', values.ogrn);
        } else {
          ogrn.updateValue(values.ogrn);
        }

        model.upsertField(ogrn);
      }
      
    }

    if (values.kpp) {
      let kpp = model.getField('kpp');

      if (!kpp) {
        kpp = new CounterpartyFieldViewModel('kpp', values.kpp);
      } else {
        kpp.updateValue(values.kpp);
      }

      model.upsertField(kpp);
    }
    //

    console.log('generated', model);

    return model;
  }

  private CreateEntity(model: CounterpartyViewModel): void {
    this.api.Create(model).subscribe(id => {

      this.SyncFields(id, model).subscribe(x => {
        this.isBusy = false;
        this.ref.close();
      });
    });
  }

  private UpdateEntity(id: string, model: CounterpartyViewModel): void {
    this.api.Update(id, model).subscribe(x => {

      this.SyncFields(id, model).subscribe(x => {
        this.isBusy = false;
        this.ref.close();
      });
    });
  }

  private SyncFields(id: string, model: CounterpartyViewModel): Observable<boolean> {
    const fieldsToCreate = model.fields.filter(x => x.id === null);
    const fieldsToUpdate = model.fields.filter(x => x.isSynced === false);

    return this.api.CreateFields(id, fieldsToCreate).pipe(
      mergeMap(() => this.api.UpdateFields(id, fieldsToUpdate))
    );
  }
}
