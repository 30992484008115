import { Injectable } from '@angular/core';
import { EconomicClassificationDto } from 'src/api/legal/models';
import { EconomicClassification } from 'src/app/view-models/economic-classification/economic-classification';

@Injectable({
  providedIn: 'root'
})
export class EconomicClassificationAssemblerService {

  constructor() { }

  public ToViewModel(other: EconomicClassificationDto): EconomicClassification {
    const model = new EconomicClassification(other.name ?? '', other.value ?? '');

    model.id = other.id ?? '';
    model.parentId = other.id ?? '';

    return model;
  }
}
