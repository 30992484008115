import { Injectable } from '@angular/core';
import { LeadFormService } from 'src/api/leads/services';
import { LeadFormAssemblerService } from '../assemblers/lead-form-assembler.service';
import { Observable, of } from 'rxjs';
import { LeadForm, LeadFormField } from 'src/app/view-models/leads/lead-form';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LeadFormApiService {

  constructor(private _api: LeadFormService, private _assembler: LeadFormAssemblerService) { }

  public list(page: number = 1, pageSize: number = 10): Observable<LeadForm[]> {
    return this._api.listLeadForms({
      Page: page,
      PageSize: pageSize
    }).pipe(
      map(response => {
        return response.map(x => this._assembler.toViewModel(x));
      })
    );
  }

  public getById(id: string): Observable<LeadForm|null> {
    return this._api.getLeadForm({
      LeadFormId: id
    }).pipe(
      map(response => {
        return response ? this._assembler.toViewModel(response) : null;
      })
    );
  }

  public edit(id: string, leadForm: LeadForm): Observable<void> {
    return this._api.updateLeadForm({
      LeadFormId: id,
      body: this._assembler.toDto(leadForm)
    });
  }

  public create(leadForm: LeadForm): Observable<string> {
    return this._api.createLeadForm({
      body: this._assembler.toDto(leadForm)
    });
  }

  public addField(id: string, field: LeadFormField): Observable<string> {
    return this._api.addLeadFormFields({
      LeadFormId: id,
      body: this._assembler.toFieldDto(field)
    })
  }

  public removeFields(id: string, fields: LeadFormField[]): Observable<void> {
    if (!fields || fields.length === 0) {
      return of();
    }

    return this._api.removeLeadFormFields({
      LeadFormId: id,
      body: fields.filter(x => x && x.id !== null).map(x => x.id ?? '')
    })
  }

  public removeField(id: string, field: LeadFormField): Observable<void> {
    return this.removeFields(id, [field]);
  }

  public updateField(id: string, fieldId: string, field: LeadFormField): Observable<void> {
    return this._api.updateLeadFormField({
      LeadFormId: id,
      LeadFormFieldId: fieldId,
      body: this._assembler.toFieldDto(field)
    })
  }
}
