import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, of, zip } from 'rxjs';
import { delay, switchMap, tap } from 'rxjs/operators';
import { IntegrationPlatformsService } from 'src/app/services/api/integration-platforms.service';
import { IntegrationsService } from 'src/app/services/api/integrations.service';
import { Integration, IntegrationMaping } from 'src/app/view-models/integrations/integration';
import { IntegrationPlatform, IntegrationPlatformObject, IntegrationPlatformObjectField } from 'src/app/view-models/integrations/integration-platform';

@Component({
  selector: 'app-integration-dialog',
  templateUrl: './integration-dialog.component.html',
  styleUrls: ['./integration-dialog.component.scss']
})
export class IntegrationDialogComponent implements OnInit {
  public form: FormGroup;
  private _values: Integration | null = null;
  public isCreateMode: boolean;
  public isBusy: boolean = false;

  public platforms: IntegrationPlatform[] = [];

  public sourceObjects: IntegrationPlatformObject[] = [];
  public destinationObjects: IntegrationPlatformObject[] = [];

  public fieldForms: FormGroup[] = [];

  public get sourceFields(): IntegrationPlatformObjectField[] {
    const object: IntegrationPlatformObject | null = this.form.get('sourceObject')?.value ?? null;

    if (!object) {
      return [];
    }

    return object.fields;
  }

  public get destinationFields(): IntegrationPlatformObjectField[] {
    const object: IntegrationPlatformObject | null = this.form.get('destinationObject')?.value ?? null;

    if (!object) {
      return [];
    }

    return object.fields;
  }

  public get isInvalid(): boolean {
    return !this.form.valid || this.fieldForms.findIndex(x => !x.valid) >= 0;// || (!this.isCreateMode && !this.hasValidItems);
  }

  constructor(private _integrationPlatform: IntegrationPlatformsService,
    private _integrationApi: IntegrationsService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
    this._values = this.config.data ?? null;
    this.isCreateMode = !this._values?.id;


    this.form = new FormGroup({
      id: new FormControl<string | null>(this._values?.id ?? null),
      name: new FormControl<string>(this._values?.name ?? 'Новая интеграция', [Validators.required]),
      source: new FormControl<IntegrationPlatform | null>(null),
      sourceObject: new FormControl<IntegrationPlatformObject | null>(this._values?.sourceObject ?? null, [Validators.required]),
      sourceEntityId: new FormControl<string | null>(this._values?.sourceEntityId ?? null),
      destination: new FormControl<IntegrationPlatform | null>(this._values?.destination ?? null),
      destinationObject: new FormControl<IntegrationPlatformObject | null>(this._values?.destinationObject ?? null, [Validators.required]),
    });

    this.form.get('source')?.valueChanges.subscribe((value: IntegrationPlatform | null) => {
      this.form.get('sourceObject')?.setValue(null);
      this.sourceObjects = value?.objects ?? [];
    });

    this.form.get('destination')?.valueChanges.subscribe((value: IntegrationPlatform | null) => {
      this.form.get('destinationObject')?.setValue(null);
      this.destinationObjects = value?.objects ?? [];
    });

    this._values?.mapping.forEach(mapping => {
      this.addField(mapping);
    });
  }

  ngOnInit(): void {
    this._integrationPlatform.list().subscribe(response => {
      this.platforms = response;

      if (this._values) {
        // Источник
        if (this._values.source) {
          this.form.get('source')?.setValue(this._values.source);
          this.form.get('source')?.disable();

          this.form.get('sourceObject')?.setValue(this._values.sourceObject);
          this.form.get('sourceObject')?.disable();
        }

        // Цель
        if (this._values.destination) {
          this.form.get('destination')?.setValue(this._values.destination);
          this.form.get('destination')?.disable();

          this.form.get('destinationObject')?.setValue(this._values.destinationObject);
          this.form.get('destinationObject')?.disable();
        }
      }
    });
  }

  public addField(mapping: IntegrationMaping | null = null): void {
    const fieldForm = new FormGroup({
      id: new FormControl<string | null>(mapping?.id ?? null),
      sourceField: new FormControl<IntegrationPlatformObjectField | null>(null),
      sourceFieldId: new FormControl<string>(mapping?.sourceFieldId ?? '', [Validators.required]),
      destinationField: new FormControl<IntegrationPlatformObjectField | null>(null),
      destinationFieldId: new FormControl<string>(mapping?.destinationFieldId ?? '', [Validators.required]),
      format: new FormControl<string>(mapping?.format ?? ''),
      subtype: new FormControl<string>(mapping?.subtype ?? ''),
      hasRemoveFlag: new FormControl<boolean>(mapping?.hasRemoveFlag ?? false)
    });

    if (mapping) {
      if (mapping.sourceFieldId) {
        const object: IntegrationPlatformObject | null = this.form.get('sourceObject')?.value;

        fieldForm.get('sourceField')?.setValue(object?.fields.find(x => x.codename === mapping.sourceFieldId) ?? null);
      }

      if (mapping.destinationFieldId) {
        const object: IntegrationPlatformObject | null = this.form.get('destinationObject')?.value;

        fieldForm.get('destinationField')?.setValue(object?.fields.find(x => x.codename === mapping.destinationFieldId) ?? null);
      }
    }

    fieldForm.get('sourceField')?.valueChanges.subscribe((value: IntegrationPlatformObjectField | null) => {
      fieldForm.get('sourceFieldId')?.setValue(value?.codename ?? '');
    });

    fieldForm.get('destinationField')?.valueChanges.subscribe((value: IntegrationPlatformObjectField | null) => {
      fieldForm.get('destinationFieldId')?.setValue(value?.codename ?? '');
    });

    this.fieldForms.push(fieldForm);
  }

  public submit(): void {
    Object.keys(this.form.controls).forEach(key => {
      // Get errors of every form control
      console.log(this.form.get(key)?.errors);
    });

    if (!this.form.valid) {
      return;
    }

    console.log('this.isCreateMode', this.isCreateMode);

    if (this.isCreateMode) {
      this.createIntegration();
    } else {
      this.updateIntegration();
    }
  }

  private updateIntegration(): void {
    const model = this.generateIntegration();
    const requests: Observable<any>[] = [];

    if (model.id) {
      const id = model.id;

      if (this.form.dirty) {
        requests.push(this._integrationApi.update(id, model));
      }

      // Создание маппингов
      model.mapping.filter(x => !x.id && !x.hasRemoveFlag).forEach(mapping => {
        console.log('Create mapping', mapping);
        requests.push(this._integrationApi.addMapping(id, mapping));
      });

      // Редактирование маппингов
      model.mapping.filter(x => x.id && x.isDirty && !x.hasRemoveFlag).forEach(mapping => {
        console.log('UPDATE mapping', mapping);
        if (mapping.id) {
          requests.push(this._integrationApi.updateMapping(id, mapping.id, mapping));
        }
      });

      // Удаление маппингов
      model.mapping.filter(x => x.id && x.hasRemoveFlag).forEach(mapping => {
        console.log('Remove mapping', mapping);
        if (mapping.id) {
          requests.push(this._integrationApi.removeMapping(id, mapping.id));
        }
      });
    }

    console.log('Update hook requests', requests);

    if (requests.length > 0) {
      zip(...requests).subscribe(result => {
        this.ref.close(model);
      });
    } else {
      this.ref.close(model);
    }
  }

  public isRemoved(mapping: FormGroup): boolean {
    return mapping.controls['hasRemoveFlag'].value;
  }

  public removeField(mapping: FormGroup): void {
    this.updateRemovalState(mapping, true);
  }

  public restoreField(mapping: FormGroup): void {
    this.updateRemovalState(mapping, false);
  }

  private updateRemovalState(fieldForm: FormGroup, shouldRemove: boolean): void {
    fieldForm.controls['hasRemoveFlag'].setValue(shouldRemove);

    for (let key in fieldForm.controls) {
      if (key === 'hasRemoveFlag')
        continue;

      if (shouldRemove)
        fieldForm.controls[key].disable();
      else
        fieldForm.controls[key].enable();
    }
  }

  private createIntegration(): void {
    const model = this.generateIntegration();

    this._integrationApi.create(model).pipe(
      tap(response => {
        model.id = response;
      }),
      switchMap(id => {
        const requests: Observable<any>[] = [];

        model.mapping.forEach(mapping => {
          if (!mapping.hasRemoveFlag) {
            requests.push(this._integrationApi.addMapping(id, mapping));
          }
        });

        return zip(...requests);
      })
    ).subscribe(result => {
      this.ref.close(model);
    });
  }

  private generateIntegration(): Integration {
    const values = this.form.getRawValue();

    const model = new Integration(values.sourceObject?.id ?? '', values.sourceEntityId ?? null);
    model.destinationObjectId = values.destinationObject?.id ?? null;
    model.id = values.id ?? null;
    model.name = values.name ?? null;

    // ToDo Сконвертировать каждое поле
    this.fieldForms.forEach(fieldForm => {
      const fieldValues = fieldForm.getRawValue();

      const fieldModel = new IntegrationMaping(fieldValues.sourceFieldId ?? '', fieldValues.destinationFieldId ?? '');
      fieldModel.id = fieldValues.id ?? null;
      fieldModel.format = fieldValues.format ?? '';
      fieldModel.subtype = fieldValues.subtype ?? '';
      fieldModel.isDirty = fieldForm.dirty;
      fieldModel.hasRemoveFlag = fieldValues.hasRemoveFlag;

      model.addMapping(fieldModel);
    });

    return model;
  }
}
