import { Component, OnInit } from '@angular/core';
import { ConfirmationService, LazyLoadEvent } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { CounterpartySearchQuery } from 'src/app/components/search-forms/counterparty-search-form/counterparty-search-form.component';
import { CounterpartyDialogueComponent } from 'src/app/dialogues/counterparty-dialogue/counterparty-dialogue.component';
import { CounterpartyApiService } from 'src/app/services/api/counterparty-api.service';
import { CounterpartyViewModel } from 'src/app/view-models/counterparty/counterparty-view-model';

@Component({
  selector: 'app-counterparty-list',
  templateUrl: './counterparty-list.component.html',
  styleUrls: ['./counterparty-list.component.scss'],
  providers: [ConfirmationService]
})
export class CounterpartyListComponent implements OnInit {
  public get isLoading(): boolean {
    return this.api.isLoading;
  }

  public get items(): CounterpartyViewModel[] {
    return this.api.items;
  }

  public get itemsTotalCount(): number {
    return this.api.itemsTotalCount;
  }

  public pageIndex: number = 1;
  public pageSize: number = 10;
  private _previousEvent: LazyLoadEvent | null = null;

  public createdAt: string = '';

  private _query: CounterpartySearchQuery | null = null;

  constructor(private api: CounterpartyApiService, 
    private dialogService: DialogService,
    
    private confirmationService: ConfirmationService) { }

  ngOnInit(): void {

  }

  public OnLazyLoad(event: LazyLoadEvent | null) {
    const current = event ?? this._previousEvent;

    if (event) {
      this._previousEvent = event;
    }

    this.pageSize = current?.rows ?? 10;
    this.pageIndex = (current?.first) ? (current.first / this.pageSize) + 1 : 1;

    this.LoadCurrentPage();
  }

  public QueryChanged(query: CounterpartySearchQuery): void {
    this._query = query;

    this.LoadCurrentPage();
  }

  public CreateItemClicked(): void {
    const ref = this.dialogService.open(CounterpartyDialogueComponent, {
      header: 'Создать контрагента',
      width: '60%'
    });

    ref.onClose.subscribe((data: CounterpartyViewModel) => {
      this.LoadCurrentPage();
    });
  }

  public UpdateItemClicked(item: CounterpartyViewModel): void {
    const ref = this.dialogService.open(CounterpartyDialogueComponent, {
      header: 'Обновить контрагента',
      width: '60%',
      data: item
    });

    ref.onClose.subscribe((data: CounterpartyViewModel) => {
      this.LoadCurrentPage();
    });
  }

  public SyncClick(model: CounterpartyViewModel): void {
    if (!model || !model.id) {
      return;
    }

    this.confirmationService.confirm({
      header: 'Подтверждение',
      message: 'Вы действительно хотите передать данные в ОРД? Действие нельзя будет отменить.',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        if (model && model.id) {
          this.api.Sync(model.id).subscribe(x => {
            model.isSyncing = true;
          });
        }
      }
    });
  }

  private LoadCurrentPage(): void {
    this.api.Load(this.pageIndex, this.pageSize,
      this._query?.searchTerm,
      this._query?.inn,
      this._query?.ogrn,
      this._query?.selectedTypes,
      this._query?.synState,
      this._query?.acceptedByErir).subscribe();
  }
}
