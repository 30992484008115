<ng-container *ngIf="isLoading; else loaded">
    <p-skeleton width="50rem" height="2rem"></p-skeleton>
</ng-container>
<ng-template #loaded>
    <ng-container *ngIf="leadForm; else noData">
        <h1>
            <span class="text-color-secondary">Лид-форма: </span>
            <span class="">{{leadForm.name}}</span>
        </h1>


        <div class="mt-5">
            <div class="grid">
                <div class="col-12 sm:col-10">
                    <div class="flex align-items-center h-full">
                        Здесь отображается список всех лидов этой формы.
                    </div>
                </div>

                <div class="col-12 sm:col-2">
                    <button pButton type="button" label="Назад" class="w-full" (click)="goBackClicked()"></button>
                </div>
            </div>
        </div>

        <div class="mt-3 fancy-card">
            <div class="p-3 flex">
                <h2 class="h4 font-light">Статистика</h2>
            </div>

            <ng-container *ngIf="isLoadingLeadStats; else statsLoaded">
                <p-skeleton width="100%" height="15rem"></p-skeleton>
            </ng-container>
            <ng-template #statsLoaded>
                <p-chart type="bar" [data]="basicData" [options]="basicOptions" height="15rem"></p-chart>
            </ng-template>
        </div>


        <div class="mt-3 fancy-card">
            <div class="p-3 flex align-items-center justify-content-between">
                <h2 class="h4 font-light">Интеграции</h2>

                <button pButton class="p-button-secondary p-button-text p-button-sm" type="button" label="Создать"
                    (click)="createIntegration()"></button>
            </div>

            <p-table [value]="integrations" [loading]="isLoadingIntegrations"
                styleClass="w-full p-datatable-gridlines p-datatable-striped">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th style="width:64px">
                            
                        </th>

                        <th>
                            Название
                        </th>
                        <th>
                            Статус
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr>
                        <td>
                            <button pButton class="p-button-secondary p-button-text p-button-sm"
                                icon="fa-solid fa-pen-to-square" (click)="updateIntegration(rowData)"
                                pTooltip="Редактировать интеграцию"></button>
                        </td>

                        <td>
                            {{rowData.name}}
                        </td>

                        <td>
                            {{rowData.status.name}}
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="3">
                            <div class="w-full flex align-items-center justify-content-center text-center">
                                <h3 class="h5 font-light">
                                    Нет интеграций
                                </h3>
                            </div>
                        </td>
                    </tr>

                </ng-template>
            </p-table>
        </div>



        <div class="mt-3 fancy-card opacity-50">
            <div class="p-3">
                ToDo Фильтр
            </div>
        </div>

        <div class="mt-3 fancy-card">
            <div class="p-3 flex">
                <h2 class="h4 font-light">Лиды</h2>
            </div>

            <p-table [columns]="leadForm.visibleFields" [value]="leads" [loading]="isLoadingLeads"
                [resizableColumns]="true" [scrollable]="true" scrollDirection="horizontal" columnResizeMode="expand"
                styleClass="w-full p-datatable-gridlines p-datatable-striped">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th pResizableColumn>
                            Получено
                        </th>
                        <th *ngFor="let col of columns" pResizableColumn>
                            {{col.name}}
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr>
                        <td>
                            <div class="flex flex-column">
                                <div class="text-small text-color-secondary">{{rowData.createdAtTime}}</div>
                                <div>{{rowData.createdAtDate}}</div>

                                <div *ngIf="rowData.referer" class="text-small text-color-secondary">
                                    <a [attr.href]="rowData.referer" target="_blank" rel="nofollow noreferer"
                                        class="mr-2">Отправлено с</a>
                                    <i class="fa-regular fa-circle-question"
                                        pTooltip="Веб-адрес, с которого была отправлен лид"></i>


                                </div>
                            </div>


                        </td>

                        <td *ngFor="let col of columns">
                            <app-lead-field-values [field]="rowData.fieldMap[col.mapId]"></app-lead-field-values>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </ng-container>
    <ng-template #noData>
        <h1 class="text-color-secondary">Лид-форма не найдена</h1>
        <p class="text-color-secondary">
            Не удалось найти лид-форму с таким ID.
        </p>
    </ng-template>
</ng-template>