export class IntegrationPlatform {
    public id: string | null = null;
    public codename: string;
    public name: string;

    private _objects: IntegrationPlatformObject[] = [];
    public get objects(): IntegrationPlatformObject[] {
        return this._objects;
    }

    constructor(codename: string, name: string) {
        this.codename = codename;
        this.name = name;
    }

    public addObject(object: IntegrationPlatformObject): void {
        if (this._objects.findIndex(x => x.codename === object.codename) >= 0) {
            return;
        }

        this._objects.push(object);
    }

    public findObjectByCodename(codename: string): IntegrationPlatformObject | null {
        return this._objects.find(x => x.codename === codename) ?? null;
    }

    public findObjectById(id: string): IntegrationPlatformObject | null {
        return this._objects.find(x => x.id === id) ?? null;
    }

    public hasObjectWithId(id: string): boolean {
        return this._objects.findIndex(x => x.id === id) >= 0;
    }
}

export class IntegrationPlatformObject {
    public id: string | null = null;
    public codename: string;
    public name: string;

    public fields: IntegrationPlatformObjectField[] = [];

    constructor(codename: string, name: string) {
        this.codename = codename;
        this.name = name;
    }
}

export class IntegrationPlatformObjectField {
    public codename: string;
    public name: string;

    constructor(codename: string, name: string) {
        this.codename = codename;
        this.name = name;
    }
}