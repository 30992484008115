import { Component, OnInit } from '@angular/core';
import { ConfirmationService, LazyLoadEvent } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { ContractSearchQuery } from 'src/app/components/search-forms/contract-search-form/contract-search-form.component';
import { ContractDialogueComponent } from 'src/app/dialogues/contract-dialogue/contract-dialogue.component';
import { ContractStatisticsComponent } from 'src/app/dialogues/contract-statistics/contract-statistics.component';
import { ContractApiService } from 'src/app/services/api/contract-api.service';
import { CounterpartyApiService } from 'src/app/services/api/counterparty-api.service';
import { InvoiceApiService } from 'src/app/services/api/invoice-api.service';
import { ContractViewModel } from 'src/app/view-models/contract/contract-view-model';
import { CounterpartyViewModel } from 'src/app/view-models/counterparty/counterparty-view-model';
import { InvoiceViewModel } from 'src/app/view-models/invoice/invoice-view-model';

@Component({
  selector: 'app-contract-list',
  templateUrl: './contract-list.component.html',
  styleUrls: ['./contract-list.component.scss'],
  providers: [ConfirmationService]
})
export class ContractListComponent implements OnInit {

  public get isLoading(): boolean {
    return this.api.isLoading;
  }

  private _contracts: ContractViewModel[] = [];

  public get items(): ContractViewModel[] {
    return this._contracts;
  }

  public get itemsTotalCount(): number {
    return this.api.itemsTotalCount;
  }

  public pageIndex: number = 1;
  public pageSize: number = 10;
  private _previousEvent: LazyLoadEvent | null = null;

  private _counterparties: CounterpartyViewModel[] = [];

  private _query: ContractSearchQuery | null = null;

  constructor(private api: ContractApiService,
    private counterpartyApi: CounterpartyApiService,
    private invoiceApi: InvoiceApiService,
    private dialogService: DialogService,

    private confirmationService: ConfirmationService) { }
  private _fetchedCounterpartyIds: string[] = [];

  ngOnInit(): void {
    // this.counterpartyApi.Load(1, 500).subscribe(x => {
    //   this.AssignCounterparties();
    // });
  }

  public QueryChanged(query: ContractSearchQuery): void {
    this._query = query;

    this.LoadCurrentPage();
  }

  public OnLazyLoad(event: LazyLoadEvent | null) {
    const current = event ?? this._previousEvent;

    if (event) {
      this._previousEvent = event;
    }

    this.pageSize = current?.rows ?? 10;
    this.pageIndex = (current?.first) ? (current.first / this.pageSize) + 1 : 1;

    this.LoadCurrentPage();
  }

  public CreateItemClicked(): void {
    const ref = this.dialogService.open(ContractDialogueComponent, {
      header: 'Создать договор',
      width: '60%'
    });

    ref.onClose.subscribe((result: boolean) => {
      this.LoadCurrentPage();
    });
  }

  public UpdateItemClicked(item: ContractViewModel): void {
    const ref = this.dialogService.open(ContractDialogueComponent, {
      header: 'Обновить договор',
      width: '60%',
      data: item
    });

    ref.onClose.subscribe((result: boolean) => {
      if (result) {
        this.LoadCurrentPage();
      }
    });
  }

  public SyncClick(model: ContractViewModel): void {
    if (!model || !model.id) {
      return;
    }

    

      this.confirmationService.confirm({
        header: 'Подтверждение',
        message: 'Вы действительно хотите передать данные в ОРД? Действие нельзя будет отменить.',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          if (model && model.id) {
            this.api.Sync(model.id).subscribe(x => {
              model.isSyncing = true;
            });
          }
        }
      });
  }

  public ViewStatistics(entity: ContractViewModel): void {
    const ref = this.dialogService.open(ContractStatisticsComponent, {
      header: 'Статистика',
      width: '80%',
      data: entity
    });

    ref.onClose.subscribe(() => {
      //this.LoadCurrentPage();
    });
  }

  private LoadCurrentPage(): void {
    this.api.Load(this.pageIndex, this.pageSize,
      this._query?.types,
      this._query?.subjects,
      this._query?.clientId, this._query?.parentContractId,
      this._query?.dateOfConclusionBefore,
      this._query?.dateOfConclusionAfter,
      this._query?.synState,
      this._query?.acceptedByErir).subscribe(x => {

        this._contracts = x;

        this.AssignCounterparties();
        this.AssignInvoices();
      });
  }

  private AssignInvoices(): void {
    if (this._contracts.length === 0) {
      return;
    }

    const ids = this._contracts.filter(x => x.id).map(x => x.id ?? '') ?? [];

    this.invoiceApi.LoadByContracts(ids).subscribe(response => {
      console.log(response);

      response.forEach(invoice => {
        const contract = this._contracts.find(x => x.id == invoice.contractId);

        if (contract) {
          contract.latestInvoice = invoice;
        }
      })
    });
  }

  private AssignCounterparties(): void {
    if (this._contracts.length === 0) {
      return;
    }

    const missingCounterpartyIds: string[] = [];

    this._contracts.forEach(item => {
      item.contractor = this._counterparties.find(x => x.id === item.contractorCounterpartyId) ?? null;
      item.client = this._counterparties.find(x => x.id === item.clientCounterpartyId) ?? null;

      if (item.client === null && missingCounterpartyIds.indexOf(item.clientCounterpartyId) < 0) {

        if (this._fetchedCounterpartyIds.indexOf(item.clientCounterpartyId) < 0) {
          missingCounterpartyIds.push(item.clientCounterpartyId);
          this._fetchedCounterpartyIds.push(item.clientCounterpartyId);
        } else {
          console.warn(`Counterparty with ID ${item.clientCounterpartyId} was already fetched. Ye it somehow not found`, this._counterparties);
        }
      }
    });

    if (missingCounterpartyIds.length > 0) {
      console.log(`Fetching counterparties`, missingCounterpartyIds);
      this.counterpartyApi.GetByIds(missingCounterpartyIds).subscribe(result => {

        result.forEach(counterparty => {
          if (this._counterparties.findIndex(x => x.id === counterparty.id) < 0) {
            this._counterparties.push(counterparty);
          }
        });

        console.log('Fetched', this._counterparties);
        this.AssignCounterparties();
      });
    }
  }
}
