import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LinkVisitAggregation, OveralLinksVisit } from 'src/api/aggregations/models';
import { LinkVisitService } from 'src/api/aggregations/services';
import { LinkPageVisitAggregation } from 'src/app/view-models/link-visit/link-page-visit-aggregation-view-model';
import { LinkVisitAggregation as LinkVisitAggregationViewModel } from 'src/app/view-models/link-visit/link-visit-aggregation-view-model';
import { OverallVisitAggregationViewModel } from 'src/app/view-models/link-visit/overall-visit-aggregation-view-model';

@Injectable({
  providedIn: 'root'
})
export class LinkVisitRepositoryService {

  constructor(private linkVisitAPI: LinkVisitService) { }

  public GetLinkVisits(linkId: string, dateFrom: Date | null = null, dateTo: Date | null = null): Observable<LinkVisitAggregationViewModel[]> {
    return this.linkVisitAPI.getLinkVisitAggregated({
      linkId: linkId,
      dateFrom: this.GetFormattedDate(dateFrom),
      dateTo: this.GetFormattedDate(dateTo)
    }).pipe(
      map(x =>  {
        return x.map(y => this.ConvertLinkVisit(y));
      })
    );
  }

  public GetLinkPageVisits(linkId: string, dateFrom: Date | null = null, dateTo: Date | null = null): Observable<LinkPageVisitAggregation[]> {
    return this.linkVisitAPI.getPageLinkVisitAggregated({
      linkId: linkId,
      dateFrom: this.GetFormattedDate(dateFrom),
      dateTo: this.GetFormattedDate(dateTo)
    }).pipe(
      map(x =>  {
        return x.map(y => new LinkPageVisitAggregation(y.path ?? '', y.visits?.map(z => this.ConvertLinkVisit(z)) ?? []));
      })
    );
  }

  public GetOverallLinksVisits(linkIds: string[]): Observable<OverallVisitAggregationViewModel[]> {
    return this.linkVisitAPI.getOverallLinkVisitAggregated({
      linkIds: linkIds
    }).pipe(
      map(x =>  {
        return x.map(y => this.ConvertOveralLinkVisit(y));
      })
    );
  }

  public GetOverallLinkVisits(linkId: string): Observable<OverallVisitAggregationViewModel> {
    return this.linkVisitAPI.getOverallLinkVisitAggregated({
      linkIds: [linkId]
    }).pipe(
      map(x =>  {
        return this.ConvertOveralLinkVisit(x[0]);
      })
    );
  }

  private ConvertLinkVisit(dto: LinkVisitAggregation): LinkVisitAggregationViewModel {
    return new LinkVisitAggregationViewModel(new Date(dto?.date ?? ''), dto?.overallVisits ?? 0, dto?.uniqueVisits ?? 0)
  }

  private ConvertOveralLinkVisit(visit: OveralLinksVisit): OverallVisitAggregationViewModel {
    return new OverallVisitAggregationViewModel(visit?.linkId ?? '', visit?.overallVisits ?? 0, visit?.uniqueVisits ?? 0, new Date(visit?.since ?? ''));
  }

  private GetFormattedDate(date: Date | null): string | undefined {
    if (!date) {
      return undefined;
    }

    return date.getFullYear() + '-' + 
    ('0' + (date.getMonth()+1)).slice(-2) + '-' + 
    ('0' + date.getDate()).slice(-2);
  }
}
