import { OverallVisitAggregationViewModel } from "../link-visit/overall-visit-aggregation-view-model";


export class LinkViewModel {
    public id: string | null = null;
    public name: string = '';
    public shortcode: string;
    private _uri: string;
    public host: string = '';

    // public get host(): string {
    //     return decodeURI(this._uri.host);
    // }

    public get url(): string {
        return this._uri;
    }

    public overrideUtms: boolean = false;
    public marks: LinkUTMViewModel;

    public overallStats: OverallVisitAggregationViewModel | null = null;

    constructor(shortcode: string, uri: string) {
        this.shortcode = shortcode;
        this._uri = uri;
        this.marks = new LinkUTMViewModel();
    }
}

export class LinkUTMViewModel {
    public source: string = '';
    public campaign: string = '';
    public medium: string = '';
    public term: string = '';
    public content: string = '';
}