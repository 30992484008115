<h4>
  Загрузите новое изображение
</h4>

<div class="flex justify-content-between">
  <input type="file" #file placeholder="Choose file" (change)="uploadFile(file.files)" style="display:none;">

  <div class="mr-3 flex">
    <span class="upload" *ngIf="progress > 0">
      {{progress}}%
    </span>
    <span class="upload" *ngIf="message">
      {{message}}
    </span>
  </div>

  <p-button label="Выбрать файл" icon="pi pi-file" (click)="file.click()"></p-button>
</div>




<h4>
  Или выберите существующее
</h4>

<div class="mt-3 grid">
  <div *ngFor="let image of uploaded" class="col-12 sm:col-6 md:col-4 lg:col-3">
    <img [attr.src]="'/uploads/'+image" class="block w-full selectable-image" (click)="selectimage(image)"/>
  </div>
</div>



