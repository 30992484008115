import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { LeadDto, LeadFieldDto } from 'src/api/leads/models';
import { Lead, LeadFormFieldValue } from 'src/app/view-models/leads/lead';

@Injectable({
  providedIn: 'root'
})
export class LeadAssemblerService {

  public toViewModel(other: LeadDto): Lead {
    const model = new Lead(other.id ?? '', other.leadFormId ?? '');

    model.referer = other.referer ?? null;

    if (other.createdAt) {
      const createdAt = moment(other.createdAt).local();

      model.createdAtDate = createdAt.format('DD MMM YYYY');
      model.createdAtTime = createdAt.format('HH:mm:ss');
    }

    other.fields?.forEach(field => {
      const leadField = model.addField(field.fieldId ?? null);

      field.values?.forEach(value => {
        const leadFieldValue = new LeadFormFieldValue(value.id ?? '', value.value ?? '');

        leadField.addValue(leadFieldValue);
      });
    });

    return model;
  }

}
