import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CreativeApiService } from 'src/app/services/api/creative-api.service';
import { InvoiceApiService } from 'src/app/services/api/invoice-api.service';
import { StatsApiService } from 'src/app/services/api/stats-api.service';
import { ContractViewModel } from 'src/app/view-models/contract/contract-view-model';
import { StatsViewModel } from 'src/app/view-models/stats/stats';

@Component({
    selector: 'app-contract-statistics',
    templateUrl: './contract-statistics.component.html',
    styleUrls: ['./contract-statistics.component.scss']
})
export class ContractStatisticsComponent implements OnInit {
    private _entity!: ContractViewModel;
    public get entity(): ContractViewModel {
        return this._entity;
    }

    public dateFrom: Date;
    public dateTo: Date;

    public maxDate: Date;

    public chartData: any;

    public chartOptions: any;

    public activeIndex: number = 0;

    public stats: StatsViewModel[] = [];

    public get totalImpressions(): number {
        let sum = 0;
        this.data.forEach(x => {sum = sum + x});

        return sum;
    }

    public aggregateOptions: any[] = [{label: 'Для ОРД', value: 'ord-month'}, {label: 'Посуточно', value: 'daily'}];
    public aggregateValue: string = this.aggregateOptions[0].value;

    public stateOptions: any[] = [{label: 'Таблица', value: 'table'}, {label: 'График', value: 'chart'}];
    public stateValue: string = this.stateOptions[0].value;

    public labels: string[] = [];
    public data: number[] = [];
    public dateFroms: Date[] = [];
    public dateTos: Date[] = [];
    public isCompleted: boolean[] = [];

    public combinedStats: any[] = [];

    constructor(
        private creativeApi: CreativeApiService,
        private statsApi: StatsApiService,
        private invoiceApi: InvoiceApiService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private datePipe: DatePipe) {
        this._entity = this.config.data;

        this.dateFrom = new Date(this._entity.dateOfConclusion);
        //this.dateFrom.setMonth(this.dateFrom.getMonth() - 1); // TODO тут надо бы скачать дату заключения договора

        this.dateTo = new Date();
        this.maxDate = new Date();

        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

        this.chartData = {
            labels: [],
            datasets: []
        };

        this.chartOptions = {
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            plugins: {
                legend: {
                    labels: {
                        color: textColor
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder,
                        drawBorder: false
                    }
                },
                y: {
                    ticks: {
                        color: textColorSecondary,
                        beginAtZero: true
                    },
                    grid: {
                        color: surfaceBorder,
                        drawBorder: false
                    }
                }
            }
        };

        const ids = this.entity.creatives.filter(x => x.creativeId).map(x => x.creativeId ?? '');
        this.creativeApi.GetByIds(ids).subscribe(creatives => {
            this._entity.creatives.forEach(creativeContract => {
                if (!creativeContract.creative) {
                    creativeContract.creative = creatives.find(x => x.id === creativeContract.creativeId) ?? null;
                }
            });
        });

        this.RefreshChart();

        this.RefreshStats();
    }

    ngOnInit(): void {
        this.config.header = `Статистика по креативам договора ${this._entity.serialNumber}`;
    }

    public RefreshStats(): void {
        const activeTab = this.entity.creatives[this.activeIndex];

        this.stats = [];

        this.LoadStatistics(activeTab.id ?? '');
    }

    private LoadStatistics(entityId: string): void {
      this.statsApi.GetStatistics('creative-contract', entityId, this.statsApi.ToDateToString(this.dateFrom), this.statsApi.ToDateToString(this.dateTo)).subscribe(result => {
        let currentDate = new Date(this.dateFrom.toDateString());
        const end = new Date(this.dateTo.toDateString());
        //let nextDate = new Date(this.dateFrom.toDateString());
        
        
        this.stats = this.FillGaps(result, currentDate, end);

        this.RefreshChart();
      });
    }

    private FillGaps(result: StatsViewModel[], currentDate: Date, end: Date): StatsViewModel[] {
        const processedResult: StatsViewModel[] = [];

        while(currentDate <= end) {
            let item = result.find(x => 
                x.date.getFullYear() == currentDate.getFullYear() && 
                x.date.getMonth() === currentDate.getMonth() && 
                x.date.getDate() === currentDate.getDate()
            );

            if (!item) {
                item = new StatsViewModel(currentDate, 0);
            }

            processedResult.push(item);

            currentDate.setDate(currentDate.getDate() + 1);
        }

        return processedResult;
    }

    // private ToApiDate(someDate: Date): string {
    //     return `${someDate.getFullYear()}-${this.pad(someDate.getMonth()+1)}-${this.pad(someDate.getDate())}`;
    //     return someDate.toUTCString().split('T')[0];
    // }

    // private pad(num: number): string {
    //     return ("00" + num).slice (-2);
    // }

    public RefreshChart(): void {
        this.labels = [];
        this.data = [];
        this.isCompleted = [];
        this.dateFroms = [];
        this.dateTos = [];

        if (this.aggregateValue === 'ord-month') {
            
            let currentDate = new Date(this.dateFrom.toDateString());
            const end = new Date(this.dateTo.toDateString());
            let windowDate: Date;

            do {
                // Вычисляем окно (текущая дата + 1 месяц)
                windowDate = new Date(currentDate.getTime());
                windowDate.setMonth(windowDate.getMonth() + 1);

                // Получим всю статистику за этот период
                const windowedStats = this.stats.filter(x => x.date >= currentDate && x.date < windowDate);

                if (windowedStats.length > 0) {
                    const aggregatedValue = windowedStats.reduce((sum, current) => sum + current.impressions, 0);

                    let labelDate = new Date(windowDate.getTime());
                    labelDate.setDate(labelDate.getDate() - 1);

                    const isCompleted = labelDate <= end;

                    this.isCompleted.push(isCompleted);

                    if (!isCompleted) {
                        labelDate = end;
                    }

                    this.labels.push(`с ${this.statsApi.ToDateToString(currentDate)} до ${this.statsApi.ToDateToString(labelDate)}`);
                    this.data.push(aggregatedValue);

                    this.dateFroms.push(new Date(currentDate.getTime()));
                    this.dateTos.push(new Date(labelDate.getTime()));
                }

                // Сдвинем окно
                currentDate = new Date(windowDate.getTime());
            } while(currentDate <= end);
        } else {
            this.labels = this.stats.map(x => x.viewDate);
            this.data = this.stats.map(x => x.impressions);
            //this.data = this.stats.map(x => x.impressions);
        }


        this.chartData = {
            labels: this.labels,
            datasets: [
                {
                    label: 'Просмотры',
                    data: this.data,
                    fill: false,
                    borderColor: '#22C55E',
                    tension: 0.4
                }
            ]
        };

        this.combinedStats = [];

        let index = 0;

        while(index < this.labels.length) {
            this.combinedStats.push({
                label: this.labels[index],
                data: this.data[index],
                diff: index == 0 ? 0 : this.data[index] - this.data[index-1],
                isCompleted: this.isCompleted[index],
                dateFrom: this.dateFroms[index],
                dateTo: this.dateTos[index]
            });

            index = index + 1;
        }
    }

    public generateInvoiceFromContract(contract: ContractViewModel, dateFrom: Date, dateTo: Date): void {
        // CreateFromContract

        this.invoiceApi.CreateFromContract(contract, '43f455bd-33b4-40ae-b58f-3cd3429620ab', dateFrom, dateTo).subscribe(x => {
            console.log('API RESULT', x);
        });
    }
}
