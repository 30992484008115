import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TokenData } from 'src/api/auth/models';
import { SignService } from 'src/api/auth/services';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public isAuthenticated: boolean = false;

  private _login: string = '';
  public get login(): string {
    return this._login;
  }

  private _accountType: string = '';
  public get accountType(): string {
    return this._accountType;
  }

  private _accessToken: string = '';
  public get accessToken(): string {
    return this._accessToken;
  }


  private _refreshToken: string = '';
  public get refreshToken(): string {
    return this._refreshToken;
  }

  private _expiresAt: Date | null = null;

  public get isAdmin(): boolean {
    return this._accountType === 'admin' || this._accountType === 'superadmin';
  }

  constructor(private api: SignService) { }

  /**
   * Подгрузка аутентификационных данных из хранилища
   */
  public LoadAuthData(): void {
    try {
      this._login = localStorage.getItem('login') ?? '';
      this._accessToken = localStorage.getItem('accessToken') ?? '';
      this._accountType = localStorage.getItem('accountType') ?? '';
      this._refreshToken = localStorage.getItem('refreshToken') ?? '';

      const expiresAtRaw = localStorage.getItem('expiresAt');

      if (expiresAtRaw) {
        this._expiresAt = new Date(expiresAtRaw);
      }

      if (this._login) {
        this.isAuthenticated = true;
      }

    } catch (ex) {
      this.isAuthenticated = false;
    }
  }

  public SignIn(login: string, password: string): Observable<boolean> {
    return this.api.signIn({
      body: {
        login: login,
        password: password
      }
    }).pipe(
      map(result => {

        this.ProcessTokenData(result);

        return true;
      })
    );
  }

  public RefreshToken(refreshToken: string): Observable<boolean> {
    return this.api.refreshToken({
      body: {
        refreshCode: refreshToken
      }
    }).pipe(
      map(result => {
        this.ProcessTokenData(result);

        return true;
      })
    );
  }

  public SignOut(): void {
    this.isAuthenticated = false;

    this._login = '';
    this._accessToken = '';
    this._accountType = '';
    this._refreshToken = '';
    this._expiresAt = null;

    localStorage.removeItem('login');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('accountType');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('expiresAt');
  }

  private ProcessTokenData(tokenData: TokenData): void {
    if (!tokenData) {
      return;
    }

    this._login = tokenData.login ?? '';
    this._accessToken = tokenData.accessToken ?? '';
    this._accountType = tokenData.accountType ?? '';
    this._refreshToken = tokenData.refreshToken ?? '';
    this._expiresAt = tokenData.expiresAfter ? new Date(tokenData.expiresAfter) : null;
    this.isAuthenticated = true;

    localStorage.setItem('login', this._login);
    localStorage.setItem('accessToken', this._accessToken);
    localStorage.setItem('accountType', this._accountType);
    localStorage.setItem('refreshToken', this._refreshToken);
    localStorage.setItem('expiresAt', tokenData.expiresAfter ?? '');
  }
}
