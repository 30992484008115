import { Injectable } from '@angular/core';
import { PlatformDto } from 'src/api/legal/models';
import { PlatformViewModel } from 'src/app/view-models/platform/platform-view-model';

@Injectable({
  providedIn: 'root'
})
export class PlatformAssemblerService {

  constructor() { }

  public ToViewModel(other: PlatformDto): PlatformViewModel {
    const model = new PlatformViewModel(other.name ?? '', other.uri ?? '');
    model.id = other.id ?? null;

    return model;
  }

  public ToDto(other: PlatformViewModel): PlatformDto {
    return {
      id: other.id ?? undefined,
      name: other.name,
      uri: other.uri
    };
  }
}
