export class LinkVisitAggregation {
    private _date:Date;
    public get date(): string {
        return this._date.toLocaleDateString();
    }

    public get rawDate(): Date {
        return this._date;
    }

    public overallVisits: number;
    public uniqueVisits: number;

    constructor(date: Date, overallVisits: number, uniqueVisits: number) {
        this._date = date;
        this.overallVisits = overallVisits;
        this.uniqueVisits = uniqueVisits;
    }
}