/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { LinkVisitAggregation } from '../models/link-visit-aggregation';
import { OveralLinksVisit } from '../models/overal-links-visit';
import { PageLinkVisitAggregation } from '../models/page-link-visit-aggregation';

@Injectable({
  providedIn: 'root',
})
export class LinkVisitService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getOverallLinkVisitAggregated
   */
  static readonly GetOverallLinkVisitAggregatedPath = '/api/link-visit/overallAggregation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOverallLinkVisitAggregated()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOverallLinkVisitAggregated$Response(params: {
    linkIds: Array<string>;
  }): Observable<StrictHttpResponse<Array<OveralLinksVisit>>> {

    const rb = new RequestBuilder(this.rootUrl, LinkVisitService.GetOverallLinkVisitAggregatedPath, 'get');
    if (params) {
      rb.query('linkIds', params.linkIds, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OveralLinksVisit>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOverallLinkVisitAggregated$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOverallLinkVisitAggregated(params: {
    linkIds: Array<string>;
  }): Observable<Array<OveralLinksVisit>> {

    return this.getOverallLinkVisitAggregated$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OveralLinksVisit>>) => r.body as Array<OveralLinksVisit>)
    );
  }

  /**
   * Path part for operation getLinkVisitAggregated
   */
  static readonly GetLinkVisitAggregatedPath = '/api/link-visit/{linkId}/dateAggregation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLinkVisitAggregated()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLinkVisitAggregated$Response(params: {
    linkId: string;
    dateFrom?: string;
    dateTo?: string;
  }): Observable<StrictHttpResponse<Array<LinkVisitAggregation>>> {

    const rb = new RequestBuilder(this.rootUrl, LinkVisitService.GetLinkVisitAggregatedPath, 'get');
    if (params) {
      rb.path('linkId', params.linkId, {});
      rb.query('dateFrom', params.dateFrom, {});
      rb.query('dateTo', params.dateTo, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LinkVisitAggregation>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLinkVisitAggregated$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLinkVisitAggregated(params: {
    linkId: string;
    dateFrom?: string;
    dateTo?: string;
  }): Observable<Array<LinkVisitAggregation>> {

    return this.getLinkVisitAggregated$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LinkVisitAggregation>>) => r.body as Array<LinkVisitAggregation>)
    );
  }

  /**
   * Path part for operation getPageLinkVisitAggregated
   */
  static readonly GetPageLinkVisitAggregatedPath = '/api/link-visit/{linkId}/pageAggregation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPageLinkVisitAggregated()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPageLinkVisitAggregated$Response(params: {
    linkId: string;
    dateFrom?: string;
    dateTo?: string;
  }): Observable<StrictHttpResponse<Array<PageLinkVisitAggregation>>> {

    const rb = new RequestBuilder(this.rootUrl, LinkVisitService.GetPageLinkVisitAggregatedPath, 'get');
    if (params) {
      rb.path('linkId', params.linkId, {});
      rb.query('dateFrom', params.dateFrom, {});
      rb.query('dateTo', params.dateTo, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PageLinkVisitAggregation>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPageLinkVisitAggregated$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPageLinkVisitAggregated(params: {
    linkId: string;
    dateFrom?: string;
    dateTo?: string;
  }): Observable<Array<PageLinkVisitAggregation>> {

    return this.getPageLinkVisitAggregated$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PageLinkVisitAggregation>>) => r.body as Array<PageLinkVisitAggregation>)
    );
  }

}
