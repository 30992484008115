import { UserViewModel } from "../user/user-view-model";

export class RightsViewModel {
    public id: string | null = null;

    public entityType: string;
    public entityId: string;

    public userId: string;
    public user: UserViewModel | null = null;

    public canModify: boolean = false;
    public canRemove: boolean = false;

    constructor(entityType: string, entityId: string, userId: string) {
        this.entityType = entityType;
        this.entityId = entityId;
        this.userId = userId;
    }
}