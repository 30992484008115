import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { RightsRepositoryService } from 'src/app/services/repositories/rights-repository.service';
import { LinkViewModel } from 'src/app/view-models/link/link-view-model';
import { RightsViewModel } from 'src/app/view-models/rights/rights-view-model';

@Component({
  selector: 'app-link-configure-dialogue',
  templateUrl: './link-configure-dialogue.component.html',
  styleUrls: ['./link-configure-dialogue.component.scss']
})
export class LinkConfigureDialogueComponent implements OnInit {
  public form: FormGroup;

  private _isEditing: boolean = false;
  public get isEditing(): boolean {
    return this._isEditing;
  }

  public get buttonText(): string {
    return this.isEditing ? 'Сохранить' : 'Создать';
  }

  public isLoadingRights: boolean = true;
  private _rights: RightsViewModel | null = null;
  public get canEdit(): boolean {
    if (!this._rights)
    {
      return false;
    }

    return this._rights.canModify;
  }

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private rightsAPI: RightsRepositoryService) {
    this._isEditing = this.config.data.isEditing ?? false;

    if (this.isEditing) {
      this.isLoadingRights = true;
      this.rightsAPI.GetMyEntityRights('link', this.config.data.model?.id).subscribe(result => {
        this._rights = result;
        this.isLoadingRights = false;
      }, error => {
        this.isLoadingRights = false;
      });
    }

    this.form = new FormGroup({
      name: new FormControl({
          value: this.config.data.model?.name ?? 'Ссылка на сайт с каталога',
          disabled: this.config.data.forbidNameEditing && this.isEditing
        }, [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(256)
        ]),
      url: new FormControl({
          value: this.config.data.model?.url ?? '',
          disabled: this.isEditing
        }, [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(512)
        ]),
      overrideUTM: new FormControl({
          value: this.config.data.model?.overrideUtms ?? true,
          disabled: false
        }),
      utmSource: new FormControl({
          value: this.config.data.model?.marks?.source ?? 'marketing-tech',
          disabled: false
        }, [
          Validators.maxLength(512)
        ]),
      utmCampaign: new FormControl({
          value: this.config.data.model?.marks?.campaign ?? '',
          disabled: false
        }, [
          Validators.maxLength(512)
        ]),
      utmMedium: new FormControl({
          value: this.config.data.model?.marks?.medium ?? 'organic',
          disabled: false
        }, [
          Validators.maxLength(512)
        ]),
      utmTerm: new FormControl({
          value: this.config.data.model?.marks?.term ?? '',
          disabled: false
        }, [
          Validators.maxLength(512)
        ]),
      utmContent: new FormControl({
          value: this.config.data.model?.marks?.content ?? '{{hub_source}}',
          disabled: false
        }, [
          Validators.maxLength(512)
        ]),
    });
  }

  ngOnInit(): void {
  }


  public Cancel(): void {
    this.ref.close(null);
  }

  public Confirm(): void {
    const values = this.form.getRawValue();

    const model = new LinkViewModel('', values.url);
    model.name = values.name;
    model.overrideUtms = values.overrideUTM;
    model.marks.source = values.utmSource;
    model.marks.campaign = values.utmCampaign;
    model.marks.medium = values.utmMedium;
    model.marks.term = values.utmTerm;
    model.marks.content = values.utmContent;

    console.log('model', model);

    this.ref.close(model);
  }
}
