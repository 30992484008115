import { Injectable } from '@angular/core';
import { LeadFormDto, LeadFormFieldDto } from 'src/api/leads/models';
import { LeadForm, LeadFormField, leadFormFieldTypes, leadFormOrigins, otherLeadFormFieldType } from 'src/app/view-models/leads/lead-form';

@Injectable({
  providedIn: 'root'
})
export class LeadFormAssemblerService {

  public toViewModel(other: LeadFormDto): LeadForm {
    const origin = leadFormOrigins.find(x => x.codename === other.origin) ?? leadFormOrigins[0];
    const model = new LeadForm(other.codename ?? '', other.name ?? '', origin);
    model.id = other.id ?? null;
    model.createdAt = other.createdAt ?? null;
    model.lastModifiedAt = other.lastModifiedAt ?? null;

    other.fields?.forEach(field => {
      const fieldModel = this.toFieldViewModel(field);

      model.addField(fieldModel);
    });

    return model;
  }

  public toFieldViewModel(other: LeadFormFieldDto): LeadFormField {
    const type = leadFormFieldTypes.find(x => x.codename === other.type) ?? otherLeadFormFieldType;

    const model = new LeadFormField(other.codename ?? '', other.name ?? '', type);
    model.id = other.id ?? null;
    model.order = other.order ?? 0;
    model.ignore = other.ignore ?? false;
    model.createdAt = other.createdAt ?? null;
    model.lastModifiedAt = other.lastModifiedAt ?? null;

    return model;
  }

  public toDto(other: LeadForm): LeadFormDto {
    return {
      id: other.id,
      codename: other.codename,
      name: other.name,
      origin: other.origin?.codename
    };
  }

  public toFieldDto(other: LeadFormField): LeadFormFieldDto {
    return {
      id: other.id,
      codename: other.codename,
      name: other.name,
      type: other.type?.codename,
      order: other.order,
      ignore: other.ignore
    };
  }
}
