<div class="flex align-items-center">
    <h1 class="mr-auto">
        Пользователи
    </h1>

    <button *ngIf="isAdmin" pButton type="button" icon="fa-solid fa-plus" label="Создать"
        (click)="OpenCreateDialog()"></button>
</div>

<div class="mt-5 fancy-card">
    <div class="fancy-card-content">
        <p-table [value]="users" [paginator]="true" [rows]="10" [showCurrentPageReport]="true" [loading]="isLoading"
            [showLoader]="false" [lazy]="true" (onLazyLoad)="LoadData($event)" dataKey="id" [totalRecords]="totalAmount"
            currentPageReportTemplate="Показаны с {first} по {last} из {totalRecords} строчек"
            [rowsPerPageOptions]="[10,25,50]">
            <ng-template pTemplate="header">
                <tr>
                    <th>Логин</th>
                    <th style="width: 250px">Роль</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
                <tr>
                    <td class="flex align-items-center">
                        <button class="p-button-sm p-button-secondary p-button-text mr-1" icon="fa-solid fa-key" pButton type="button" (click)="OpenResetPasswordDialog(item)"></button>
                        
                        <div>
                            <div class="flex align-items-center">
                                <strong class="mr-2">
                                    {{item.login}}
                                </strong>
    
                                <i *ngIf="item.isOnline" style="font-size: 0.5em;line-height: 1.25em;color:#22C55E" class="fa-solid fa-circle" pTooltip="Сейчас онлайн"></i>
                            </div>
                            

                            <div class="text-bare text-small">
                                <ng-container *ngIf="item.isOnline; else isOffline">
                                    В сети
                                </ng-container>
                                <ng-template #isOffline>
                                    Был в сети {{item.wasOnline}} назад
                                </ng-template>
                            </div>
                        </div>
                    </td>

                    <td>
                        {{item.accountName}}
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="loadingbody">
                <tr *ngFor="let item of [1,2,3,4,5,6,7,8,9,10]">
                    <td>
                        <p-skeleton width="100%" height="2rem"></p-skeleton>
                    </td>

                    <td style="width: 200px">
                        <p-skeleton width="100%" height="2rem"></p-skeleton>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>