import { Injectable } from '@angular/core';
import { RightsDto } from 'src/api/administration/models';
import { RightsViewModel } from 'src/app/view-models/rights/rights-view-model';

@Injectable({
  providedIn: 'root'
})
export class RightsAssemblerService {

  constructor() { }

  public ToViewModel(other: RightsDto): RightsViewModel {
    const model = new RightsViewModel(other.entityType ?? '', other.entityId ?? '', other.userId ?? '');
    model.id = other.id ?? '';
    model.canModify = other.canModify ?? false;
    model.canRemove = other.canRemove ?? false;

    return model;
  }

  public ToDto(other: RightsViewModel): RightsDto {
    return {
      id: other.id ?? undefined,
      userId: other.userId ?? undefined,
      entityType: other.entityType ?? undefined,
      entityId: other.entityId ?? undefined,
      canModify: other.canModify,
      canRemove: other.canRemove,
    };
  }
}
