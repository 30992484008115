/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { LeadDto } from '../models/lead-dto';
import { LeadStats } from '../models/lead-stats';
import { LeadUpdates } from '../models/lead-updates';

@Injectable({
  providedIn: 'root',
})
export class LeadService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation listLeads
   */
  static readonly ListLeadsPath = '/api/lead';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listLeads()` instead.
   *
   * This method doesn't expect any request body.
   */
  listLeads$Response(params?: {
    Page?: number;
    PageSize?: number;
    LeadFormIds?: Array<string>;
    CreatedAfter?: string;
    CreatedBefore?: string;
  }): Observable<StrictHttpResponse<Array<LeadDto>>> {

    const rb = new RequestBuilder(this.rootUrl, LeadService.ListLeadsPath, 'get');
    if (params) {
      rb.query('Page', params.Page, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('LeadFormIds', params.LeadFormIds, {});
      rb.query('CreatedAfter', params.CreatedAfter, {});
      rb.query('CreatedBefore', params.CreatedBefore, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LeadDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listLeads$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listLeads(params?: {
    Page?: number;
    PageSize?: number;
    LeadFormIds?: Array<string>;
    CreatedAfter?: string;
    CreatedBefore?: string;
  }): Observable<Array<LeadDto>> {

    return this.listLeads$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LeadDto>>) => r.body as Array<LeadDto>)
    );
  }

  /**
   * Path part for operation getLeadsStatistics
   */
  static readonly GetLeadsStatisticsPath = '/api/lead/statistic/{LeadFormId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLeadsStatistics()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLeadsStatistics$Response(params: {
    LeadFormId: string;
    After?: string;
    Before?: string;
  }): Observable<StrictHttpResponse<Array<LeadStats>>> {

    const rb = new RequestBuilder(this.rootUrl, LeadService.GetLeadsStatisticsPath, 'get');
    if (params) {
      rb.path('LeadFormId', params.LeadFormId, {});
      rb.query('After', params.After, {});
      rb.query('Before', params.Before, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LeadStats>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLeadsStatistics$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLeadsStatistics(params: {
    LeadFormId: string;
    After?: string;
    Before?: string;
  }): Observable<Array<LeadStats>> {

    return this.getLeadsStatistics$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LeadStats>>) => r.body as Array<LeadStats>)
    );
  }

  /**
   * Path part for operation getNewLeadsStats
   */
  static readonly GetNewLeadsStatsPath = '/api/lead/new';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNewLeadsStats()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getNewLeadsStats$Response(params?: {
    body?: {
[key: string]: number;
}
  }): Observable<StrictHttpResponse<Array<LeadUpdates>>> {

    const rb = new RequestBuilder(this.rootUrl, LeadService.GetNewLeadsStatsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LeadUpdates>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getNewLeadsStats$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getNewLeadsStats(params?: {
    body?: {
[key: string]: number;
}
  }): Observable<Array<LeadUpdates>> {

    return this.getNewLeadsStats$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LeadUpdates>>) => r.body as Array<LeadUpdates>)
    );
  }

}
