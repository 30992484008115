import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'primeng/dynamicdialog';
import { SignInDialogueComponent } from './dialogues/sign-in-dialogue/sign-in-dialogue.component';
import { AuthService } from './services/auth.service';
import { UserViewModel } from './view-models/user/user-view-model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [DialogService]
})
export class AppComponent implements OnInit {
  title = 'mt-dashboard';

  public get isAuthenticated(): boolean {
    return this.authService.isAuthenticated;
  }

  public get login(): string {
    return this.authService.login;
  }

  public isAutheticating: boolean = false;

  public get isAdmin(): boolean {
    return this.authService.isAdmin;
  }

  constructor(private config: PrimeNGConfig,
    private translateService: TranslateService,
    private dialogService: DialogService,
    private authService: AuthService,
    private router: Router) {
      this.authService.LoadAuthData();
    }

    ngOnInit() {
        this.translateService.setDefaultLang('rus');

        this.translate('rus');
    }

    translate(lang: string) {
        this.translateService.use(lang);
        this.translateService.get('primeng').subscribe(res => this.config.setTranslation(res));
    }

    public SignInOpenDialog(): void {
      const ref = this.dialogService.open(SignInDialogueComponent, {
        header: 'Вход в систему',
        width: '380px',
        dismissableMask: true,
      });

      ref.onClose.subscribe((data: UserViewModel) => {
        this.isAutheticating = true;

        this.authService.SignIn(data.login, data.password).subscribe(x => {
          this.isAutheticating = false;
        }, err => {
          this.isAutheticating = false;
        });
      });
    }

    public SignOut(): void {
      this.authService.SignOut();

      this.router.navigate(['/']);
    }

    public RouteToLinks(): void {
      this.router.navigate(['/link']);
    }

    public RouteToUsers(): void {
      this.router.navigate(['/user']);
    }

    public RouteToCounterparties(): void {
      this.router.navigate(['/counterparty']);
    }

    public RouteToContracts(): void {
      this.router.navigate(['/contract']);
    }

    public RouteToCreatives(): void {
      this.router.navigate(['/creative']);
    }

    public RouteToInvoices(): void {
      this.router.navigate(['/invoice']);
    }

    public RouteToLeadForms(): void {
      this.router.navigate(['/lead-forms']);
    }
}