<div class="stats-container grid">
    <div class="col-12 sm:col-6">
        <label for="dateFrom">Начало периода</label>
        <p-calendar dateFormat="yy-mm-dd" [(ngModel)]="dateFrom" [maxDate]="maxDate" styleClass="w-full" [showIcon]="true" (onClose)="RefreshStats()"></p-calendar>
    </div>

    <div class="col-12 sm:col-6">
        <label for="dateFrom">Конец периода</label>
        <p-calendar dateFormat="yy-mm-dd" [(ngModel)]="dateTo" [maxDate]="maxDate" styleClass="w-full" [showIcon]="true" (onClose)="RefreshStats()"></p-calendar>
    </div>

    <div class="col-12">
        <p-tabView [(activeIndex)]="activeIndex" [scrollable]="true" (onChange)="RefreshStats()">
            <p-tabPanel *ngFor="let model of entity.creatives" [header]="model.creative?.name ?? 'Без названия'">

            </p-tabPanel>
        </p-tabView>

        <div class="flex align-items-center justify-content-end">
            <p-selectButton styleClass="mr-3" [options]="aggregateOptions" [(ngModel)]="aggregateValue" optionLabel="label" optionValue="value" (onChange)="RefreshChart()"></p-selectButton>

            <p-selectButton [options]="stateOptions" [(ngModel)]="stateValue" optionLabel="label" optionValue="value"></p-selectButton>
        </div>

        <ng-container [ngSwitch]="stateValue">

            <ng-container *ngSwitchCase="'chart'">
                <p-chart styleClass="mt-3" type="line" [data]="chartData" [options]="chartOptions"></p-chart>
        
                <div class="mt-3 grid">
                    <div class="col-6">Всего за указанный период</div>
                    <div class="col-6">{{totalImpressions|number}}</div>
                </div>
            </ng-container>
            
            <p-table *ngSwitchCase="'table'" styleClass="mt-3" [value]="combinedStats">
           
                <ng-template pTemplate="header">
                    <tr>
                        <th>Дата</th>
                        <th>Просмотры</th>
                        <th>Динамика просмотров</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-model>
                    <tr>
                        <td>
                            <ng-container *ngIf="model.isCompleted">
                                <p-tag styleClass="mr-3" severity="success" icon="pi pi-check" value="Готов"></p-tag>

                                <p-button styleClass="p-button-text" icon="fa-solid fa-file-invoice-dollar" (click)="generateInvoiceFromContract(entity, model.dateFrom, model.dateTo)"></p-button>
                            </ng-container>

                            {{ model.label }}
                        </td>
                        <td>
                            {{ model.data|number }}
                        </td>
                        <td>
                            <ng-container *ngIf="model.diff >= 0; else negativeDiff">
                                <span style="color:#22C55E">+{{ model.diff|number }}</span>
                            </ng-container>
                            <ng-template #negativeDiff>
                                <span style="color:#EF4444">{{ model.diff|number }}</span>
                            </ng-template>
                        </td>
                    </tr>
                </ng-template>
    
                <ng-template pTemplate="summary">
                    <div class="grid">
                        <div class="col-6">Всего за указанный период</div>
                        <div class="col-6">{{totalImpressions|number}}</div>
                    </div>
                </ng-template>
            </p-table>
        </ng-container>
    </div>
</div>