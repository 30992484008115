import * as moment from 'moment'

export class StatsViewModel {
    public date: Date;
    public viewDate: string;

    public impressions: number;

    constructor(date: Date, impressions: number) {
        this.date = new Date(date.toDateString());
        this.impressions = impressions;

        this.viewDate = moment(date).format('Do MMM');
    }
}