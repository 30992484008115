<form [formGroup]="form" class="from-grid grid h-full">
    <div class="col-12 lg:col-4 md:col-6">
        <div class="field">
            <label for="origin">
                Источник<span class="required">*</span>
                <i class="ml-2 fa-regular fa-circle-question" pTooltip="Указывает откуда будут приходить лиды и диктует какой вебхук использовать для этой лид-формы"></i>
            </label>
            
            <p-dropdown formControlName="origin" styleClass="w-full" appendTo="body"
                [options]="formOrigins" optionLabel="name" placeholder="Выберите источник">
            </p-dropdown>
        </div>
    </div>

    <div class="col-12 lg:col-4 md:col-6">
        <div class="field">
            <label for="codename">
                Кодовое название<span class="required">*</span>
                <i class="ml-2 fa-regular fa-circle-question" pTooltip="Укажите ID формы сбора лидов из источника"></i>
            </label>

            <input id="codename" formControlName="codename" type="text" class="w-full"
                placeholder="Для идентификации формы по API" pInputText />
        </div>
    </div>

    <div class="col-12 lg:col-4 md:col-12">
        <div class="field">
            <label for="name">
                Название<span class="required">*</span>
                <i class="ml-2 fa-regular fa-circle-question" pTooltip="Просто название для отображения"></i>
            </label>

            <input id="name" formControlName="name" type="text" class="w-full" placeholder="Для отображения в админке"
                pInputText />
        </div>
    </div>

    <div class="col-12">
        <div class="p-3 surface-ground border-round border-1 surface-border">
            <h2 class="h4">Поля</h2>
            <p-table [value]="fieldForms" styleClass="mt-3 w-full border-1 surface-border">
                <ng-template pTemplate="summary">
                    <div class="flex align-items-center justify-content-between gap-3">
                        <p-message *ngIf="fieldForms.length === 0" severity="info" text="Добавьте поля, чтобы иметь возможность настроить интеграцию" class="flex-grow-1" styleClass="w-full text-small"></p-message>

                        <button pButton class="ml-auto p-button-secondary p-button-sm" icon="pi pi-plus"
                            label="Добавить поле" (click)="addField()"></button>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 150px;">
                            <i class="fa-regular fa-eye-slash" pTooltip="Не сохранять значения этого поля"></i>
                        </th>

                        <th style="width: 300px;">
                            Кодовое название
                            <i class="ml-2 fa-regular fa-circle-question" pTooltip="Укажите ID поля формы в истчонике. Должно быть уникальным (в этой лид-форме)"></i>
                        </th>

                        <th>
                            Название
                            <i class="ml-2 fa-regular fa-circle-question" pTooltip="Просто название для отображения"></i>
                        </th>

                        <th>
                            Тип данных
                            <i class="ml-2 fa-regular fa-circle-question" pTooltip="Укажите наиболее подходящий тип данных. Если не уверены - оставьте 'Прочее'"></i>
                        </th>

                        <th style="width: 64px;"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-fields>
                    <tr [formGroup]="fields" [ngClass]="{
                        'bg-red-50': !fields.valid && !isRemoved(fields),
                        'opacity-50': isRemoved(fields)
                    }">
                        <td>
                            <p-inputSwitch formControlName="ignore"></p-inputSwitch>
                        </td>

                        <td>
                            <input formControlName="codename" type="text" class="w-full"
                                placeholder="field_id" pInputText />
                        </td>
                        <td>
                            <input formControlName="name" type="text" class="w-full" placeholder="Название"
                                pInputText />
                        </td>
                        <td>
                            <p-dropdown formControlName="type" styleClass="w-full" appendTo="body"
                                [options]="fieldTypes" optionLabel="name" placeholder="Выберите тип данных">
                            </p-dropdown>
                        </td>
                        <td>
                            <ng-container *ngIf="isRemoved(fields); else fieldNotRemoved">
                                <button pButton class="p-button-success p-button-text p-button-sm" icon="pi pi-undo"
                                    (click)="restoreField(fields)" pTooltip="Нажмите, чтобы восстановить поле"></button>
                            </ng-container>
                            <ng-template #fieldNotRemoved>
                                <button pButton class="p-button-danger p-button-text p-button-sm" icon="pi pi-trash"
                                    (click)="removeField(fields)" pTooltip="Нажмите, чтобы удалить поле"></button>
                            </ng-template>

                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="4">
                            <div class="p-4 text-color-secondary w-full flex align-items-center justify-content-center">
                                Не добавлено ни одного поля
                            </div>
                        </td>
                    </tr>
                    
                </ng-template>
            </p-table>
        </div>
    </div>

    <div class="col-12">
        <div class="flex justify-content-end">
            <button pButton type="button" [disabled]="isInvalid" [label]="isCreateMode ? 'Создать' : 'Сохранить'"
                [loading]="isBusy" (click)="Submit()"></button>
        </div>
    </div>
</form>