import { CounterpartyViewModel } from "../counterparty/counterparty-view-model";
import { CreativeContractViewModel } from "../creative/creative-view-model";
import { InvoiceViewModel } from "../invoice/invoice-view-model";

export class ContractViewModel {
    public id: string | null = null;
    public parentId: string | null = null;

    public typeCode: string;
    public type: ContractTypeViewModel | null = null;

    public subjectCode: string;
    public subject: ContractSubjectViewModel | null = null;

    public serialNumber: string;

    public dateOfConclusion: string;

    public contractorCounterpartyId: string;
    public contractor: CounterpartyViewModel | null = null;

    public clientCounterpartyId: string;
    public client: CounterpartyViewModel | null = null;

    public amount: number;
    public isVatIncluded: boolean = false;

    public isSynced: boolean = false;
    public isSyncing: boolean = false;
    public isAcceptedByErir: boolean = false;
    public erirId: string = '';

    public createdAt: string = '';
    
    public creatives: CreativeContractViewModel[] = []

    public latestInvoice: InvoiceViewModel | null = null;

    public invoiceAutomation: boolean = false;

    public invoiceAutomationProgress: string = '';

    public get hasCreatives(): boolean {
        return this.creatives.length > 0;
    }

    constructor(type: string, subject: string, serialNumber: string, dateOfConclusion: string,
        contractorCounterpartyId: string, clientCounterpartyId: string, amount: number) {
        this.typeCode = type;

        this.type = ContractTypes.find(x => x.code === type) ?? null;

        this.subjectCode = subject;
        this.serialNumber = serialNumber;

        this.dateOfConclusion = dateOfConclusion;
        this.contractorCounterpartyId = contractorCounterpartyId;
        this.clientCounterpartyId = clientCounterpartyId;

        this.amount = amount;
    }
}

export class ContractTypeViewModel {
    public code: string;
    public name: string;

    constructor(code: string, name: string) {
        this.code = code;
        this.name = name;
    }
}

export const ContractServiceType = new ContractTypeViewModel('service','Договор оказания услуг');
export const ContractAdditionalType =  new ContractTypeViewModel('additional','Дополнение к договору');
export const ContractMediatonType =  new ContractTypeViewModel('mediation','Посредническй договор');

export const ContractTypes: ContractTypeViewModel[] = [
    ContractServiceType,
    ContractAdditionalType,
    ContractMediatonType
];

export class ContractSubjectViewModel {
    public code: string;
    public name: string;

    constructor(code: string, name: string) {
        this.code = code;
        this.name = name;
    }
}

export const ContractSubjects: ContractSubjectViewModel[] = [
    new ContractSubjectViewModel('distribution','Распространение рекламы'),
    new ContractSubjectViewModel('distributionOrganisation','Организация распространения рекламы')
];