import { Component, Input, OnInit } from '@angular/core';
import { CounterpartyViewModel } from 'src/app/view-models/counterparty/counterparty-view-model';

@Component({
  selector: 'app-counterparty-snippet',
  templateUrl: './counterparty-snippet.component.html',
  styleUrls: ['./counterparty-snippet.component.scss']
})
export class CounterpartySnippetComponent implements OnInit {
  @Input() public counterparty: CounterpartyViewModel | null = null;

  constructor() { }

  ngOnInit(): void {
  }

}
