/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { LeadFormDto } from '../models/lead-form-dto';
import { LeadFormFieldDto } from '../models/lead-form-field-dto';

@Injectable({
  providedIn: 'root',
})
export class LeadFormService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation listLeadForms
   */
  static readonly ListLeadFormsPath = '/api/leadform';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listLeadForms()` instead.
   *
   * This method doesn't expect any request body.
   */
  listLeadForms$Response(params?: {
    Page?: number;
    PageSize?: number;
  }): Observable<StrictHttpResponse<Array<LeadFormDto>>> {

    const rb = new RequestBuilder(this.rootUrl, LeadFormService.ListLeadFormsPath, 'get');
    if (params) {
      rb.query('Page', params.Page, {});
      rb.query('PageSize', params.PageSize, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LeadFormDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listLeadForms$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listLeadForms(params?: {
    Page?: number;
    PageSize?: number;
  }): Observable<Array<LeadFormDto>> {

    return this.listLeadForms$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LeadFormDto>>) => r.body as Array<LeadFormDto>)
    );
  }

  /**
   * Path part for operation createLeadForm
   */
  static readonly CreateLeadFormPath = '/api/leadform';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createLeadForm()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createLeadForm$Response(params: {
    body: LeadFormDto
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, LeadFormService.CreateLeadFormPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createLeadForm$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createLeadForm(params: {
    body: LeadFormDto
  }): Observable<string> {

    return this.createLeadForm$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getLeadForm
   */
  static readonly GetLeadFormPath = '/api/leadform/{LeadFormId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLeadForm()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLeadForm$Response(params: {
    LeadFormId: string;
  }): Observable<StrictHttpResponse<LeadFormDto>> {

    const rb = new RequestBuilder(this.rootUrl, LeadFormService.GetLeadFormPath, 'get');
    if (params) {
      rb.path('LeadFormId', params.LeadFormId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LeadFormDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLeadForm$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLeadForm(params: {
    LeadFormId: string;
  }): Observable<LeadFormDto> {

    return this.getLeadForm$Response(params).pipe(
      map((r: StrictHttpResponse<LeadFormDto>) => r.body as LeadFormDto)
    );
  }

  /**
   * Path part for operation updateLeadForm
   */
  static readonly UpdateLeadFormPath = '/api/leadform/{LeadFormId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateLeadForm()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateLeadForm$Response(params: {
    LeadFormId: string;
    body: LeadFormDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, LeadFormService.UpdateLeadFormPath, 'put');
    if (params) {
      rb.path('LeadFormId', params.LeadFormId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateLeadForm$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateLeadForm(params: {
    LeadFormId: string;
    body: LeadFormDto
  }): Observable<void> {

    return this.updateLeadForm$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation addLeadFormFields
   */
  static readonly AddLeadFormFieldsPath = '/api/leadform/{LeadFormId}/fields';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addLeadFormFields()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addLeadFormFields$Response(params: {
    LeadFormId: string;
    body: LeadFormFieldDto
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, LeadFormService.AddLeadFormFieldsPath, 'post');
    if (params) {
      rb.path('LeadFormId', params.LeadFormId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addLeadFormFields$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addLeadFormFields(params: {
    LeadFormId: string;
    body: LeadFormFieldDto
  }): Observable<string> {

    return this.addLeadFormFields$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation removeLeadFormFields
   */
  static readonly RemoveLeadFormFieldsPath = '/api/leadform/{LeadFormId}/fields';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeLeadFormFields()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  removeLeadFormFields$Response(params: {
    LeadFormId: string;
    body?: Array<string>
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, LeadFormService.RemoveLeadFormFieldsPath, 'delete');
    if (params) {
      rb.path('LeadFormId', params.LeadFormId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeLeadFormFields$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  removeLeadFormFields(params: {
    LeadFormId: string;
    body?: Array<string>
  }): Observable<void> {

    return this.removeLeadFormFields$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation updateLeadFormField
   */
  static readonly UpdateLeadFormFieldPath = '/api/leadform/{LeadFormId}/fields/{LeadFormFieldId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateLeadFormField()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateLeadFormField$Response(params: {
    LeadFormId: string;
    LeadFormFieldId: string;
    body: LeadFormFieldDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, LeadFormService.UpdateLeadFormFieldPath, 'put');
    if (params) {
      rb.path('LeadFormId', params.LeadFormId, {});
      rb.path('LeadFormFieldId', params.LeadFormFieldId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateLeadFormField$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateLeadFormField(params: {
    LeadFormId: string;
    LeadFormFieldId: string;
    body: LeadFormFieldDto
  }): Observable<void> {

    return this.updateLeadFormField$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
