<form [formGroup]="form" class="formgrid grid">
    <div class="field col-12">
        <label for="login">Логин</label>
        <input id="login" formControlName="login" type="text" class="w-full" placeholder="Ваш логин" pInputText />
    </div>

    <div class="field col-12">
        <label for="password">Пароль</label>
        <input id="password" formControlName="password" type="password" class="w-full" placeholder="Ваш пароль" pInputText />
    </div>

    <div class="field col-12 flex">
        <button pButton (click)="Cancel()" type="button" label="Отменить" class="ml-auto p-button-text"></button>
        <button pButton (click)="Confirm()" type="button" label="Войти" class="ml-3"></button>
    </div>
</form>