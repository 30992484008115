import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { StrictHttpResponse } from 'src/api/administration/strict-http-response';
import { CounterpartyDto } from 'src/api/legal/models';
import { CounterpartyService } from 'src/api/legal/services';
import { CounterpartyFieldViewModel, CounterpartyTypes, CounterpartyViewModel } from 'src/app/view-models/counterparty/counterparty-view-model';
import { CounterpartyAssemblerService } from '../assemblers/counterparty-assembler.service';

@Injectable({
  providedIn: 'root'
})
export class CounterpartyApiService {
  private _isLoading: boolean = false;
  public get isLoading(): boolean {
    return this._isLoading;
  }

  private _items: CounterpartyViewModel[] = [];
  public get items(): CounterpartyViewModel[] {
    return this._items;
  }

  private _itemsTotalCount: number = 0;
  public get itemsTotalCount(): number {
    return this._itemsTotalCount;
  }


  constructor(private api: CounterpartyService, private assembler: CounterpartyAssemblerService) { }

  public Sync(id: string): Observable<boolean> {
    return this.api.addCounterpartyToSyncQueue({
      entityId: id
    });
  }

  public Load(pageIndex: number = 1, pageSize: number = 10,
    filterName: string | null = null,
    filterInn: string | null = null,
    filterOgrn: string | null = null,
    filterTypes: string[] | null = null,
    filterSyncState: string | null = null,
    filterAcceptedByErir: boolean | null = null): Observable<boolean> {
    this._isLoading = true;
    this._items = [];

    return this.api.listCounterparties$Response({
      page: pageIndex,
      pageSize: pageSize,
      filterName: (filterName && filterName !== '') ? filterName : undefined,
      filterInn: (filterInn && filterInn !== '') ? filterInn : undefined,
      filterOgrn: (filterOgrn && filterOgrn !== '') ? filterOgrn : undefined,
      filterTypes: (filterTypes && filterTypes.length > 0) ? filterTypes : undefined,
      filterSyncState: (filterSyncState && filterSyncState !== '') ? filterSyncState : undefined,
      filterAcceptedByErir: (filterAcceptedByErir != null) ? filterAcceptedByErir : undefined
    }).pipe(
      map(x => {
        const totaCountRaw = x.headers.get('x-total-count');
        
        if (totaCountRaw) {
          this._itemsTotalCount = parseInt(totaCountRaw, 10);
        }

        return x.body as Array<CounterpartyDto>;
      }),
      map(response => {
        this._isLoading = false;

        this._items = response.map(x => this.assembler.ToViewModel(x));

        this.Enrich(this._items);

        return true;
      })
    );
  }

  public Create(model: CounterpartyViewModel): Observable<string> {
    const dto = this.assembler.ToDto(model);

    return this.api.createCounterpary({
      body: dto
    });
  }

  public Update(id: string, model: CounterpartyViewModel): Observable<boolean> {
    const dto = this.assembler.ToDto(model);

    return this.api.updateCounterparty({
      entityId: id,
      body: dto
    });
  }

  public CreateFields(id: string, models: CounterpartyFieldViewModel[]): Observable<boolean> {
    const dtos = models.map(model => this.assembler.FieldToDto(model));

    if (dtos.length === 0) {
      // TODO Ignore request
    }

    return this.api.createCounterpartyFields({
      entityId: id,
      body: dtos
    });
  }

  public UpdateFields(id: string, models: CounterpartyFieldViewModel[]): Observable<boolean> {
    const dtos = models.map(model => this.assembler.FieldToDto(model));

    if (dtos.length === 0) {
      // TODO Ignore request
    }

    return this.api.updateCounterpartyFields({
      entityId: id,
      body: dtos
    });
  }

  public GetByIds(ids: string[]): Observable<CounterpartyViewModel[]> {
    return this.api.getCounterpartyByIds({
      ids: ids
    }).pipe(
      map(response => {
        const items = response.map(x => this.assembler.ToViewModel(x));

        this.Enrich(items);

        return items;
      })
    );
  }

  private Enrich(items: CounterpartyViewModel[]): void {
    items.forEach(x => {
      x.type = CounterpartyTypes.find(type => type.code === x.typeCode) ?? null;
    });
  }
}
