/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ListPlatformsQuery } from '../models/list-platforms-query';
import { PlatformDto } from '../models/platform-dto';
import { PlatformObjectDto } from '../models/platform-object-dto';
import { PlatformObjectFieldDto } from '../models/platform-object-field-dto';

@Injectable({
  providedIn: 'root',
})
export class PlatformService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation listPlatforms
   */
  static readonly ListPlatformsPath = '/api/integration-platform';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listPlatforms()` instead.
   *
   * This method doesn't expect any request body.
   */
  listPlatforms$Response(params?: {
    query?: ListPlatformsQuery;
  }): Observable<StrictHttpResponse<Array<PlatformDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PlatformService.ListPlatformsPath, 'get');
    if (params) {
      rb.query('query', params.query, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PlatformDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listPlatforms$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listPlatforms(params?: {
    query?: ListPlatformsQuery;
  }): Observable<Array<PlatformDto>> {

    return this.listPlatforms$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PlatformDto>>) => r.body as Array<PlatformDto>)
    );
  }

  /**
   * Path part for operation createPlatform
   */
  static readonly CreatePlatformPath = '/api/integration-platform';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPlatform()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPlatform$Response(params?: {
    body?: PlatformDto
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, PlatformService.CreatePlatformPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createPlatform$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPlatform(params?: {
    body?: PlatformDto
  }): Observable<string> {

    return this.createPlatform$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getPlatformById
   */
  static readonly GetPlatformByIdPath = '/api/integration-platform/{Id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPlatformById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlatformById$Response(params: {
    Id: string;
  }): Observable<StrictHttpResponse<PlatformDto>> {

    const rb = new RequestBuilder(this.rootUrl, PlatformService.GetPlatformByIdPath, 'get');
    if (params) {
      rb.path('Id', params.Id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlatformDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPlatformById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlatformById(params: {
    Id: string;
  }): Observable<PlatformDto> {

    return this.getPlatformById$Response(params).pipe(
      map((r: StrictHttpResponse<PlatformDto>) => r.body as PlatformDto)
    );
  }

  /**
   * Path part for operation updatePlatform
   */
  static readonly UpdatePlatformPath = '/api/integration-platform/{Id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePlatform()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePlatform$Response(params: {
    Id: string;
    body?: PlatformDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PlatformService.UpdatePlatformPath, 'put');
    if (params) {
      rb.path('Id', params.Id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updatePlatform$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePlatform(params: {
    Id: string;
    body?: PlatformDto
  }): Observable<void> {

    return this.updatePlatform$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation createPlatformObject
   */
  static readonly CreatePlatformObjectPath = '/api/integration-platform/{Id}/objects';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPlatformObject()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPlatformObject$Response(params: {
    Id: string;
    body?: PlatformObjectDto
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, PlatformService.CreatePlatformObjectPath, 'post');
    if (params) {
      rb.path('Id', params.Id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createPlatformObject$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPlatformObject(params: {
    Id: string;
    body?: PlatformObjectDto
  }): Observable<string> {

    return this.createPlatformObject$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation updatePlatformObject
   */
  static readonly UpdatePlatformObjectPath = '/api/integration-platform/{Id}/objects/{ObjectId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePlatformObject()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePlatformObject$Response(params: {
    Id: string;
    ObjectId: string;
    body?: PlatformObjectDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PlatformService.UpdatePlatformObjectPath, 'put');
    if (params) {
      rb.path('Id', params.Id, {});
      rb.path('ObjectId', params.ObjectId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updatePlatformObject$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePlatformObject(params: {
    Id: string;
    ObjectId: string;
    body?: PlatformObjectDto
  }): Observable<void> {

    return this.updatePlatformObject$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation createPlatformObjectField
   */
  static readonly CreatePlatformObjectFieldPath = '/api/integration-platform/{Id}/objects/{ObjectId}/fields';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPlatformObjectField()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPlatformObjectField$Response(params: {
    Id: string;
    ObjectId: string;
    body?: PlatformObjectFieldDto
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, PlatformService.CreatePlatformObjectFieldPath, 'post');
    if (params) {
      rb.path('Id', params.Id, {});
      rb.path('ObjectId', params.ObjectId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createPlatformObjectField$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPlatformObjectField(params: {
    Id: string;
    ObjectId: string;
    body?: PlatformObjectFieldDto
  }): Observable<string> {

    return this.createPlatformObjectField$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation assignApiKeyToPlatform
   */
  static readonly AssignApiKeyToPlatformPath = '/api/integration-platform/{Id}/apikey/{ApiKeyId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `assignApiKeyToPlatform()` instead.
   *
   * This method doesn't expect any request body.
   */
  assignApiKeyToPlatform$Response(params: {
    Id: string;
    ApiKeyId: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PlatformService.AssignApiKeyToPlatformPath, 'post');
    if (params) {
      rb.path('Id', params.Id, {});
      rb.path('ApiKeyId', params.ApiKeyId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `assignApiKeyToPlatform$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  assignApiKeyToPlatform(params: {
    Id: string;
    ApiKeyId: string;
  }): Observable<void> {

    return this.assignApiKeyToPlatform$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
