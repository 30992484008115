<p-panel header="Инструменты поиска" toggler="header" [toggleable]="true" [collapsed]="true">
    <div class="grid">
        <div class="col-12 sm:col-6 md:col-3">
            <label>Типы договора</label>
            <p-multiSelect [options]="types" [(ngModel)]="selectedTypes" [showClear]="true"
                styleClass="w-full" placeholder="Любые" display="chip"
                optionLabel="name"  optionValue="code"></p-multiSelect>
        </div>

        <div class="col-12 sm:col-6 md:col-3">
            <label>Предметы договора</label>
            <p-multiSelect [options]="subjects" [(ngModel)]="selectedSubjects" [showClear]="true"
                styleClass="w-full" placeholder="Любые" display="chip"
                optionLabel="name"  optionValue="code"></p-multiSelect>
        </div>

        <div class="col-12 sm:col-6 md:col-3">
            <label pTooltip="Включительно указанную дату">Дата заключения (после)</label>
            <p-calendar  [(ngModel)]="dateOfConclusionAfter" [showClear]="true"
            [touchUI]="true" dateFormat="yy-mm-dd"
            styleClass="w-full" placeholder="До любой"></p-calendar>
        </div>

        <div class="col-12 sm:col-6 md:col-3">
            <label pTooltip="Включительно указанную дату">Дата заключения (до)</label>
            <p-calendar  [(ngModel)]="dateOfConclusionBefore" [showClear]="true"
            [touchUI]="true" dateFormat="yy-mm-dd"
            styleClass="w-full" placeholder="После любой"></p-calendar>
        </div>

        <div class="col-12 sm:col-6 md:col-3">
            <label>Родительский договор</label>
            <p-dropdown [options]="parentContracts" [(ngModel)]="parentContractId"
                [filter]="true" [filterPlaceholder]="'Поиск по номеру договора'" [showClear]="true"
                optionLabel="serialNumber" optionValue="id"
                styleClass="w-full" placeholder="Любой">
                <ng-template let-model pTemplate="item">
                    <div *ngIf="model.client" class="text-muted text-small">
                        {{model.client.type.name}} {{model.client.name}} (ИНН: {{model.client.inn}})
                    </div>
                    <div>
                        {{model.type.name}} <strong>{{model.serialNumber}}</strong> от <span class="text-muted">{{model.dateOfConclusion}}</span>
                    </div>
                    <div class="text-small">
                        {{model.amount|number}} руб.
                    </div>
                </ng-template>
            </p-dropdown>
        </div>

        <div class="col-12 sm:col-6 md:col-3">
            <label>Заказчик</label>
            <p-dropdown [options]="counterparties" [(ngModel)]="clientId"
                [filter]="true" filterBy="name" [showClear]="true"
                optionLabel="name" optionValue="id"
                styleClass="w-full" placeholder="Любой">

            </p-dropdown>
        </div>

        <div class="col-12 sm:col-6 md:col-3">
            <label>Статус передачи в ОРД</label>
            <p-dropdown [options]="syncStates" [(ngModel)]="syncState" [showClear]="true"
                optionLabel="name" optionValue="code"
                styleClass="w-full" placeholder="Любой">

            </p-dropdown>
        </div>

        <div class="col-12 sm:col-6 md:col-3 flex align-items-center">
            <div class="mt-4 flex align-items-center">
                <label class="mr-3">Принято ЕРИР</label>

                <p-triStateCheckbox class="flex" [(ngModel)]="acceptedByErir" inputId="acceptedByErir"></p-triStateCheckbox>
            </div>
        </div>

        <div class="col-12"></div>
        <div class="col"></div>

        <div class="col-12 sm:col-6 md:col-3">
            <p-button (click)="reset()" icon="pi pi-times" label="Очистить форму" styleClass="w-full p-button-text p-button-danger"></p-button>
        </div>

        <div class="col-12 sm:col-6 md:col-3">
            <p-button (click)="send()" icon="pi pi-search" label="Выполнить поиск" styleClass="w-full p-button-outlined"></p-button>
        </div>
    </div>
</p-panel>