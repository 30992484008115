/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { IntegrationDto } from '../models/integration-dto';
import { IntegrationMappingDto } from '../models/integration-mapping-dto';

@Injectable({
  providedIn: 'root',
})
export class IntegrationService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation listIntegrations
   */
  static readonly ListIntegrationsPath = '/api/integration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listIntegrations()` instead.
   *
   * This method doesn't expect any request body.
   */
  listIntegrations$Response(params?: {
    SourceObjectId?: string;
    SourceEntityId?: string;
  }): Observable<StrictHttpResponse<Array<IntegrationDto>>> {

    const rb = new RequestBuilder(this.rootUrl, IntegrationService.ListIntegrationsPath, 'get');
    if (params) {
      rb.query('SourceObjectId', params.SourceObjectId, {});
      rb.query('SourceEntityId', params.SourceEntityId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<IntegrationDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listIntegrations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listIntegrations(params?: {
    SourceObjectId?: string;
    SourceEntityId?: string;
  }): Observable<Array<IntegrationDto>> {

    return this.listIntegrations$Response(params).pipe(
      map((r: StrictHttpResponse<Array<IntegrationDto>>) => r.body as Array<IntegrationDto>)
    );
  }

  /**
   * Path part for operation createIntegration
   */
  static readonly CreateIntegrationPath = '/api/integration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createIntegration()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createIntegration$Response(params?: {
    body?: IntegrationDto
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, IntegrationService.CreateIntegrationPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createIntegration$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createIntegration(params?: {
    body?: IntegrationDto
  }): Observable<string> {

    return this.createIntegration$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getIntegrationById
   */
  static readonly GetIntegrationByIdPath = '/api/integration/{Id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIntegrationById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIntegrationById$Response(params: {
    Id: string;
  }): Observable<StrictHttpResponse<IntegrationDto>> {

    const rb = new RequestBuilder(this.rootUrl, IntegrationService.GetIntegrationByIdPath, 'get');
    if (params) {
      rb.path('Id', params.Id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IntegrationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getIntegrationById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIntegrationById(params: {
    Id: string;
  }): Observable<IntegrationDto> {

    return this.getIntegrationById$Response(params).pipe(
      map((r: StrictHttpResponse<IntegrationDto>) => r.body as IntegrationDto)
    );
  }

  /**
   * Path part for operation updateIntegration
   */
  static readonly UpdateIntegrationPath = '/api/integration/{Id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateIntegration()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateIntegration$Response(params: {
    Id: string;
    body?: IntegrationDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, IntegrationService.UpdateIntegrationPath, 'put');
    if (params) {
      rb.path('Id', params.Id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateIntegration$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateIntegration(params: {
    Id: string;
    body?: IntegrationDto
  }): Observable<void> {

    return this.updateIntegration$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation createIntegrationMapping
   */
  static readonly CreateIntegrationMappingPath = '/api/integration/{Id}/mappings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createIntegrationMapping()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createIntegrationMapping$Response(params: {
    Id: string;
    body?: IntegrationMappingDto
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, IntegrationService.CreateIntegrationMappingPath, 'post');
    if (params) {
      rb.path('Id', params.Id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createIntegrationMapping$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createIntegrationMapping(params: {
    Id: string;
    body?: IntegrationMappingDto
  }): Observable<string> {

    return this.createIntegrationMapping$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation updateIntegrationMapping
   */
  static readonly UpdateIntegrationMappingPath = '/api/integration/{Id}/mappings/{MappingId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateIntegrationMapping()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateIntegrationMapping$Response(params: {
    Id: string;
    MappingId: string;
    body?: IntegrationMappingDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, IntegrationService.UpdateIntegrationMappingPath, 'put');
    if (params) {
      rb.path('Id', params.Id, {});
      rb.path('MappingId', params.MappingId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateIntegrationMapping$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateIntegrationMapping(params: {
    Id: string;
    MappingId: string;
    body?: IntegrationMappingDto
  }): Observable<void> {

    return this.updateIntegrationMapping$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation removeIntegrationMapping
   */
  static readonly RemoveIntegrationMappingPath = '/api/integration/{Id}/mappings/{MappingId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeIntegrationMapping()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeIntegrationMapping$Response(params: {
    Id: string;
    MappingId: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, IntegrationService.RemoveIntegrationMappingPath, 'delete');
    if (params) {
      rb.path('Id', params.Id, {});
      rb.path('MappingId', params.MappingId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeIntegrationMapping$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeIntegrationMapping(params: {
    Id: string;
    MappingId: string;
  }): Observable<void> {

    return this.removeIntegrationMapping$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation updateIntegrationStatus
   */
  static readonly UpdateIntegrationStatusPath = '/api/integration/{Id}/status/{Status}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateIntegrationStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateIntegrationStatus$Response(params: {
    Id: string;
    Status: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, IntegrationService.UpdateIntegrationStatusPath, 'post');
    if (params) {
      rb.path('Id', params.Id, {});
      rb.path('Status', params.Status, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateIntegrationStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateIntegrationStatus(params: {
    Id: string;
    Status: string;
  }): Observable<void> {

    return this.updateIntegrationStatus$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
