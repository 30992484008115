<div *ngIf="counterparty; else noCounterparty">
    <div *ngIf="counterparty.type" class="text-small text-muted">
        {{counterparty.type.name}}
    </div>

    <div>
        {{counterparty.name}}
    </div>

    
    <div class="flex text-small mt-2">
        <p-tag styleClass="p-tag-outline mr-2"
        [severity]="counterparty.isSynced ? 'success' : counterparty.isSyncing ? 'warning' : 'danger'" 
        [icon]="counterparty.isSynced ? 'pi pi-check' : counterparty.isSyncing ? 'pi pi-clock' : 'pi pi-send'"
        [value]="counterparty.isSynced ? 'Передано в ОРД' : counterparty.isSyncing ? 'Передаётся в ОРД' : 'Ещё не передавалось в ОРД'"></p-tag>
    </div>
</div>
<ng-template #noCounterparty>
    Loading...
</ng-template>