import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UserViewModel } from 'src/app/view-models/user/user-view-model';

@Component({
  selector: 'app-sign-in-dialogue',
  templateUrl: './sign-in-dialogue.component.html',
  styleUrls: ['./sign-in-dialogue.component.scss']
})
export class SignInDialogueComponent implements OnInit {
  public form: FormGroup;
  
  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
    this.form = new FormGroup({
      login: new FormControl({
        value: '',
        disabled: false
      }, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(32)
      ]),
      password: new FormControl({
        value: '',
        disabled: false
      }, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(128)
      ])
    });
  }

  ngOnInit(): void {
  }

  public Cancel(): void {
    this.ref.close(null);
  }

  public Confirm(): void {
    if (!this.form.valid) {
      return; // TODO Iform about validation error
    }
    
    const values = this.form.getRawValue();

    const model = new UserViewModel(values.login);
    model.password = values.password;

    this.ref.close(model);
  }
}
