<h1>
    Договоры
</h1>

<div class="mt-5">
    <div class="grid">
        <div class="col-12 sm:col-10">
            <div class="flex align-items-center h-full">
                Здесь отображается список всех созданных договоров.
            </div>
        </div>

        <div class="col-12 sm:col-2">
            <button pButton type="button" label="Создать" class="w-full" (click)="CreateItemClicked()"></button>
        </div>
    </div>
</div>

<div class="mt-3">
    <app-contract-search-form (onQueryChanged)="QueryChanged($event)"></app-contract-search-form>
</div>

<div class="mt-3 fancy-card">
    <div class="fancy-card-content">
        <p-table [scrollable]="true" scrollHeight="80vh" [tableStyle]="{'min-width': '50rem'}" styleClass="p-datatable-gridlines"
                [value]="items" [loading]="isLoading" dataKey="id" [showCurrentPageReport]="true"
                [lazy]="true" (onLazyLoad)="OnLazyLoad($event)"
                [paginator]="true" [rows]="10" [totalRecords]="itemsTotalCount" [rowsPerPageOptions]="[10,25,50]"
                currentPageReportTemplate="Показаны с {first} по {last} из {totalRecords} строчек" >
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="min-width: 300px;" pFrozenColumn>Номер</th>
                            <th  style="min-width: 300px;">Заказчик</th>
                            <th style="min-width: 200px;">Дата заключения</th>
                            <th style="min-width: 150px;">Сумма</th>
                            
                            <th  style="min-width: 230px;">Подача актов</th>
                            <th  style="min-width: 230px;">Последний акт</th>
                            <th style="min-width: 250px;" alignFrozen="right" pFrozenColumn>Операции</th>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-model>
                        <tr>
                            <td style="min-width: 300px;" class="flex-column align-items-start" pFrozenColumn>
                                <app-contract-snippet [contract]="model" [showAmount]="false"></app-contract-snippet>

                                <div class="text-small text-muted">Создано {{model.createdAt |date}}</div>

                                <p-button *ngIf="model.hasCreatives" (click)="ViewStatistics(model)" styleClass="mt-3 p-button-text p-button-sm p-button-secondary" icon="pi pi-chart-line" label="Статистика"></p-button>
                            </td>

                            <td style="min-width: 300px;" class="flex-column align-items-start">
                                <app-counterparty-snippet [counterparty]="model.client"></app-counterparty-snippet>
                            </td>

                            <td style="min-width: 200px;" class="flex-column align-items-start">
                                {{model.dateOfConclusion | date}}
                            </td>

                            <td style="min-width: 150px;" class="flex-column align-items-start">
                                <strong>{{model.amount | number}}</strong> руб.
                            </td>

                            <td style="min-width: 230px;" class="flex-column align-items-start">
                                <ng-container *ngIf="model.invoiceAutomation; else noInvoiceAutomation">
                                    <p-message severity="success" text="Автоматически" class="w-full" styleClass="w-full" pTooltip="Акты для этого договора будут создавать и передаваться автоматически"></p-message>  

                                    <div class="mt-3 text-small text-muted text-center w-full" pTooltip="Это прогресс автоматизации передачи актов, т.е. на какой дате остановился модуль автоматизации при сборе статистики">
                                        {{model.invoiceAutomationProgress | date}}
                                    </div>
                                </ng-container>
                                <ng-template #noInvoiceAutomation>
                                    <p-message severity="warn" text="Вручную" class="w-full" styleClass="w-full" pTooltip="Вы должны самостоятельно формировать акты и передавать для этого договора!"></p-message> 
                                </ng-template>
                            </td>

                            <td  style="min-width: 230px;" class="flex-column align-items-start">
                            
                                <ng-container *ngIf="model.latestInvoice; else noInvoices">
                                    <div class="text-small">
                                        с {{model.latestInvoice.dateStart| date}}
                                    </div>
                                    <div class="text-small mb-3">
                                        по {{model.latestInvoice.dateEnd| date}}
                                    </div>
                                    <div *ngIf="model.invoiceAutomation">
                                        <ng-container *ngIf="model.latestInvoice.isSynced; else notSynced">
                                            <i [ngClass]="{'text-small flex-shrink-0 pi': true, 
                                            'pi-check success': model.latestInvoice.isAcceptedByErir, 
                                            'pi-clock warning': !model.latestInvoice.isAcceptedByErir}"></i>

                                            <span class="text-muted text-small ml-2 mr-2">{{model.latestInvoice.isAcceptedByErir ? 'Принято ЕРИР' : 'Пока не принято ЕРИР'}}</span>
                                        </ng-container>
                                        <ng-template #notSynced>
                                            <div>
                                                <ng-container *ngIf="model.latestInvoice.isSyncing; else notSyncing">
                                                    <span class="text-small text-muted">Передаётся в ОРД...</span>
                                                </ng-container>
                                                <ng-template #notSyncing>
                                                    <p-message severity="error" text="Не передаётся в ОРД!" class="w-full" styleClass="w-full"></p-message>  
                                                </ng-template>
                                                
                                            </div>
                                        </ng-template>
                                    </div>
                                </ng-container>
                                <ng-template #noInvoices>
                                    <div class="text-muted">
                                        Нет
                                    </div>
                                </ng-template>
                            </td>

                            <td style="min-width: 250px;" class="flex-column align-items-start" alignFrozen="right" pFrozenColumn>
                                <div class="grid">
                                    <div class="col-12">
                                        <button pButton type="button" icon="pi pi-pencil" label="Правка" class="w-full p-button-sm p-button-text" (click)="UpdateItemClicked(model)"></button>
                                    </div>
                                    <div class="col-12">
                                        <app-sync-button 
                                        [erirId]="model.erirId"
                                        [isAcceptedByErir]="model.isAcceptedByErir" 
                                        [isSyncing]="model.isSyncing" 
                                        [isSynced]="model.isSynced" 
                                        (onClicked)="SyncClick(model)"></app-sync-button>
                                    </div>
                                </div>
                            </td>
                        </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<!-- <div  class="mt-3 px-3 text-small">
    Креативы к договору (для получения маркеров) можно будет добавить после создания договора.<br>
    Для получения маркера (для креатива) необходимо зайти в договор и добавить креатив в разделе "Маркрировка креативов", затем нажать на кнопку "передать в ОРД". После получения маркера при редактировании креатива его данные будут переданы автоматически.
</div> -->

<p-confirmDialog acceptIcon="pi pi-send" acceptLabel="Передать в ОРД" rejectLabel="Отмена" rejectButtonStyleClass="p-button-danger p-button-text" [style]="{width: '50vw'}"></p-confirmDialog>