<ng-container *ngIf="field && field.values && field.values.length > 0;  else noValues">
    <div class="flex flex-wrap gap-2">
        <div *ngFor="let value of field.values"
            class="py-1 px-2 bg-blue-50 border-1 border-blue-100 border-round text-small cursor-pointer"
            pTooltip="Копировать это значение поля" ngxClipboard [cbContent]="value.value">
            <span>{{value.value}}</span>

            <i class="ml-2 text-color-secondary fa-regular fa-copy"></i>
        </div>
    </div>
</ng-container>
<ng-template #noValues>
    <div class="text-color-secondary text-small w-full">
        Нет данных
    </div>
</ng-template>