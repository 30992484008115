import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CreativeDto } from 'src/api/legal/models';
import { CreativeService } from 'src/api/legal/services';
import { CreativeCampaignTypes, CreativeContractViewModel, CreativeEconomicClassificationViewModel, CreativeForms, CreativeViewModel } from 'src/app/view-models/creative/creative-view-model';
import { CreativeAssemblerService } from '../assemblers/creative-assembler.service';

@Injectable({
  providedIn: 'root'
})
export class CreativeApiService {
  private _isLoading: boolean = false;
  public get isLoading(): boolean {
    return this._isLoading;
  }

  private _items: CreativeViewModel[] = [];
  public get items(): CreativeViewModel[] {
    return this._items;
  }

  private _itemsTotalCount: number = 0;
  public get itemsTotalCount(): number {
    return this._itemsTotalCount;
  }

  constructor(private api: CreativeService, private assembler: CreativeAssemblerService) { }

  public SyncSelfAdvertisement(creativeId: string): Observable<boolean> {
    return this.api.addSelfAdvertisementCreativeToSyncQueue({
      entityId: creativeId
    });
  }

  public Sync(creativeId: string, contractId: string): Observable<boolean> {
    return this.api.addCreativeToSyncQueue({
      entityId: creativeId,
      contractId: contractId
    });
  }

  public Load(pageIndex: number = 1, pageSize: number = 10,
    filterText: string | null = null,
    filterForms: string[] | null = null,
    filterCampaignTypes: string[] | null = null,
    filterOkveds: string[] | null = null ): Observable<CreativeViewModel[]> {
    this._isLoading = true;
    this._items = [];

    return this.api.listCreatives$Response({
      page: pageIndex,
      pageSize: pageSize,
      filterText: (filterText && filterText !== '') ? filterText : undefined,
      filterForms: (filterForms && filterForms.length > 0) ? filterForms : undefined,
      filterCampaignTypes: (filterCampaignTypes && filterCampaignTypes.length > 0) ? filterCampaignTypes : undefined,
      filterOkveds: (filterOkveds && filterOkveds.length > 0) ? filterOkveds : undefined,
    }).pipe(
      map(x => {
        const totaCountRaw = x.headers.get('x-total-count');
        
        if (totaCountRaw) {
          this._itemsTotalCount = parseInt(totaCountRaw, 10);
        }

        return x.body as Array<CreativeDto>;
      }),
      map(response => {
        this._isLoading = false;

        this._items = response.map(x => this.assembler.ToViewModel(x));

        this._items.forEach(item => {
          item.form = CreativeForms.find(x => x.code === item.formCode) ?? null;
          item.campaignType = CreativeCampaignTypes.find(x => x.code === item.campaignTypeCode) ?? null;
        });

        return this._items;
      })
    );
  }

  public GetByIds(ids: string[]): Observable<CreativeViewModel[]> {
    return this.api.getCreativesByIds({
      ids: ids
    }).pipe(
      map(response => {
        return response.map(x => this.assembler.ToViewModel(x));
      })
    );
  }

  public Create(model: CreativeViewModel): Observable<string> {
    const dto = this.assembler.ToDto(model);

    return this.api.createCreative({
      body: dto
    });
  }

  public Update(id: string, model: CreativeViewModel): Observable<boolean> {
    const dto = this.assembler.ToDto(model);

    return this.api.updateCreative({
      entityId: id,
      body: dto
    });
  }

  public AddContract(id: string, model: CreativeContractViewModel): Observable<string> {
    const dto = this.assembler.ToCreativeContractDto(model);

    return this.api.addCreativeToContract({
      entityId: id,
      body: dto
    });
  }

  public RemoveContract(id: string, contractId: string): Observable<boolean> {
    return this.api.removeCreativeFromContract({
      entityId: id,
      contractId: contractId
    });
  }

  public AddEconomicsClassifications(id: string, economicsClassifications: CreativeEconomicClassificationViewModel[]): Observable<boolean>{
    return this.api.addEconomicsClassificationToCreative({
      entityId: id,
      body: economicsClassifications.map(x => this.assembler.ToEconomicClassificationDto(x))
    });
  }

  public RemoveEconomicsClassifications(id: string, economicsClassificationIds: string[]): Observable<boolean> {
    return this.api.removeEconomicsClassificationFromCreative({
      entityId: id,
      body: economicsClassificationIds
    });
  }
}
