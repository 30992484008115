import { Injectable } from '@angular/core';
import { Observable, concat, defer, forkJoin, from, merge, of, zip } from 'rxjs';
import { last, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { InvoiceDto } from 'src/api/legal/models';
import { InvoiceService } from 'src/api/legal/services';
import { InvoiceItemCreativeAggregationViewModel, InvoiceItemViewModel, InvoiceViewModel } from 'src/app/view-models/invoice/invoice-view-model';
import { InvoiceAssemblerService } from '../assemblers/invoice-assembler.service';
import { ContractViewModel } from 'src/app/view-models/contract/contract-view-model';
import { StatsApiService } from './stats-api.service';

@Injectable({
  providedIn: 'root'
})
export class InvoiceApiService {
  private _isLoading: boolean = false;
  public get isLoading(): boolean {
    return this._isLoading;
  }

  private _items: InvoiceViewModel[] = [];
  public get items(): InvoiceViewModel[] {
    return this._items;
  }

  private _itemsTotalCount: number = 0;
  public get itemsTotalCount(): number {
    return this._itemsTotalCount;
  }


  constructor(private api: InvoiceService, private assembler: InvoiceAssemblerService,
    private statsApi: StatsApiService) { }

  public Sync(id: string): Observable<boolean> {
    return this.api.addInvoiceToSyncQueue({
      entityId: id
    });
  }

  public Load(pageIndex: number = 1, pageSize: number = 10): Observable<boolean> {
    this._isLoading = true;
    this._items = [];

    return this.api.listInvoices$Response({
      page: pageIndex,
      pageSize: pageSize
    }).pipe(
      map(x => {
        const totaCountRaw = x.headers.get('x-total-count');

        if (totaCountRaw) {
          this._itemsTotalCount = parseInt(totaCountRaw, 10);
        }

        return x.body as Array<InvoiceDto>;
      }),
      map(response => {
        this._isLoading = false;

        this._items = response.map(x => this.assembler.ToViewModel(x));

        return true;
      })
    );
  }

  public LoadByContracts(contractIds: string[]): Observable<InvoiceViewModel[]> {
    return this.api.listLatestAggregatedByContracts({
      body: contractIds
    }).pipe(
      map(response => {
        return response.map(x => this.assembler.ToViewModel(x));
      })
    );
  }

  public Create(model: InvoiceViewModel): Observable<string> {
    console.log('Creating invoice', model);
    const dto = this.assembler.ToDto(model);

    return this.api.createInvoice({
      body: dto,
    });
  }

  public CreateFromContract(contract: ContractViewModel, platformId: string, dateStart: Date, dateEnd: Date): Observable<string> {
    // Создать акт, прилинкованный к договору и вбить туда указанные даты начала и конца
    const invoice = new InvoiceViewModel(contract.id ?? '', 'АКТ-ВРЕМЕННЫЙ', this.statsApi.ToDateToString(new Date()), this.statsApi.ToDateToString(dateStart), this.statsApi.ToDateToString(dateEnd), 0);

    const item = new InvoiceItemViewModel(contract.id ?? '', 0);


    const requests: Observable<void>[] = [];
    contract.creatives.forEach(creative => {
      if (contract.creatives && contract.creatives.length > 0) {

        const request = this.statsApi.GetStatistics('creative-contract', creative.id ?? '', this.statsApi.ToDateToString(dateStart), this.statsApi.ToDateToString(dateEnd)).pipe(
          map(stats => {
            const sum = stats.reduce((sum, current) => sum + current.impressions, 0);
            
            const aggregation = new InvoiceItemCreativeAggregationViewModel(creative.creativeId ?? '', platformId, sum, 0);

            
            item.addCreativeAggregation(aggregation);

            console.log('APPENDING AGGREGATION TO ITEM',aggregation , item);
          })
        );

        requests.push(request);
      }
    });

    
    return forkJoin(requests).pipe(
      map(x => {
        invoice.addItem(item);
        console.log('GATHERED STATS OF ITEM', item);
        return '';
      }),
      switchMap(() => this.Create(invoice))
    );
  }

  public Update(entityId: string, model: InvoiceViewModel, removeCreativeAggregationIds: string[]): Observable<boolean> {
    const dto = this.assembler.ToDto(model);

    return this.api.updateInvoice({
      invoiceId: entityId,
      removeItemIds: removeCreativeAggregationIds,
      body: dto,
    });
  }
}
