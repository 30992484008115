import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PlatformService } from 'src/api/legal/services';
import { PlatformViewModel as PlatformViewModel } from 'src/app/view-models/platform/platform-view-model';
import { PlatformAssemblerService } from '../assemblers/platform-assembler.service';

@Injectable({
  providedIn: 'root'
})
export class PlatformApiService {
  private _isLoading: boolean = false;
  public get isLoading(): boolean {
    return this._isLoading;
  }

  private _items: PlatformViewModel[] = [];
  public get items(): PlatformViewModel[] {
    return this._items;
  }

  private _itemsTotalCount: number = 0;
  public get itemsTotalCount(): number {
    return this._itemsTotalCount;
  }


  constructor(private api: PlatformService, private assembler: PlatformAssemblerService) { }

  public Load(): Observable<boolean> {
    this._isLoading = true;
    this._items = [];

    return this.api.listPlatforms().pipe(
      map(response => {
        this._isLoading = false;

        this._items = response.map(x => this.assembler.ToViewModel(x));

        return true;
      })
    );
  }
}
