import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { UserDialogComponent } from 'src/app/dialogues/user-dialog/user-dialog.component';
import { AuthService } from 'src/app/services/auth.service';
import { UserRepositoryService } from 'src/app/services/repositories/user-repository.service';
import { UserViewModel } from 'src/app/view-models/user/user-view-model';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {

  public get isAdmin(): boolean {
    return this.authService.isAdmin;
  }

  public get totalAmount(): number {
    return this.userAPI.totalAmount;
  }

  private _users: UserViewModel[] = [];
  public get users(): UserViewModel[] {
    return this._users;
  }

  public isLoading: boolean = false;

  private _previousEvent: LazyLoadEvent | null = null;

  constructor(private userAPI: UserRepositoryService,
    private dialogService: DialogService,
    private authService: AuthService) { }

  ngOnInit(): void {
  }

  public OpenCreateDialog(): void {
    const ref = this.dialogService.open(UserDialogComponent, {
      header: 'Создание пользователя',
      width: '380px',
    });

    ref.onClose.subscribe((data: UserViewModel) => {
      if (data) {
        this.isLoading = true;
        this.userAPI.CreateUser(data).subscribe(x => {

          // Reload links
          this.LoadData(null);
        }, err => {
          this.isLoading = false;
        });
      }
    });
  }

  public OpenResetPasswordDialog(model: UserViewModel): void {
    const ref = this.dialogService.open(UserDialogComponent, {
      header: 'Изменение пароля',
      width: '380px',
      dismissableMask: true,
      data: {
        isEditing: false,
        login: model.login
      }
    });

    ref.onClose.subscribe((data: UserViewModel) => {
      if (data) {
        this.isLoading = true;
        this.userAPI.ResetPassword(model.id ?? '', data.password).subscribe(x => {

          // Reload links
          this.LoadData(null);
        }, err => {
          this.isLoading = false;
        });
      }
    });
  }

  public LoadData(event: LazyLoadEvent | null):void {
    let current = event ?? this._previousEvent;

    if (event) {
      this._previousEvent = event;
    }
    //this.loading = true;
    console.log(event);

    this.LoadViaAPI(current?.rows ?? 10, current?.first ?? 0);
  }

  private LoadViaAPI(limit: number, offset: number): void {
    this.isLoading = true;
    this._users = [];
    this.userAPI.ListUsers(offset, limit).subscribe(result => {
      this._users = result;
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
    });
  }
}
