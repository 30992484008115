import { IntegrationPlatform, IntegrationPlatformObject } from "./integration-platform";

/**
 * Объект интеграции, указывающий на  то откуда и куда нужно передавать данные
 */
export class Integration {
    public id: string | null = null;

    public name: string;

    public sourceObjectId: string;
    public source: IntegrationPlatform|null = null;
    public sourceObject: IntegrationPlatformObject|null = null;
    public sourceEntityId: string | null;

    public destinationObjectId: string|null = null;
    public destination: IntegrationPlatform|null = null;
    public destinationObject: IntegrationPlatformObject|null = null;

    public status: IntergrationStatus;

    public lastSyncAt: any;

    private _mapping: IntegrationMaping[] = [];
    public get mapping(): IntegrationMaping[] {
        return this._mapping;
    }

    constructor(sourceObjectId: string, sourceEntityId: string | null) {
        this.sourceObjectId = sourceObjectId;
        this.sourceEntityId = sourceEntityId;

        this.name = `Новая интеграция`;

        this.status = integrationStatuses[0];
    }

    public addMapping(mapping: IntegrationMaping): void {
        if (this._mapping.findIndex(x => x.sourceFieldId === mapping.sourceFieldId && x.destinationFieldId === mapping.destinationFieldId) >= 0) {
            return;
        }

        this._mapping.push(mapping);
    }
}

/**
 * Карта-маппинг одного значения поля в другое
 */
export class IntegrationMaping {
    public id: string | null = null;
    public sourceFieldId: string;
    public destinationFieldId: string;

    public format: string = '{value}';
    public subtype: string = '';

    public isDirty: boolean = false;
    public hasRemoveFlag: boolean = false;

    constructor(sourceFieldId: string, destinationFieldId: string) {
        this.sourceFieldId = sourceFieldId;
        this.destinationFieldId = destinationFieldId;
    }
}

export class IntergrationStatus {
    public codename: string;
    public name: string;

    constructor(codename: string, name: string) {
        this.codename = codename;
        this.name = name;
    }
}


export const integrationStatuses: IntergrationStatus[] = [
    new IntergrationStatus('stopped', 'Остановлена'),
    new IntergrationStatus('working', 'Работает'),
]