<form [formGroup]="form" class="formgrid grid">
    <div class="field col-12">
        <label for="login">Логин</label>
        <input id="login" formControlName="login" type="text" class="w-full" placeholder="Например to4ka_agency" pInputText />
    </div>

    <div class="field col-12">
        <label for="password">Пароль</label>
        <input id="password" formControlName="password" type="text" class="w-full" placeholder="Пароль пользователя" pInputText />
    </div>

    <div class="field col-12 flex">
        <button pButton (click)="Cancel()" type="button" label="Отменить" class="ml-auto p-button-text"></button>
        <button pButton (click)="Confirm()" type="button" [label]="blockLogin ? 'Изменить пароль' : 'Создать'" class="ml-3"></button>
    </div>
</form>