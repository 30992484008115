import * as moment from "moment";

export class UserViewModel {
    public id: string | null = null;
    public accountType: string = '';
    public get accountName(): string {
        switch(this.accountType) {
            case 'superadmin': return 'Супер-администратор';
            case 'admin': return 'Администратор';
            case 'visitor': return 'Посетитель';
            default: return '';
        }
    }
    
    public login: string = '';
    public password: string = '';

    public accessCodeGrantedAt: string | null = null;

    public get isOnline(): boolean {
        if (!this.accessCodeGrantedAt) {
            return false;
        }

        var now = moment(new Date()); //todays date
        var end = moment(new Date(this.accessCodeGrantedAt)); // another date
        var duration = moment.duration(now.diff(end));
        
        return duration.asMinutes() <= 10;
    }

    public get wasOnline(): string {
        if (!this.accessCodeGrantedAt) {
            return '';
        }

        var now = moment(new Date()); //todays date
        var end = moment(new Date(this.accessCodeGrantedAt)); // another date
        var duration = moment.duration(now.diff(end));
        
        return duration.humanize();
    }

    public get isAdminOrSuperadmin(): boolean {
        return this.accountType === 'admin' || this.accountType === 'superadmin';
    }

    constructor(login: string) {
        this.login = login;
    }
}