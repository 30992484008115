import { LeadFormField } from "./lead-form";

export class Lead {
    public readonly id: string;
    public readonly leadFormId: string;

    public referer: string|null = null;
    //public createdAt: string|null = null;
    public createdAtDate: string|null = null;
    public createdAtTime: string|null = null;

    private readonly _fields: LeadField[] = [];
    public get fields(): LeadField[] {
        return this._fields;
    }

    public readonly fieldMap: Dictionary<LeadField> = {};

    constructor(id: string, leadFormId: string) {
        this.id = id;
        this.leadFormId = leadFormId;
    }

    public addField(fieldId: string|null): LeadField {
        let field = this._fields.find(x => x.leadFormFieldId === fieldId);

        if (field) 
            return field;

        field = new LeadField(fieldId);

        this._fields.push(field);

        
        this.fieldMap[fieldId ?? ''] = field;

        return field;
    }

    public sortFields() {
        this.fields.sort((n1,n2) => {
            if (!n1.leadFormField) {
                return 1;
            }

            if (!n2.leadFormField) {
                return -1;
            }

            return n1.leadFormField.order - n2.leadFormField.order;
        });
    }
}

export class LeadField {
    public readonly leadFormFieldId: string | null = null;
    public leadFormField: LeadFormField|null = null;

    private readonly _values: LeadFormFieldValue[] = [];
    public get values(): LeadFormFieldValue[] {
        return this._values;
    }

    public get name(): string {
        return this.leadFormField?.name ?? 'Без поля';
    }

    constructor(leadFormFieldId: string | null) {
        this.leadFormFieldId = leadFormFieldId;
    }

    public addValue(value: LeadFormFieldValue): void {
        this._values.push(value);
    }
}

export class LeadFormFieldValue {
    public readonly id: string;
    public readonly value: string;

    constructor(id: string, value: string) {
        this.id = id;
        this.value = value;
    }
}

interface Dictionary<T> {
    [key: string]: T;
}