import { Injectable } from '@angular/core';
import { PlatformService } from 'src/api/integrations/services';
import { IntegrationPlatformAssemblerService } from '../assemblers/integration-platform-assembler.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IntegrationPlatform } from 'src/app/view-models/integrations/integration-platform';

@Injectable({
  providedIn: 'root'
})
export class IntegrationPlatformsService {

  constructor(private _api: PlatformService, private _assembler: IntegrationPlatformAssemblerService) { }

  public list(): Observable<IntegrationPlatform[]> {
    return this._api.listPlatforms().pipe(
      map(response => {
        return response.map(x => this._assembler.toViewModel(x));
      })
    );
  }
}
