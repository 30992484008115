export class CounterpartyViewModel {
    public id: string | null = null;

    public typeCode: string;
    public type: CounterpartyTypeViewModel | null = null;

    public name: string;

    public fields: CounterpartyFieldViewModel[] = [];

    public isSynced: boolean = false;
    public isSyncing: boolean = false;
    public isAcceptedByErir: boolean = false;
    public erirId: string = '';
    
    public createdAt: string = '';

    public get inn(): string | null {
        return this.getField('inn')?.value ?? null;
    }

    public get ogrn(): string | null {
        return this.getField('ogrn')?.value ?? null;
    }

    public get ogrnip(): string | null {
        return this.getField('ogrnip')?.value ?? null;
    }

    public get kpp(): string | null {
        return this.getField('kpp')?.value ?? null;
    }

    constructor(type: string, name: string) {
        this.typeCode = type;
        this.name = name;
    }

    public getField(type: string): CounterpartyFieldViewModel | null {
        return this.fields.find(x => x.type === type) ?? null;
    }

    public getFields(type: string): CounterpartyFieldViewModel[] {
        return this.fields.filter(x => x.type === type) ?? [];
    }

    public getFieldById(id: string): CounterpartyFieldViewModel | null {
        return this.fields.find(x => x.id === id) ?? null;
    }

    public upsertField(field: CounterpartyFieldViewModel): boolean {
        if (!field) {
            return false;
        }

        if (field.id) {
            const stored = this.getFieldById(field.id);

            if (stored) {
                stored.patch(field);

                return true;
            }
        }

        this.fields.push(field);

        return true;
    }
}

export class CounterpartyFieldViewModel {
    public id: string | null = null;

    public type: string;
    public value: string;

    public isSynced: boolean = false;

    constructor(type: string, value: string) {
        this.type = type;
        this.value = value;
    }

    public patch(other:CounterpartyFieldViewModel): void {
        this.type = other.type;
        this.value = other.value;
    }

    public updateValue(value: string): void {
        if (value !== this.value){
            this.isSynced = false;
        }

        this.value = value;
    }
}

export class CounterpartyTypeViewModel {
    public code: string;
    public name: string;

    constructor(code: string, name: string) {
        this.code = code;
        this.name = name;
    }
}

export const CounterpartyTypes: CounterpartyTypeViewModel[] = [
    new CounterpartyTypeViewModel('individual','Физическое лицо'),
    new CounterpartyTypeViewModel('legalEntity','Юридическое лицо'),
    new CounterpartyTypeViewModel('individualEntrepreneur','Индивидуальный предприниматель'),
];