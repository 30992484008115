import { Injectable } from '@angular/core';
import { LeadService } from 'src/api/leads/services';
import { LeadAssemblerService } from '../assemblers/lead-assembler.service';
import { Observable } from 'rxjs';
import { Lead } from 'src/app/view-models/leads/lead';
import { map } from 'rxjs/operators';
import { LeadDto } from 'src/api/leads/models';
import { LeadStats } from 'src/app/view-models/leads/lead-stats';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class LeadApiService {

  constructor(private _api: LeadService, private _assembler: LeadAssemblerService) { }

  public fetch(leadFormIds: string[], page: number = 1, pageSize: number = 50): Observable<[Lead[], number]> {
    return this._api.listLeads$Response({
      LeadFormIds: leadFormIds,
      Page: page,
      PageSize: pageSize
    }).pipe(
      map(response => {
        const totaCountRaw = response.headers.get('x-total-count');
        let totalCount = 0;
        if (totaCountRaw) {
          totalCount = parseInt(totaCountRaw, 10);
        }

        const leads = response.body as LeadDto[];

        return [leads.map(x => this._assembler.toViewModel(x)), totalCount];
      })
    );
  }

  public getStatistics(leadFormId: string, after: Date | null = null, before: Date | null = null): Observable<LeadStats[]> {
    return this._api.getLeadsStatistics({
      LeadFormId: leadFormId,
      After: this.renderDate(after),
      Before: this.renderDate(before)
    }).pipe(
      map(response => {
        return response.map(x => new LeadStats(x.date ?? '', x.count ?? 0));
      })
    );
  }

  public getNewLeadCounters(filter: { [key: string]: number; }): Observable<[string, number][]> {
    return this._api.getNewLeadsStats({
      body: filter
    }).pipe(
      map(response => {
        return response.map(x => [x.leadFormId ?? '', x.count ?? 0]);
      })
    );
  }

  private renderDate(date: Date|null): string|undefined {
    return date? moment(date).format('YYYY-MM-DD') : undefined;
  }
}
