import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RightsService } from 'src/api/administration/services';
import { RightsViewModel } from 'src/app/view-models/rights/rights-view-model';
import { RightsAssemblerService } from '../assemblers/rights-assembler.service';

@Injectable({
  providedIn: 'root'
})
export class RightsRepositoryService {

  constructor(private api: RightsService, private assembler: RightsAssemblerService) { }

  public ListEntityRights(entityType: string, entityId: string): Observable<RightsViewModel[]> {
    return this.api.listEntityRights({
      entityType: entityType,
      entityId: entityId
    }).pipe(
      map(result => {
        return result.map(x => this.assembler.ToViewModel(x));
      })
    );
  }

  public GetMyEntityRights(entityType: string, entityId: string): Observable<RightsViewModel> {
    return this.api.getMyRightsByEntity({
      entityType: entityType,
      entityId: entityId
    }).pipe(
      map(result => {
        return this.assembler.ToViewModel(result);
      })
    );
  }

  public GrantRights(rights: RightsViewModel): Observable<string> {
    return this.api.grantRights({
      body: this.assembler.ToDto(rights)
    });
  }

  public RevokeRights(rights: RightsViewModel): Observable<boolean> {
    return this.api.revokeRights({
      rightsId: rights.id ?? ''
    });
  }

  public PatchCanModify(rights: RightsViewModel): Observable<boolean> {
    return this.api.updateCanModifyScope({
      rightsId: rights.id ?? '',
      body: rights.canModify
    });
  }

  public PatchCanRemove(rights: RightsViewModel): Observable<boolean> {
    return this.api.updateCanRemoveScope({
      rightsId: rights.id ?? '',
      body: rights.canRemove
    });
  }
}
