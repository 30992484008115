/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ContractDto } from '../models/contract-dto';
import { CreativeContractDto } from '../models/creative-contract-dto';
import { CreativeDto } from '../models/creative-dto';
import { CreativeEconomicsClassifierDto } from '../models/creative-economics-classifier-dto';
import { ErirDataDto } from '../models/erir-data-dto';

@Injectable({
  providedIn: 'root',
})
export class CreativeService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation listCreatives
   */
  static readonly ListCreativesPath = '/api/creative';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listCreatives()` instead.
   *
   * This method doesn't expect any request body.
   */
  listCreatives$Response(params?: {
    page?: number;
    pageSize?: number;
    filterText?: string;
    filterForms?: Array<string>;
    filterCampaignTypes?: Array<string>;
    filterOkveds?: Array<string>;
  }): Observable<StrictHttpResponse<Array<CreativeDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CreativeService.ListCreativesPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('filterText', params.filterText, {});
      rb.query('filterForms', params.filterForms, {});
      rb.query('filterCampaignTypes', params.filterCampaignTypes, {});
      rb.query('filterOkveds', params.filterOkveds, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CreativeDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listCreatives$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listCreatives(params?: {
    page?: number;
    pageSize?: number;
    filterText?: string;
    filterForms?: Array<string>;
    filterCampaignTypes?: Array<string>;
    filterOkveds?: Array<string>;
  }): Observable<Array<CreativeDto>> {

    return this.listCreatives$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CreativeDto>>) => r.body as Array<CreativeDto>)
    );
  }

  /**
   * Path part for operation createCreative
   */
  static readonly CreateCreativePath = '/api/creative';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCreative()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCreative$Response(params?: {
    body?: CreativeDto
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, CreativeService.CreateCreativePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createCreative$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCreative(params?: {
    body?: CreativeDto
  }): Observable<string> {

    return this.createCreative$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getCreativesByIds
   */
  static readonly GetCreativesByIdsPath = '/api/creative/byids';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCreativesByIds()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCreativesByIds$Response(params: {
    ids: Array<string>;
  }): Observable<StrictHttpResponse<Array<ContractDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CreativeService.GetCreativesByIdsPath, 'get');
    if (params) {
      rb.query('ids', params.ids, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ContractDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCreativesByIds$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCreativesByIds(params: {
    ids: Array<string>;
  }): Observable<Array<ContractDto>> {

    return this.getCreativesByIds$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ContractDto>>) => r.body as Array<ContractDto>)
    );
  }

  /**
   * Path part for operation getErirDataOfCreative
   */
  static readonly GetErirDataOfCreativePath = '/api/creative/erir/{marker}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getErirDataOfCreative()` instead.
   *
   * This method doesn't expect any request body.
   */
  getErirDataOfCreative$Response(params: {
    marker: string;
  }): Observable<StrictHttpResponse<ErirDataDto>> {

    const rb = new RequestBuilder(this.rootUrl, CreativeService.GetErirDataOfCreativePath, 'get');
    if (params) {
      rb.path('marker', params.marker, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ErirDataDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getErirDataOfCreative$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getErirDataOfCreative(params: {
    marker: string;
  }): Observable<ErirDataDto> {

    return this.getErirDataOfCreative$Response(params).pipe(
      map((r: StrictHttpResponse<ErirDataDto>) => r.body as ErirDataDto)
    );
  }

  /**
   * Path part for operation addCreativeToContract
   */
  static readonly AddCreativeToContractPath = '/api/creative/{entityId}/contracts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addCreativeToContract()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addCreativeToContract$Response(params: {
    entityId: string;
    body: CreativeContractDto
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, CreativeService.AddCreativeToContractPath, 'post');
    if (params) {
      rb.path('entityId', params.entityId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addCreativeToContract$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addCreativeToContract(params: {
    entityId: string;
    body: CreativeContractDto
  }): Observable<string> {

    return this.addCreativeToContract$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation removeCreativeFromContract
   */
  static readonly RemoveCreativeFromContractPath = '/api/creative/{entityId}/contracts/{contractId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeCreativeFromContract()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeCreativeFromContract$Response(params: {
    entityId: string;
    contractId: string;
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, CreativeService.RemoveCreativeFromContractPath, 'delete');
    if (params) {
      rb.path('entityId', params.entityId, {});
      rb.path('contractId', params.contractId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeCreativeFromContract$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeCreativeFromContract(params: {
    entityId: string;
    contractId: string;
  }): Observable<boolean> {

    return this.removeCreativeFromContract$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation addEconomicsClassificationToCreative
   */
  static readonly AddEconomicsClassificationToCreativePath = '/api/creative/{entityId}/economicsclassifiers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addEconomicsClassificationToCreative()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addEconomicsClassificationToCreative$Response(params: {
    entityId: string;
    body: Array<CreativeEconomicsClassifierDto>
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, CreativeService.AddEconomicsClassificationToCreativePath, 'post');
    if (params) {
      rb.path('entityId', params.entityId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addEconomicsClassificationToCreative$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addEconomicsClassificationToCreative(params: {
    entityId: string;
    body: Array<CreativeEconomicsClassifierDto>
  }): Observable<boolean> {

    return this.addEconomicsClassificationToCreative$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation removeEconomicsClassificationFromCreative
   */
  static readonly RemoveEconomicsClassificationFromCreativePath = '/api/creative/{entityId}/economicsclassifiers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeEconomicsClassificationFromCreative()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  removeEconomicsClassificationFromCreative$Response(params: {
    entityId: string;
    body: Array<string>
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, CreativeService.RemoveEconomicsClassificationFromCreativePath, 'delete');
    if (params) {
      rb.path('entityId', params.entityId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeEconomicsClassificationFromCreative$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  removeEconomicsClassificationFromCreative(params: {
    entityId: string;
    body: Array<string>
  }): Observable<boolean> {

    return this.removeEconomicsClassificationFromCreative$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation updateCreative
   */
  static readonly UpdateCreativePath = '/api/creative/{entityId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCreative()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCreative$Response(params: {
    entityId: string;
    body?: CreativeDto
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, CreativeService.UpdateCreativePath, 'put');
    if (params) {
      rb.path('entityId', params.entityId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateCreative$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCreative(params: {
    entityId: string;
    body?: CreativeDto
  }): Observable<boolean> {

    return this.updateCreative$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation addSelfAdvertisementCreativeToSyncQueue
   */
  static readonly AddSelfAdvertisementCreativeToSyncQueuePath = '/api/creative/{entityId}/sync';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addSelfAdvertisementCreativeToSyncQueue()` instead.
   *
   * This method doesn't expect any request body.
   */
  addSelfAdvertisementCreativeToSyncQueue$Response(params: {
    entityId: string;
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, CreativeService.AddSelfAdvertisementCreativeToSyncQueuePath, 'post');
    if (params) {
      rb.path('entityId', params.entityId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addSelfAdvertisementCreativeToSyncQueue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addSelfAdvertisementCreativeToSyncQueue(params: {
    entityId: string;
  }): Observable<boolean> {

    return this.addSelfAdvertisementCreativeToSyncQueue$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation addCreativeToSyncQueue
   */
  static readonly AddCreativeToSyncQueuePath = '/api/creative/{entityId}/contracts/{contractId}/sync';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addCreativeToSyncQueue()` instead.
   *
   * This method doesn't expect any request body.
   */
  addCreativeToSyncQueue$Response(params: {
    entityId: string;
    contractId: string;
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, CreativeService.AddCreativeToSyncQueuePath, 'post');
    if (params) {
      rb.path('entityId', params.entityId, {});
      rb.path('contractId', params.contractId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addCreativeToSyncQueue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addCreativeToSyncQueue(params: {
    entityId: string;
    contractId: string;
  }): Observable<boolean> {

    return this.addCreativeToSyncQueue$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
