/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { EconomicClassificationDto } from '../models/economic-classification-dto';

@Injectable({
  providedIn: 'root',
})
export class EconomicClassificationService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation listEconomicClassifications
   */
  static readonly ListEconomicClassificationsPath = '/api/economicclassification';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listEconomicClassifications()` instead.
   *
   * This method doesn't expect any request body.
   */
  listEconomicClassifications$Response(params?: {
    page?: number;
    pageSize?: number;
  }): Observable<StrictHttpResponse<Array<EconomicClassificationDto>>> {

    const rb = new RequestBuilder(this.rootUrl, EconomicClassificationService.ListEconomicClassificationsPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('pageSize', params.pageSize, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EconomicClassificationDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listEconomicClassifications$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listEconomicClassifications(params?: {
    page?: number;
    pageSize?: number;
  }): Observable<Array<EconomicClassificationDto>> {

    return this.listEconomicClassifications$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EconomicClassificationDto>>) => r.body as Array<EconomicClassificationDto>)
    );
  }

  /**
   * Path part for operation createEconomicClassification
   */
  static readonly CreateEconomicClassificationPath = '/api/economicclassification';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createEconomicClassification()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createEconomicClassification$Response(params?: {
    body?: EconomicClassificationDto
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, EconomicClassificationService.CreateEconomicClassificationPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createEconomicClassification$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createEconomicClassification(params?: {
    body?: EconomicClassificationDto
  }): Observable<string> {

    return this.createEconomicClassification$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation updateEconomicClassification
   */
  static readonly UpdateEconomicClassificationPath = '/api/economicclassification/{entityId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateEconomicClassification()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateEconomicClassification$Response(params: {
    entityId: string;
    body?: EconomicClassificationDto
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, EconomicClassificationService.UpdateEconomicClassificationPath, 'put');
    if (params) {
      rb.path('entityId', params.entityId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateEconomicClassification$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateEconomicClassification(params: {
    entityId: string;
    body?: EconomicClassificationDto
  }): Observable<boolean> {

    return this.updateEconomicClassification$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
